import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from '@angular/forms';
import {
  StudentEvaluationService,
  StudentRegistrationService,
  LocalLanguageService,
} from '@services/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-final-evaluation',
  templateUrl: './final-evaluation.component.html',
  styleUrls: ['./final-evaluation.component.sass'],
})
export class FinalEvaluationComponent implements OnInit {
  // Const Data usage in the real time<<<<<<<<<<<<<<<<<<
  formDummyData = null;
  finalEvaluatonForm: FormGroup;
  followUpEvaluationForm: FormGroup;
  evaluation = [
    { code: 1, name: 'Weak' },
    { code: 2, name: 'Sufficient' },
    { code: 3, name: 'Good' },
    { code: 4, name: 'Very good' },
    { code: 5, name: 'Excellent' },
  ];
  trainerSpoke = [
    { code: 1, name: 'very often' },
    { code: 2, name: 'often' },
    { code: 3, name: 'sometimes' },
    { code: 4, name: 'rarely' },
  ];
  expectationsStatus = [
    { code: 1, name: 'Yes' },
    { code: 2, name: 'not quite' },
    { code: 3, name: 'no' },
  ];

  agataalMet = [
    { code: 1, name: 'The website' },
    { code: 2, name: 'the site Bedrijfsopleidingen.be' },
    { code: 3, name: 'Google' },
    { code: 4, name: 'work' },
    { code: 5, name: 'friends/family' },
    { code: 6, name: 'other' },
  ];
  selected = -1;
  // >>>>>>>>>>>>
  evaluationFormDisplay = false;
  langs = languages;
  studentId: number = null;
  currentDate = new Date();
  contactList: FormArray;
  spinerLoading = true;
  stdFirstName = '';
  stdLastName = '';
  trainerName = [];
  organizationName = '';
  targertedLanguage = '';
  evalutionId = null;
  constructor(
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    private evaluationService: StudentEvaluationService,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentRegistrationService,
    private localLanguageService: LocalLanguageService
  ) { }

  ngOnInit(): void {
    this.languageChangeHandler();

    this.finalEvaluatonForm = this.formBuilder.group({
      content_of_training: new FormControl(0, Validators.required),
      structure_of_training: new FormControl(0, Validators.required),
      activities_during_lesson: new FormControl(0, Validators.required),
      variation_in_subject_matters: new FormControl(0, Validators.required),
      pace_of_lessons: new FormControl(0, Validators.required),
      documents_received: new FormControl(0, Validators.required),
      specified_home_tasks: new FormControl(0, Validators.required),
      often_spoke_during_training: new FormControl(0, Validators.required),
      training_comments: new FormControl('', Validators.required),
      trainer_evaluations: this.formBuilder.array([]),

      organization_of_training: new FormControl(0, Validators.required),
      organization_comments: new FormControl('', Validators.required),
      meet_expectations: new FormControl(0, Validators.required),
      meet_expectations_explanation: new FormControl('', Validators.required),
      suggestions: new FormControl('', Validators.required),
      met_agataal_through: new FormControl('', Validators.required),
    });
    this.followUpEvaluationForm = this.formBuilder.group({
      satisfied_with_result: new FormControl(0, Validators.required),
      satisfied_with_result_explanation: new FormControl(
        '',
        Validators.required
      ),
      strong_points: new FormControl('', Validators.required),
      weak_points: new FormControl('', Validators.required),
      additional_suggestions: new FormControl('', Validators.required),
      met_agataal_through: new FormControl('', Validators.required),
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  // getStudentsFormGroup(index): FormGroup {
  //   this.contactList = this.finalEvaluatonForm.get(
  //     'trainer_evaluations'
  //   ) as FormArray;

  //   const formGroup = this.contactList.controls[index] as FormGroup;
  //   return formGroup;
  // }

  formDrawer() {
    for (let a = 0; a < this.formDummyData.length; a++) {
      const fg = this.formBuilder.group({
        trainer_id: new FormControl(this.trainerName[a].id),
        way_of_teaching: new FormControl(0, Validators.required),
        adjustment_level: new FormControl(0, Validators.required),
        subject_knowledge: new FormControl(0, Validators.required),
        contact_language_trainer: new FormControl(0, Validators.required),
        correction_of_mistakes: new FormControl(0, Validators.required),
        use_of_teaching_materials: new FormControl(0, Validators.required),
        remarks: new FormControl('', Validators.required),
      });

      (this.finalEvaluatonForm.get('trainer_evaluations') as FormArray).push(
        fg
      );
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.studentService.studentDetailsAPI().subscribe(
      (res) => {
        this.stdFirstName = res.data.first_name;
        this.stdLastName = res.data.last_name;
        this.trainerName =
          res.data.group && res.data.group.trainers
            ? res.data.group.trainers
            : [];
        this.organizationName = res.data.registration.customer.name;
        this.targertedLanguage = res.data.registration.type.language.name;
        this.spinerLoading = false;
        this.formDummyData =
          res.data.group && res.data.group.trainers
            ? res.data.group.trainers
            : [];
        this.formDrawer();
      },
      (error) => {
        this.notificationService.error(error.status, error.error.message);
        this.spinerLoading = false;
      }
    );

    this.evaluationService.getEvaluation().subscribe(
      (res: any) => {
        if (res) {
          this.evaluationFormDisplay = true;
          this.spinerLoading = false;
          this.evalutionId = res.data.id;
        } else {
          this.evaluationFormDisplay = false;
          this.spinerLoading = false;
        }
      },
      (error) => {
        this.notificationService.error(error.status, error.error.messsage);
        this.spinerLoading = false;
      }
    );
    // this.formDrawer();
  }

  get finalForm() {
    return this.finalEvaluatonForm.controls;
  }

  get followUpForm() {
    return this.followUpEvaluationForm.controls;
  }

  onSubmit(value) {
    if (this.finalEvaluatonForm.invalid) {
      return 0;
    }
    value.final = false;
    this.evaluationService.createEvaluation(value).subscribe(
      (res) => {
        this.notificationService.success(
          '200',
          'Evalualtion part 1 submitted...'
        );
      },
      (error) => {
        if (error.status) {
          this.notificationService.error(error.status, error.error.message);
        }
      }
    );
  }

  evaluationPart2(value) {
    if (this.followUpEvaluationForm.invalid) {
      return 0;
    }
    value.final = true;
    value.evaluation_id = this.evalutionId;
    this.evaluationService.updateEvaluation(value).subscribe(
      (res) => {
        this.notificationService.success(
          '200',
          'Evalualtion part 1 submitted...'
        );
        this.evaluationFormDisplay = true;
      },
      (error) => {
        this.notificationService.error(error.status, error.error.message);
      }
    );
  }
}
