import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {
  SingleDataSet,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-eval-pie-chart',
  templateUrl: './eval-pie-chart.component.html',
  styleUrls: ['./eval-pie-chart.component.sass'],
})
export class EvalPieChartComponent implements OnInit, OnChanges {
  @Input() generalAttendence: any;
  @Input() label: any;
  @Input() totalLabel: any;

  @Input() total: any = 5;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };
  public pieChartLabels: Label[] = ['Present', 'Total'];
  public pieChartData: SingleDataSet = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {}
  ngOnChanges(change) {
    this.pieChartLabels = [this.label, 'Total'];
    if (change.generalAttendence) {
      this.pieChartData = [change.generalAttendence.currentValue, this.total];
    }

    if (change.label) {
      this.pieChartLabels[0] = change.label.currentValue;
    }
    if (change.totalLabel) {
      this.pieChartLabels[1] = change.totalLabel.currentValue;
    }
  }
}
