<mat-card style="width: 100%">
  <div style="position: fixed; top: 40%; left: 50%; z-index: 1">
    <div style="display: flex; justify-content: space-around">
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  </div>
  <form
    [formGroup]="lessonDetailForm"
    (ngSubmit)="updateLesson(lessonDetailForm.value)"
  >
    <div class="row">
      <div class="col-lg-4">
        <mat-card class="mb-4">
          <p class="content">
            {{ "LessonDetails.startLabel" | translate }}
            {{
              lessonDetail["start"]
                | amLocale: getlocale()
                | amLocal
                | amDateFormat: "LL LT"
            }}
          </p>
        </mat-card>
      </div>
      <div class="col-lg-4">
        <mat-card class="mb-4">
          <p class="content">
            {{ "LessonDetails.endLabel" | translate }}
            {{
              lessonDetail["end"]
                | amLocale: getlocale()
                | amLocal
                | amDateFormat: "LL LT"
            }}
          </p>
        </mat-card>
      </div>
      <div class="col-lg-4">
        <mat-card class="mb-2">
          <button
            disabled="{{
              lessonDetail['cancelled'] || lessonDetail['lesson_end'] != null
            }}"
            mat-raised-button
            color="warn"
            style="margin-bottom: 6px"
            type="button"
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            {{ "LessonDetails.cancelLessonBtn" | translate }}
            <mat-icon>cancel</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openAbsentCaseForm()">
              <span>{{ "LessonDetails.peopleShowing" | translate }}</span>
            </button>
            <button mat-menu-item (click)="openRescheduleForm()">
              <span>{{ "LessonDetails.reSheduleLabel" | translate }}</span>
            </button>
          </mat-menu>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-card class="mb-4">
          <p class="content">
            {{ "LessonDetails.groupNameLabel" | translate }}
            <button
              mat-button
              class="button"
              type="button"
              (click)="navigateGroup(lessonDetail['group']['id'])"
            >
              {{ lessonDetail["group"] ? lessonDetail["group"]["name"] : "" }}
            </button>
          </p>
        </mat-card>
      </div>
      <div class="col-lg-6">
        <mat-card class="mb-4">
          <p class="content" style="margin-left: 5px">
            {{ "LessonDetails.remarksLabel" | translate }}
          </p>
          <p class="content" [innerHTML]="lessonDetail['remarks']"></p>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-card class="mb-4">
          <p class="content">
            {{ "LessonDetails.startLessonLabel" | translate }}
            <button
              *ngIf="!checkTime('lesson_start')"
              disabled="{{ lessonDetail['cancelled'] || lessonStartLoading }}"
              mat-raised-button
              color="accent"
              type="button"
              (click)="startLesson()"
            >
              {{ "LessonDetails.startBtn" | translate }}</button
            ><button
              *ngIf="checkTime('lesson_start')"
              mat-button
              color="accent"
              type="button"
            >
              {{
                lessonDetail["lesson_start"]
                  | amLocale: getlocale()
                  | amLocal
                  | amDateFormat: "LL LT"
              }}
            </button>
          </p>
        </mat-card>
      </div>
      <div class="col-lg-6">
        <mat-card class="mb-4">
          <p class="content">
            {{ "LessonDetails.endLessonLabel" | translate }}
            <button
              *ngIf="!checkTime('lesson_end')"
              disabled="{{ lessonDetail['cancelled'] || lessonEndLoading }}"
              mat-raised-button
              disabled="{{ !checkTime('lesson_start') }}"
              type="button"
              (click)="endLesson()"
              color="warn"
            >
              {{ "LessonDetails.endBtn" | translate }}</button
            ><button
              *ngIf="checkTime('lesson_end')"
              mat-button
              color="accent"
              type="button"
            >
              {{
                lessonDetail["lesson_end"]
                  | amLocale: getlocale()
                  | amLocal
                  | amDateFormat: "LL LT"
              }}
            </button>
          </p>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <mat-card class="mb-4">
          <p *ngIf="checkAttendenceFile()" class="content">
            {{ "LessonDetails.attendenceFile" | translate }}
            <input
              type="file"
              id="file"
              (change)="handleFileInput($event.target.files)"
              disabled="{{ lessonDetail['cancelled'] }}"
            />
          </p>
          <p *ngIf="!checkAttendenceFile()" class="content">
            {{ "LessonDetails.updateAttendenceFile" | translate }}
            <input
              type="file"
              id="file"
              (change)="handleFileInput($event.target.files)"
            />
          </p>
        </mat-card>
      </div>
      <div class="col-lg-4">
        <mat-card class="mb-4">
          <p *ngIf="checkAttendenceFile()" class="content">
            {{ "LessonDetails.attendenceList" | translate }}
            <a href="{{ lessonDetail['attendance_list'] }}" target="_blank"
              ><button
                type="button"
                disabled="{{ lessonDetail['cancelled'] }}"
                mat-mini-fab
                class="button"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>arrow_downward</mat-icon>
              </button></a
            >
          </p>
          <p *ngIf="!checkAttendenceFile()" class="content">
            {{ "LessonDetails.attendenceFile" | translate }}
            <a href="{{ lessonDetail['attendance_file'] }}" target="_blank" [hidden]="!lessonDetail['attendance_file']"
              ><button
                type="button"
                mat-mini-fab
                class="button"
                [disabled]="!lessonDetail['attendance_file']"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>arrow_downward</mat-icon>
              </button></a
            >
          </p>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-card class="mb-12">
          <p class="content">
            {{ "LessonDetails.lessonStatus" | translate
            }}<button
              *ngIf="lessonDetail['cancelled']"
              mat-button
              color="warn"
              type="button"
            >
              {{ "LessonDetails.cancelledBtn" | translate }}</button
            ><button
              type="button"
              *ngIf="!lessonDetail['cancelled']"
              mat-button
              color="warn"
              style="color: green"
            >
              {{ "LessonDetails.scheduleBtn" | translate }}
            </button>
          </p>
        </mat-card>
      </div>
    </div>
    <div
      *ngIf="lessonDetail['cancelled']"
      class="row"
      style="justify-content: start"
    >
      <div class="col-lg-12">
        <mat-card class="mb-12">
          <p class="content" style="margin-left: 5px">
            {{ "LessonDetails.cancelationReason" | translate }}
          </p>
          <p
            class="content"
            [innerHTML]="lessonDetail['cancellation_reason']"
          ></p>
        </mat-card>
      </div>
    </div>
    <div
      *ngIf="lessonDetail['cancelled']"
      class="row"
      style="justify-content: center"
    >
      <div class="col-lg-12">
        <mat-card class="mb-12">
          <p
            class="content"
            style="color: red; margin: auto"
            [innerHTML]="lessonDetail['cancellation_reason_detail']"
          ></p>
        </mat-card>
      </div>
    </div>
    <div style="margin-top: 15px">
      <div class="col-lg-12">
        <mat-card class="mb-12">
          <angular-editor
            disabled="{{ lessonDetail['cancelled'] }}"
            formControlName="notes"
            [config]="editorConfig"
          ></angular-editor>
        </mat-card>
      </div>
    </div>
    <div style="display: flex; justify-content: space-around; margin-top: 15px">
      <button
        disabled="{{ lessonDetail['cancelled'] }}"
        mat-raised-button
        class="button"
        type="submit"
      >
        {{ "LessonDetails.saveBtn" | translate }}
      </button>
    </div>
  </form>
</mat-card>
