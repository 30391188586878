import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class LessonCRUDService {
  constructor(private http: HttpClient) {}

  // Get offer/Quotation details

  createLesson(group: number, data): Observable<any> {
    return this.http.post<any>(`${API.creatLesson}/${group}/lesson`, data);
  }

  updateLesson(lesson: number, data): Observable<any> {
    return this.http.put<any>(`${API.updateLesson}/${lesson}`, data);
  }
  searchLessons(val, from: string, till: string): Observable<any> {
    return this.http.get<any>(`${API.proposalAPI}/${val}/lesson`, {
      params: {
        from,
        till,
      },
    });
  }
  searchAllLessons(from: string, till: string): Observable<any> {
    return this.http.get<any>(`${API.lesson}`, {
      params: {
        from,
        till,
      },
    });
  }
}
