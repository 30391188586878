import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclineProposalComponent } from '../decline-proposal/decline-proposal.component';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { MatDialog } from '@angular/material/dialog';
import {
  StudentGroupingProposalService,
  LocalLanguageService,
} from '@services/index';
import { NotificationsService } from 'angular2-notifications';
@Component({
  selector: 'app-proposal-details',
  templateUrl: './proposal-details.component.html',
  styleUrls: ['./proposal-details.component.sass'],
})
export class ProposalDetailsComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'Email'];
  groups = null;
  registerId: number;
  language = 'en';
  langs = languages;
  spinerLoading = true;
  token: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private route: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    private localLanguageService: LocalLanguageService,
    private studentGroupService: StudentGroupingProposalService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.registerId = +this.activeRoute.snapshot.params.registration;

    this.activeRoute.queryParams.subscribe((params) => {
      this.token = params.token;
    });

    this.studentGroupService.getGroup(this.registerId).subscribe(
      (res: any) => {
        this.groups = res.data;
        this.spinerLoading = false;
      },
      (error) => {
        this.notificationService.error(error.status, error.error.message);
        this.spinerLoading = false;
      }
    );

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  routeNavAccept() {
    this.route.navigate(
      [`/course/registration/${this.registerId}/proposal/accept`],
      {
        queryParams: { token: this.token },
      }
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeclineProposalComponent, {
      width: '70%',
      height: '65%',
      data: { id: this.registerId, language: this.language },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
