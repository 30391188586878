import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../interfaces';
import { API } from './api.urls';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  private userName = new BehaviorSubject<string>(
    JSON.parse(localStorage.getItem('userName'))
  );

  private getToken = new BehaviorSubject(
    JSON.parse(localStorage.getItem('token'))
  );

  constructor(private http: HttpClient, private storage: StorageMap) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${API.login}`, {
        email,
        password,
      })
      .pipe(
        map((user) => {
          localStorage.setItem('token', JSON.stringify(user.token)); // currentUser change to token
          localStorage.setItem(
            'userName',
            JSON.stringify(user.user.first_name)
          );
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('loginStatus', '1');
          localStorage.setItem('trainerId', JSON.stringify(user.user.id));
          this.currentUserSubject.next(user);

          return user;
        })
      );
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token ? true : false;
  }

  logout() {
    localStorage.clear();
    this.loginStatus.next(false);
    localStorage.setItem('loginStatus', '0');
    this.currentUserSubject.next(null);
  }
  isloggedIn() {
    return !!localStorage.getItem('token');
  }

  checkLoginStatus(): boolean {
    return false;
  }

  get isLogged() {
    return this.loginStatus.asObservable();
  }
  get currentUserName() {
    return this.userName.asObservable();
  }

  get fetchToken() {
    return this.getToken.asObservable();
  }

  get getAuthToken() {
    return localStorage.getItem('token');
  }
}
