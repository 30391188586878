import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  LocalLanguageService,
  GroupInfoService,
  AuthV2Service,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';
import { MatDialog } from '@angular/material/dialog';
import { GoalsUpdateFormComponent } from '../common/goals-update-form/goals-update-form.component';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.sass'],
  providers: [AuthV2Service],
})
export class GoalsComponent implements OnInit, OnChanges {
  @Input() groupId: number;
  @Input() language: string;
  loading = true;
  goals = [];
  subscription = null;
  // language: string;
  gId: number = null;
  goalData = {};

  constructor(
    private groupInfo: GroupInfoService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {}
  ngOnChanges(changes) {
    if (changes.hasOwnProperty('groupId')) {
      if (changes.hasOwnProperty('groupId')) {
        if (changes.groupId.currentValue != null){
          this.gId = changes.groupId.currentValue;
        }
      }
      if (this.gId != null ) {
        this.loading = true;
        this.subscription = this.groupInfo.getGroupGoal(
          this.gId
        );
        this.subscription.subscribe((res) => {
          this.goals = res.data;
          this.loading = false;
        });

      }
    }
  }
  ngOnInit(): void {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  createHandler() {
    this.goalData = null;
    this.openGoalForm();
  }
  editHandler(value) {
    this.goalData = value;
    this.openGoalForm();
  }
  request(){
    if (this.gId != null ) {
      this.loading = true;
      this.subscription = this.groupInfo.getGroupGoal(
        this.gId
      );
      this.subscription.subscribe((res) => {
        this.goals = res.data;
        this.loading = false;
      });

    }
  }
  openGoalForm() {
    let dataForm = {};
    if (this.goalData == null) {
      dataForm = {};
    } else {
      dataForm = this.goalData;
    }
    const dialogRef = this.dialog.open(GoalsUpdateFormComponent, {
      width: '70%',
      height: '70%',
      data: {
        id: 1,
        language: this.language,
        groupId: this.gId,
        goalsDetails: dataForm,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.request();
    });
  }
}
