import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { TokenModel } from '@models/index';

@Injectable({
  providedIn: 'root',
})
export class TokenStoreService {
  private readonly token$ = new ReplaySubject<TokenModel>(1);

  constructor() {}

  get token(): Observable<TokenModel | null> {
    return this.tokenChange.pipe(first());
  }

  /**
   * Listen for token changes
   */
  get tokenChange(): Observable<TokenModel | null> {
    return this.token$.pipe(
      distinctUntilChanged((lhs, rhs) =>
        lhs && rhs ? lhs.access_token === rhs.access_token : lhs === rhs
      )
    );
  }

  /**
   * Save the token in storage and update the internal state
   */
  setToken(token): Observable<any> {
    if (!token) {
      return of(localStorage.removeItem('ACCESS_TOKEN'));
    }
    return of(localStorage.setItem('ACCESS_TOKEN', token.access_token));
  }
}
