import { Injectable } from '@angular/core';
import { CustomerAuthService } from '@services/index';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, first, tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})
export class CustomerAuthGuard implements CanActivate {
  constructor(
    private customerAuthService: CustomerAuthService,
    private router: Router,
    private notificationService: NotificationsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    const token = route.queryParams.token;

    if (token) {
      const url = atob(token.replace(/_/g, '/').replace(/-/g, '+'));
      return this.customerAuthService.checkAuthValidator(url).pipe(
        tap((valid) => {
          if (!valid) {
            this.router.navigate(['/unauthorized']);
          }
        })
      );
    }

    return this.customerAuthService.token.pipe(
      first(),
      map((tokenValue) => {
        if (tokenValue) {
          return true;
        }
        this.notificationService.error('UnAuthorized', 'Access is denied');
        this.router.navigate(['/unauthorized']);

        return false;
      })
    );
  }
}
