<div class="container">
  <mat-drawer-container class="example-container" autosize>
    <mat-drawer mode="side" opened>
      <div class="progressDrawer">
        {{ "ProgressInfoComponent.studentsTitle" | translate }}
      </div>
      <div style="z-index: 1; position: absolute; left: 25px">
        <mat-spinner *ngIf="loadingStudents"></mat-spinner>
      </div>
      <mat-action-list style="padding-top: 0px">
        <div *ngFor="let key of students">
          <mat-list-item
            [ngStyle]="{
              'background-color': key.id === studentId ? '#252e4b' : '#ffffff',
              color: key.id === studentId ? '#ffffff' : '#252e4b'
            }"
            role="listitem"
            class="listdata"
            style="height: 35px; border-bottom: 1px solid lightgray"
            (click)="showInfo(key.id)"
            >{{ key.name }}</mat-list-item
          >
        </div>
      </mat-action-list>
    </mat-drawer>
    <mat-drawer-content>
      <div style="position: absolute; top: 30%; z-index: 1; left: 40%">
        <mat-spinner *ngIf="loadingProgress"></mat-spinner>
      </div>
      <form [formGroup]="studentDetail" (ngSubmit)="addProgressReport()">
        <mat-card>
          <div class="row">
            <div class="col-lg-12">
              <mat-card class="mb-4">
                <label
                  >{{ "ProgressInfoComponent.assessmentProgress" | translate }}
                </label>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label
                  >{{ "ProgressInfoComponent.courseContent" | translate
                  }}<span class="star"> *</span></label
                >
                <textarea
                  class="remarks"
                  formControlName="course_content"
                  matInput
                  rows="3"
                  cols="16"
                  required
                ></textarea>
                <div
                  class="alertError"
                  *ngIf="
                    (!studentDetail.controls['course_content'].valid &&
                      studentDetail.controls['course_content'].touched) ||
                    (!studentDetail.controls['course_content'].valid &&
                      submitted)
                  "
                >
                  <div
                    [hidden]="
                      !studentDetail.controls['course_content'].errors.required
                    "
                  >
                    {{ "validations.courseContentReq" | translate }}
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label
                  >{{ "ProgressInfoComponent.commentsLabel" | translate
                  }}<span class="star"> *</span></label
                >
                <textarea
                  class="remarks"
                  formControlName="comments"
                  matInput
                  rows="3"
                  cols="16"
                  required
                ></textarea>
                <div
                  class="alertError"
                  *ngIf="
                    (!studentDetail.controls['comments'].valid &&
                      studentDetail.controls['comments'].touched) ||
                    (!studentDetail.controls['comments'].valid && submitted)
                  "
                >
                  <div
                    [hidden]="
                      !studentDetail.controls['comments'].errors.required
                    "
                  >
                    {{ "validations.commentsReq" | translate }}
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label
                  >{{ "ProgressInfoComponent.felowUpLabel" | translate
                  }}<span class="star"> *</span></label
                >
                <textarea
                  class="remarks"
                  formControlName="follow_up"
                  matInput
                  rows="3"
                  cols="16"
                  required
                ></textarea>
                <div
                  class="alertError"
                  *ngIf="
                    (!studentDetail.controls['follow_up'].valid &&
                      studentDetail.controls['follow_up'].touched) ||
                    (!studentDetail.controls['follow_up'].valid && submitted)
                  "
                >
                  <div
                    [hidden]="
                      !studentDetail.controls['follow_up'].errors.required
                    "
                  >
                    {{ "validations.followUpReq" | translate }}
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.vocabularyGeneralLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_progress_vocabulary_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <option [ngValue]="1">Very Good</option>
                  <option [ngValue]="2">Good</option>
                  <option [ngValue]="3">Enough</option>
                  <option [ngValue]="4">Weak</option>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.vocabularyBusLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_progress_vocabulary_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <option [ngValue]="1">Very Good</option>
                  <option [ngValue]="2">Good</option>
                  <option [ngValue]="3">Enough</option>
                  <option [ngValue]="4">Weak</option>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.garmmarLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_progress_grammar_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <option [ngValue]="1">Very Good</option>
                  <option [ngValue]="2">Good</option>
                  <option [ngValue]="3">Enough</option>
                  <option [ngValue]="4">Weak</option>
                </select>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.garmmarBusinuss" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_progress_grammar_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <option [ngValue]="1">Very Good</option>
                  <option [ngValue]="2">Good</option>
                  <option [ngValue]="3">Enough</option>
                  <option [ngValue]="4">Weak</option>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.statementLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_progress_statement_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <option [ngValue]="1">Very Good</option>
                  <option [ngValue]="2">Good</option>
                  <option [ngValue]="3">Enough</option>
                  <option [ngValue]="4">Weak</option>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.statementBusiness" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_progress_statement_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <option [ngValue]="1">Very Good</option>
                  <option [ngValue]="2">Good</option>
                  <option [ngValue]="3">Enough</option>
                  <option [ngValue]="4">Weak</option>
                </select>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mat-card class="mb-4">
                <label
                  >{{ "ProgressInfoComponent.assesmentLavel" | translate }}
                </label>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>
                  {{
                    "ProgressInfoComponent.listenBusLabel" | translate
                  }}</label
                >
                <select
                  class="form-control"
                  formControlName="assessment_end_listen_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>
                  {{
                    "ProgressInfoComponent.listenBusLabel" | translate
                  }}</label
                >
                <select
                  class="form-control"
                  formControlName="assessment_end_listen_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.speakGenLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_end_speak_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.speakBusLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_end_speak_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.readGenLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_end_read_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>

            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.readBusLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_end_read_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.writeGenLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_end_write_general"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
            <div class="col-lg-4">
              <mat-card class="mb-4">
                <label>{{
                  "ProgressInfoComponent.writeBusLabel" | translate
                }}</label>
                <select
                  class="form-control"
                  formControlName="assessment_end_write_business"
                  name="singleSelect"
                  ng-model="data.singleSelect"
                >
                  <optgroup label="Beginner ">
                    <option [ngValue]="1">A01</option>
                    <option [ngValue]="2">A02</option>
                  </optgroup>
                  <optgroup label="Basic user  ">
                    <option [ngValue]="3">A1</option>
                    <option [ngValue]="4">A2</option>
                  </optgroup>
                  <optgroup label="Independent user">
                    <option [ngValue]="5">B1</option>
                    <option [ngValue]="6">B2</option>
                  </optgroup>
                </select>
              </mat-card>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 15px;
            "
          >
            <button *ngIf="!datarecieved" mat-raised-button class="button">
              {{ "ProgressInfoComponent.saveBtn" | translate }}
            </button>
            <button *ngIf="datarecieved" mat-raised-button class="button">
              {{ "ProgressInfoComponent.updateBtn" | translate }}
            </button>
          </div>
        </mat-card>
      </form>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
