import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GroupInfoService,
  AuthV2Service,
  LocalLanguageService,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.sass'],
  providers: [AuthV2Service],
})
export class LessonComponent implements OnInit {
  lessons = [];
  dataRecieved = false;
  lessonId = null;
  subscription = null;
  langs = languages;
  language = 'en';
  backGround = 'primary';
  color = 'primary';
  @ViewChild('tabs') tabs: MatTabGroup;

  constructor(
    public translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private groupInfo: GroupInfoService,
    private localLanguageService: LocalLanguageService,
  ) {}

  ngOnInit(): void {
    this.lessonId = this.activeRoute.snapshot.params.lessonId;
    this.subscription = this.groupInfo.getLessons(
      this.activeRoute.snapshot.params.groupId,
    );
    this.dataRecieved = false;
    this.subscription.subscribe((res) => {
      this.lessons = res.data;
    });
    this.dataRecieved = true;
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  showInfo(val): void {
    this.lessonId = val;
    this.tabs.selectedIndex = 0;
  }

  getlocale(): string {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }

  checkLesson(id): boolean {
    if (this.lessons.length > 0) {
      const a = this.lessons.find((x) => x.id == id);
      if (a.cancelled) {
        return a.cancelled;
      }
      if (a.lesson_start == null) {
        return true;
      }
    }
    return false;
  }
}
