import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';
import { Offer } from 'app/interfaces/offers.interafce';

@Injectable({ providedIn: 'root' })
export class OfferService {

  constructor(private http: HttpClient) {}

  getOffer(quotation: number): Observable<[Offer]> {
    return this.http.get<[Offer]>(`${API.quoteDetails}/${quotation}`);
  }

  acceptQuote(quotation: number, offer) {
    return this.http.post<any>(`${API.quoteAccept}/${quotation}/accept`, offer);
  }

  declineQuote(quotation: number, offer) {
    return this.http.post(`${API.quoteDecline}/${quotation}/decline`, offer);
  }

  getPDF(quotation: number) {
    return this.http.get<any>(`${API.downloadQuotationPDF}/${quotation}/download`, {responseType: 'blob' as 'json'});
  }
}
