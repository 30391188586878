<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />
  <div class="main">
    <div class="container">
      <br />
      <br />
      <h2>{{ "RegistartionDisplay.title" | translate }}</h2>
      <br />
      <br />
      <mat-accordion>
        <form [formGroup]="displayForm">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "RegistartionDisplay.enrollmentAccordian" | translate }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-group">
              <b
                ><label>{{
                  "RegistartionDisplay.languageLabel" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="language"
                readonly
              />
            </div>

            <div class="form-group">
              <b
                ><label>{{
                  "RegistartionDisplay.frequnceyLabel" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="frequency"
                readonly
              />
            </div>
            <div class="form-group">
              <b>
                <label>{{
                  "RegistartionDisplay.rateLabel" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="rate"
                readonly
              />
            </div>

            <div class="form-group">
              <b
                ><label>{{
                  "RegistartionDisplay.personsLabel" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="persons"
                readonly
              />
            </div>

            <div *ngIf="offerId">
              <div class="form-group">
                <b
                  ><label>{{
                    "RegistartionDisplay.startDateLabel" | translate
                  }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="startDate"
                  readonly
                />
              </div>
              <div class="form-group">
                <b
                  ><label>{{
                    "RegistartionDisplay.endDateLabel" | translate
                  }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="endDate"
                  readonly
                />
              </div>
            </div>

            <div class="form-group">
              <b
                ><label>{{
                  "RegistartionDisplay.trainingPlace" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="place_traning"
                readonly
              />
            </div>

            <div class="form-group">
              <b
                ><label>{{
                  "RegistartionDisplay.commentsLabel" | translate
                }}</label></b
              >

              <div>
                <iframe
                  appIframeResizer
                  class="commentsStyle"
                  title="Thread Content"
                  scrolling="yes"
                  [srcdoc]="registrationComments"
                  frameborder="20"
                ></iframe>
              </div>
            </div>
          </mat-expansion-panel>
          <div *ngIf="multi">
            <br />

            <!-- Subscriber Information Code -->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "RegistartionDisplay.subscriberAccordian" | translate }}
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-group">
                <b
                  ><label>{{ "StudentInfo.firstName" | translate }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  formControlName="subscriber_first_name"
                />
              </div>
              <div class="form-group">
                <b
                  ><label>{{ "StudentInfo.lastName" | translate }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  formControlName="subscriber_last_name"
                />
              </div>
              <div class="form-group">
                <b>
                  <label>{{
                    "RegistartionDisplay.phoneLabel" | translate
                  }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="phone"
                  formControlName="phone"
                  readonly
                />
              </div>

              <div class="form-group">
                <b
                  ><label>{{
                    "RegistartionDisplay.emailLabel" | translate
                  }}</label></b
                >
                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                  readonly
                />
              </div>
            </mat-expansion-panel>
          </div>

          <!-- <div *ngIf="isCompany"> -->
          <br />
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "RegistartionDisplay.customerAccordian" | translate }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-group">
              <b
                ><label>{{ "StudentInfo.firstName" | translate }}</label></b
              >
              <input
                type="text"
                class="form-control"
                readonly
                formControlName="customer_first_name"
              />
            </div>
            <div class="form-group">
              <b
                ><label>{{ "StudentInfo.lastName" | translate }}</label></b
              >
              <input
                type="text"
                class="form-control"
                readonly
                formControlName="customer_last_name"
              />
            </div>
            <div class="form-group">
              <b>
                <label>{{
                  "RegistartionDisplay.phoneLabel" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="customer_phone"
                readonly
              />
            </div>
            <div class="form-group">
              <b
                ><label>{{
                  "RegistartionDisplay.emailLabel" | translate
                }}</label></b
              >
              <input
                type="text"
                class="form-control"
                formControlName="customer_email"
                readonly
              />
            </div>
          </mat-expansion-panel>

          <!-- Billing Information  -->
          <div *ngIf="isCompany">
            <br />
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "RegistartionDisplay.billingAccordian" | translate }}
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-group">
                <b
                  ><label>{{ "StudentInfo.firstName" | translate }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  formControlName="company_first_name"
                />
              </div>
              <div class="form-group">
                <b
                  ><label>{{ "StudentInfo.lastName" | translate }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  formControlName="company_last_name"
                />
              </div>
              <div class="form-group">
                <b>
                  <label>{{
                    "RegistartionDisplay.companyAddressLabel" | translate
                  }}</label></b
                >
                <textarea
                  type="text"
                  class="form-control"
                  formControlName="address"
                  readonly
                ></textarea>
              </div>
              <div class="form-group">
                <b
                  ><label>{{
                    "RegistartionDisplay.companyVatLabel" | translate
                  }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="vat"
                  readonly
                />
              </div>
              <div class="form-group">
                <b
                  ><label>{{
                    "RegistartionDisplay.cocNumber" | translate
                  }}</label></b
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  formControlName="coc_number"
                />
              </div>
            </mat-expansion-panel>
          </div>
        </form>
        <br />

        <!--

        Price Information for OfferId or Without Offer Id case

      -->

        <div>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "RegistartionDisplay.priceAccordian" | translate }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <span>
              <table
                class="table table-striped"
                style="background-color: white; font-size: 16px"
              >
                <thead>
                  <tr>
                    <th>{{ trainingPlace }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="priceRow">
                    <td class="col-6">
                      {{ "RegistartionDisplay.lessonCount" | translate }}
                    </td>
                    <td class="col-6">
                      <span style="color: green"> {{ lessonCount }}</span>
                    </td>
                  </tr>
                  <div class="dossierStyle">
                    <tr class="priceRow">
                      <td class="col-6">
                        {{ "RegistartionDisplay.fileCostLabel" | translate }}
                      </td>
                      <td class="col-6">
                        <span style="color: green"
                          >{{ currenySign }}{{ fileCosts }}</span
                        >
                      </td>
                    </tr>
                    <tr *ngIf="withDossierFollowup">
                      <td class="subDossierStyle col-12">
                        *
                        {{
                          "RegistartionDisplay.withDossierFollowup" | translate
                        }}
                      </td>
                    </tr>
                    <tr *ngIf="withTerminology">
                      <td class="subDossierStyle col-12">
                        *
                        {{ "RegistartionDisplay.withTerminology" | translate }}
                      </td>
                    </tr>
                    <tr *ngIf="withScreening">
                      <td class="subDossierStyle col-12">
                        * {{ "RegistartionDisplay.withScreening" | translate }}
                      </td>
                    </tr>
                    <tr *ngIf="withoutIntake">
                      <td class="subDossierStyle col-12">
                        * {{ "RegistartionDisplay.withoutIntake" | translate }}
                      </td>
                    </tr>
                  </div>
                  <tr class="priceRow">
                    <td class="col-6">
                      {{
                        "RegistartionDisplay.courseMaterialLabel" | translate
                      }}
                    </td>
                    <td class="col-6">
                      <span style="color: green"
                        >{{ currenySign }}{{ courseMaterialCosts }}</span
                      >
                    </td>
                  </tr>
                  <span *ngIf="totalTravelCost">
                    <tr class="priceRow">
                      <td class="col-6">
                        {{ "RegistartionDisplay.travelCostLabel" | translate }}
                        [ {{ lessonCount }} * {{ travelCost }} ] =
                      </td>
                      <td class="col-6">
                        <span style="color: green"
                          >{{ currenySign }}{{ totalTravelCost }}</span
                        >
                      </td>
                    </tr>
                  </span>
                  <br />
                  <tr class="priceRow">
                    <td class="col-8">
                      <b>{{ "RegistartionDisplay.exculdeVat" | translate }}</b>
                    </td>
                    <td class="col-4">
                      <span style="color: green"
                        >{{ currenySign }}{{ totalAmount }}</span
                      >
                    </td>
                  </tr>
                  <tr class="priceRow">
                    <td class="col-12">
                      <b>{{ "RegistartionDisplay.vatlabel" | translate }}</b>

                      <span style="color: green">{{ vatValue }} %</span>
                    </td>
                  </tr>
                  <tr class="priceRow">
                    <td class="col-8">
                      <b>{{ "RegistartionDisplay.includeVat" | translate }} </b>
                    </td>
                    <td class="col-4">
                      <span style="color: green"
                        >{{ currenySign }}{{ includeVatTotal }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          </mat-expansion-panel>
        </div>
        <br />

        <!--

        Print Student details for organization and parents Case.

      -->

        <div *ngIf="multi">
          <span *ngIf="data && data?.length">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "RegistartionDisplay.studentAccordian" | translate }}
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>

              <span *ngFor="let key of data; index as j">
                <table
                  class="table table-striped"
                  style="background-color: white"
                >
                  <tbody>
                    <thead>
                      <br />
                      <tr>
                        <b>
                          &nbsp;&nbsp;
                          {{ "RegistartionDisplay.studentLabel" | translate }}:
                          {{ j + 1 + (page - 1) * 10 }}</b
                        >
                      </tr>
                      <br />
                    </thead>
                    <tr>
                      <td class="col-4">
                        {{ "StudentInfo.firstName" | translate }}
                      </td>
                      <td class="col-4">
                        {{ data[j]?.first_name }}
                      </td>
                    </tr>

                    <tr>
                      <td class="col-4">
                        {{ "StudentInfo.lastName" | translate }}
                      </td>
                      <td class="col-4">
                        {{ data[j]?.last_name }}
                      </td>
                    </tr>

                    <tr>
                      <td class="col-4">
                        {{ "RegistartionDisplay.stdEmail" | translate }}
                      </td>
                      <td class="col-4">
                        {{ data[j]?.email }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-4">
                        {{ "RegistartionDisplay.stdPhone" | translate }}
                      </td>
                      <td class="col-4">
                        {{ data[j]?.phone }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-4">
                        {{ "RegistartionDisplay.stdPosition" | translate }}
                      </td>
                      <td class="col-4">
                        {{ data[j]?.job_title }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
              </span>

              <mat-paginator
                [length]="studentsLength"
                [pageSize]="pageSize"
                (page)="pageEvent($event)"
              >
              </mat-paginator>
            </mat-expansion-panel>
          </span>
        </div>
      </mat-accordion>
      <br />
      <br />
      <div class="buttonDiv">
        <button
          (click)="routeNavUpdate()"
          class="btn btn-success"
          [disabled]="status"
        >
          {{ "RegistartionDisplay.updateBtn" | translate }}
        </button>
        <button
          (click)="routeNavAccept()"
          class="btn button"
          [disabled]="status"
        >
          {{ "RegistartionDisplay.acceptBtn" | translate }}
        </button>
      </div>
    </div>
    <div class="terms">
      <span>
        <span *ngIf="!isCompany">
          {{ "RegistartionDisplay.paymentDetail.title" | translate }}
          <br />
          <br />
          <mat-icon>arrow_right</mat-icon> &nbsp;{{
            "RegistartionDisplay.paymentDetail.cashLabel" | translate
          }}{{ currenySign }}
          <br />
          <mat-icon>arrow_right</mat-icon> &nbsp;{{
            "RegistartionDisplay.paymentDetail.accountDetails" | translate
          }}{{ currenySign }}
          <br />
          <br />
          {{ "RegistartionDisplay.paymentDetail.monthlyLabel" | translate }}
          <br />
          {{ "RegistartionDisplay.paymentDetail.paymentMethod" | translate }}
          &nbsp;<b>{{ paymentMethod }}</b>
          <br />
          <br />
          {{ "RegistartionDisplay.paymentDetail.details" | translate }}
          <br />
          <br />
        </span>
        <span *ngIf="isCompany">
          <span> {{ "RegistartionDisplay.vov.title" | translate }}</span>
          <br />
          <br />
          <mat-icon>arrow_right</mat-icon> &nbsp;{{
            "RegistartionDisplay.vov.dutchVov" | translate
          }}
          <br />
          <mat-icon>arrow_right</mat-icon> &nbsp;{{
            "RegistartionDisplay.vov.frecnhVov" | translate
          }}
          <br />
          <mat-icon>arrow_right</mat-icon> &nbsp;{{
            "RegistartionDisplay.vov.englisgVov" | translate
          }}
          <br />
          <mat-icon>arrow_right</mat-icon> &nbsp;{{
            "RegistartionDisplay.vov.germanVov" | translate
          }}
        </span>
      </span>
    </div>

    <h3>{{ termTitle }}</h3>

    <iframe
      appIframeResizer
      class="terms"
      title="Thread Content"
      scrolling="yes"
      [srcdoc]="termsConditions"
      frameborder="20"
    ></iframe>
  </div>
</span>
