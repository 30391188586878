import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject } from 'rxjs';
import { TokenModel } from '@models/token.model';
import { AuthV2Service } from './auth.vr2.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerAuthService {
  private readonly accessToken = 'ACCESS_TOKEN';
  private readonly refreshAuthToken = 'REFRESH_TOKEN';
  private readonly expireToken = 'EXPIRE_IN_TOKEN';
  private readonly tokenType = 'TYPE_TOKEN';
  private tokenValue: TokenModel = {} as TokenModel;
  public token = new BehaviorSubject(this.tokenValue);
  constructor(
    private http: HttpClient,
    private readonly authService: AuthV2Service,
  ) {}

  checkAuthValidator(url): any {
    return this.http.post<TokenModel>(url, {}).pipe(
      tap((token: TokenModel) => {
        this.setCustomerTokens(token);
      }),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  getCustomerTokenExpire() {
    const expiration = sessionStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    const date = new Date().getTime() / 1000;
    if (expiresAt > date) {
      return true;
    } else {
      return this.removeCustomerTokens();
    }
  }

  checkCustomerTokenExpire(time) {
    const date = new Date().getTime() / 1000;
    if (time > date) {
      return true;
    } else {
      return this.removeCustomerTokens();
    }
  }

  setCustomerTokens(tokens: TokenModel) {
    this.authService.removeTokens();
    sessionStorage.setItem(this.accessToken, tokens.access_token);
    sessionStorage.setItem(
      this.refreshAuthToken,
      tokens.refresh_expires_in.toString(),
    );
    sessionStorage.setItem(this.expireToken, tokens.expires_in.toString());
    sessionStorage.setItem(this.tokenType, tokens.token_type);
  }

  removeCustomerTokens() {
    sessionStorage.removeItem(this.accessToken);
    sessionStorage.removeItem(this.refreshAuthToken);
    sessionStorage.removeItem(this.tokenType);
    sessionStorage.removeItem(this.expireToken);
    return false;
  }

  getAccessToken() {
    return sessionStorage.getItem(this.accessToken);
  }

  getTokenExpiry() {
    const timeToken = +sessionStorage.getItem(this.expireToken);
    const date = new Date().getTime() / 1000;
    if (timeToken > date) {
      return true;
    } else {
      this.removeCustomerTokens();
      return false;
    }
  }
}
