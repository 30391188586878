<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />
  <div class="container">
    <h3>{{ "DispalyGroupProposal.title" | translate }}</h3>
    <mat-accordion>
      <mat-expansion-panel
        class="accStyle"
        *ngFor="let key of groups; index as j"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b> {{ key.name }}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="layoutAcc">
          <div class="row">
            <mat-card class="example-card col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <mat-card-header>
                <mat-card-title>
                  <mat-icon>book</mat-icon>&nbsp;
                  {{
                    "DispalyGroupProposal.readingLabel" | translate
                  }}</mat-card-title
                >
              </mat-card-header>
              <mat-card-content>
                <p>
                  {{ "DispalyGroupProposal.generalLabel" | translate
                  }}{{ key.read_general }}
                  <br />
                  <br />
                  {{ "DispalyGroupProposal.businessLabel" | translate
                  }}{{ key.read_business }}
                </p>
              </mat-card-content>
            </mat-card>

            <mat-card class="example-card col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <mat-card-header>
                <mat-card-title
                  ><mat-icon>create</mat-icon>&nbsp;
                  {{
                    "DispalyGroupProposal.writingLabel" | translate
                  }}</mat-card-title
                >
              </mat-card-header>
              <mat-card-content>
                <p>
                  {{ "DispalyGroupProposal.generalLabel" | translate
                  }}{{ key.write_general }}
                  <br />
                  <br />
                  {{ "DispalyGroupProposal.businessLabel" | translate
                  }}{{ key.write_business }}
                </p>
              </mat-card-content>
            </mat-card>

            <mat-card class="example-card col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <mat-card-header>
                <mat-card-title
                  ><mat-icon>headset</mat-icon>&nbsp;{{
                    "DispalyGroupProposal.listeningLabel" | translate
                  }}</mat-card-title
                >
              </mat-card-header>
              <mat-card-content>
                <p>
                  {{ "DispalyGroupProposal.generalLabel" | translate
                  }}{{ key.listen_general }}
                  <br />
                  <br />
                  {{ "DispalyGroupProposal.businessLabel" | translate
                  }}{{ key.listen_business }}
                </p>
              </mat-card-content>
            </mat-card>

            <mat-card class="example-card col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <mat-card-header>
                <mat-card-title
                  ><mat-icon>mic</mat-icon>&nbsp;{{
                    "DispalyGroupProposal.speakingLabel" | translate
                  }}</mat-card-title
                >
              </mat-card-header>
              <mat-card-content>
                <p>
                  {{ "DispalyGroupProposal.generalLabel" | translate
                  }}{{ key.speak_general }}
                  <br />
                  <br />
                  {{ "DispalyGroupProposal.businessLabel" | translate
                  }}{{ key.speak_business }}
                </p>
              </mat-card-content>
            </mat-card>
          </div>
          <br />
          <br />
          <h5>{{ "DispalyGroupProposal.rematksLabel" | translate }}</h5>
          <br />
          <div>
            <iframe
              appIframeResizer
              class="terms"
              title="Thread Content"
              scrolling="yes"
              [srcdoc]="key.remarks"
              frameborder="20"
            ></iframe>
          </div>
          <br />
          <br />
          <h5>{{ "DispalyGroupProposal.scheduleLabel" | translate }}</h5>
          <br />
          <div>
            <iframe
              appIframeResizer
              class="terms"
              title="Thread Content"
              scrolling="yes"
              [srcdoc]="key.schedule_remarks"
              frameborder="20"
            ></iframe>
          </div>
        </div>
        <br />
        <br />
        <span *ngIf="key['students'].length > 0"> </span>
        <br />
        <span *ngFor="let keyData of key['students']; index as j">
          <p class="col-12 studentLogo">
            &nbsp;&nbsp;
            {{ "DispalyGroupProposal.studentLabel" | translate }}
            {{ j + 1 }}
          </p>
          <table
            class="table table-striped col-xs-12 col-sm-12 col-md-12 col-lg-12"
          >
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td class="col-6">
                  {{ "StudentInfo.firstName" | translate }}
                </td>
                <td class="col-6">
                  {{ keyData.first_name }}
                </td>
              </tr>

              <tr>
                <td class="col-6">
                  {{ "StudentInfo.lastName" | translate }}
                </td>
                <td class="col-6">
                  {{ keyData.last_name }}
                </td>
              </tr>

              <tr>
                <td class="col-6">
                  {{ "DispalyGroupProposal.emailLabel" | translate }}
                </td>
                <td class="col-6">
                  {{ keyData.email }}
                </td>
              </tr>
              <tr>
                <td class="col-6">
                  {{ "DispalyGroupProposal.phoneLabel" | translate }}
                </td>
                <td class="col-6">
                  {{ keyData.phone }}
                </td>
              </tr>
              <tr>
                <td class="col-6">
                  {{ "DispalyGroupProposal.securityLabel" | translate }}:
                </td>
                <td class="col-6">
                  {{ keyData.social_security_number }}
                </td>
              </tr>
            </tbody>
            <!-- </mat-card-content>
            </mat-card> -->
          </table></span
        >
      </mat-expansion-panel>
    </mat-accordion>
    <br />
    <br />

    <div class="buttonsLayout">
      <button mat-raised-button class="button" (click)="routeNavAccept()">
        {{ "DispalyGroupProposal.acceptBtn" | translate }}
      </button>

      <button mat-raised-button color="warn" (click)="openDialog()">
        {{ "DispalyGroupProposal.declineBtn" | translate }}
      </button>
    </div>
  </div>
</span>
