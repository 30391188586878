import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrainerService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.sass'],
})
export class ChatBoxComponent implements OnInit {
  chatForm: FormGroup;
  loading = false;
  submitted = false;
  langs = languages;

  constructor(
    private formBuilder: FormBuilder,
    private trainerService: TrainerService,
    private notificationService: NotificationsService,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
  ) {}

  ngOnInit() {
    this.chatForm = this.formBuilder.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
    });

    const browserlang = this.translate.getBrowserLang();

    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.chatForm.controls;
  }

  onSubmit(value) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.chatForm.invalid) {
      return;
    }
    this.loading = true;
    this.trainerService.trainerChat(value).subscribe(
      (res) => {
        this.loading = false;
        this.notificationService.success(200, 'Successfully sent for message');
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error.message ? error.error.message : 'Something Bad',
        );
        this.loading = false;
      },
    );
  }
}
