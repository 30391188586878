import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MakingPlanningService, HelperService } from '@services/index';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-accept-planning',
  templateUrl: './accept-planning.component.html',
  styleUrls: ['./accept-planning.component.sass'],
})
export class AcceptPlanningComponent implements OnInit {
  document = '';
  groupId = 0;
  contractId = 0;
  AcceptContractForm: FormGroup;
  fileUrl = '';
  buttonDisable = false;
  loading = false;
  showDownload = false;
  uploadFileResponse = { status: '', message: '', filePath: '' };
  subscription: any;
  langs = languages;
  fileDownloadValue = false;
  constructor(
    private router: Router,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private planningService: MakingPlanningService
  ) {
    this.groupId = +this.activeRoute.snapshot.params.groupId;
    this.contractId = +this.activeRoute.snapshot.params.contractId;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.AcceptContractForm.get('file').setValue(file);
    }
  }

  ngOnInit(): void {
    this.AcceptContractForm = this.formBuilder.group({
      file: ['', Validators.required],
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.translate.use(lang);
  }
  downloadFile() {
    this.loading = true;
    this.fileDownloadValue = true;
    this.planningService.getContractFile(this.groupId, this.contractId).subscribe(
      (res) => {
        this.helperService.makeAndDownLoadFile(res, 'Contract.pdf');
        this.loading = false;
        this.notificationService.success('200', 'File received!');
      },
      (err) => {
        this.loading = false;
        this.notificationService.error(err.status, 'File Not Found!');
      }
    );
  }
  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', this.AcceptContractForm.get('file').value);

    this.planningService
      .acceptPlanningContract(this.groupId, this.contractId, formData)
      .subscribe(
        (events) => {
          this.showDownload = true;
          this.uploadFileResponse = events;

          if (events?.data?.id) {
            this.notificationService.success(
              '200',
              'Successfully, Upload your document!'
            );
            this.buttonDisable = true;

            this.loading = false;
          }
        },
        (err) => {
          this.notificationService.error(err.status, err.error.errors.file);
          this.buttonDisable = false;
          this.loading = false;
        }
      );
  }
}
