<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />
<div style="position: absolute; top: 45%; left: 45%; z-index: 1">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div class="container">
  <h1>{{ "AcceptContract.title" | translate }}</h1>
  <br />
  <br />

  <br />
  <p>
    <b>{{ "AcceptContract.noteLabel" | translate }}</b>
    <br />
    {{ "AcceptContract.noteDetails" | translate }}
  </p>
  <br />
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="AcceptContractForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="inputTag">
            <input type="file" name="file" (change)="onFileSelect($event)" />
          </div>
        </div>
        <div class="buttons">
          <span>
            <a
              (click)="downloadFile()"
              class="btn button"
              target="_blank"
              [hidden]="fileDownloadValue"
              style="color: #ffffff;"
            >
              {{ "AcceptContract.downloadBtn" | translate }}
            </a>
          </span>
          <span>
            <!-- <img
              *ngIf="loading"
              class="pl-3"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            /> -->
          </span>
          <span style="margin-top: 50px">
            <button
              type="submit"
              class="btn button"
              [disabled]="
                AcceptContractForm.invalid || loading || buttonDisable
              "
            >
              {{ "AcceptContract.uploadBtn" | translate }}
            </button>
          </span>
        </div>
      </form>

      <!-- 
File Uploading progress bar feature.
 -->

      <br />
      <div *ngIf="uploadFileResponse.status === 'error'">
        {{ uploadFileResponse.message }}
      </div>
      <div *ngIf="uploadFileResponse.status === 'progress'">
        <div
          role="progressbar"
          [style.width.%]="uploadFileResponse.message"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ uploadFileResponse.message }}%
          <mat-progress-bar
            mode="determinate"
            value=" uploadFileResponse.message"
          ></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
