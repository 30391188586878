<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />
<div class="container">
  <h2>{{ "TrainerChat.title" | translate }}</h2>

  <form [formGroup]="chatForm" (ngSubmit)="onSubmit(chatForm.value)">
    <div class="form-group">
      <label for="content"
        >{{ "TrainerChat.titleLabel" | translate
        }}<span class="star"> *</span></label
      >
      <input
        type="text"
        formControlName="title"
        class="form-control"
        required
        [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
      />

      <div
        class="error"
        *ngIf="
          !chatForm.controls['title'].valid &&
          chatForm.controls['title'].touched
        "
      >
        <div [hidden]="!chatForm.controls['title'].errors.required">
          {{ "TrainerChat.validations.title" | translate }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="content"
        >{{ "TrainerChat.messageLabel" | translate
        }}<span class="star"> *</span></label
      >
      <textarea
        #input
        type="text"
        formControlName="content"
        class="form-control"
        required
        maxlength="256"
        minlength="3"
        [ngClass]="{ 'is-invalid': submitted && f.content.errors }"
      >
      </textarea>
      <small
        >{{ "TrainerChat.validations.messageLength" | translate }}
        {{ input.value?.length || 0 }} / 3-256</small
      >
      <br />
      <div
        class="error"
        *ngIf="
          !chatForm.controls['content'].valid &&
          chatForm.controls['content'].touched
        "
      >
        <div [hidden]="!chatForm.controls['content'].errors.required">
          {{ "TrainerChat.validations.message" | translate }}
        </div>
        <div [hidden]="!chatForm.controls['content'].errors.maxLength">
          Message should be under the 256 digits
        </div>
      </div>
    </div>

    <button [disabled]="loading || chatForm.invalid" class="btn button">
      {{ "TrainerChat.submitBtn" | translate }}
    </button>
    <img
      *ngIf="loading"
      class="pl-3"
      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
    />
  </form>
  <br />
  <br />
</div>
