<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<br />
<div class="container">
  <section id="slider">
    <input type="radio" name="slider" id="s1" />
    <input type="radio" name="slider" id="s2" />
    <input type="radio" name="slider" id="s3" checked />
    <input type="radio" name="slider" id="s4" />
    <input type="radio" name="slider" id="s5" />
    <label for="s1" id="slide1">
      <img src="../../../assets/img1.jpg" height="100%" width="100%" />
    </label>
    <label for="s2" id="slide2">
      <img src="../../../assets/img2.jpg" height="100%" width="100%" />
    </label>
    <label for="s3" id="slide3">
      <img src="../../../assets/img3.jpg" height="100%" width="100%" />
    </label>
    <label for="s4" id="slide4">
      <img src="../../../assets/img4.jpeg" height="100%" width="100%" />
    </label>
    <label for="s5" id="slide5">
      <img src="../../../assets/img5.jpg" height="100%" width="100%" />
    </label>
  </section>
  <h2>{{ "Home.title" | translate }}</h2>
  <p class="paraStyle">{{ "Home.text" | translate }}</p>

  <p class="paraStyle">
    {{ "Home.textDetails" | translate }}
  </p>
  <div>
    <span>
      <h4>{{ "Home.assetLabel" | translate }}</h4>

      <p class="paraStyle">
        <span class="assetStyle">{{ "Home.tailor.label" | translate }}</span>
        {{ "Home.tailor.note" | translate }}
      </p>
      <p class="paraStyle">
        <span class="assetStyle">{{ "Home.range.label" | translate }}</span
        >{{ "Home.range.note" | translate }}
      </p>
      <p class="paraStyle">
        <span class="assetStyle">{{ "Home.approch.label" | translate }}</span
        >{{ "Home.approch.note" | translate }}
      </p></span
    >
  </div>

  <h4>{{ "Home.equipmentLabel" | translate }}</h4>
  <br />
  <h5>{{ "Home.languages.title" | translate }}</h5>

  <p>
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.dutch" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.french" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.german" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.english" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.spain" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.italian" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.portugu" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.polish" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.russ" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.czech" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.turk" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.arab" | translate
    }}</span
    ><br />
    <mat-icon>tour</mat-icon>&nbsp;<span class="aligned-with-icon">{{
      "Home.languages.chinese" | translate
    }}</span
    ><br />
    {{ "Home.languages.other" | translate }} <br /><br />{{
      "Home.note" | translate
    }}
  </p>
  <br />
  <br />
</div>
