import { environment } from '../../environments/environment';

export const API = {
  login: `${environment.apiURL}/api/auth/login`,
  logout: `${environment.apiURL}/api/auth/logout`,
  refresh: `${environment.apiURL}/api/auth/refresh`,
  getCities: `${environment.apiURL}/api/get-cities`,
  checkCustomer: `${environment.apiURL}/api/auth/customer`,
  customer: `${environment.apiURL}/api/customer/statistics`,
  updateRegistration: `${environment.apiURL}/api/customer/registration`,
  acceptRegistration: `${environment.apiURL}/api/customer/registration`,
  registrationDetails: `${environment.apiURL}/api/customer/registration`,
  registrationFile: `${environment.apiURL}/api/customer/registration`,
  registrationStudents: `${environment.apiURL}/api/customer/registration`,
  quoteDetails: `${environment.apiURL}/api/customer/quotation`,
  quoteAccept: `${environment.apiURL}/api/customer/quotation`,
  quoteDecline: `${environment.apiURL}/api/customer/quotation`,
  saveStudentData: `${environment.apiURL}/api/save-student`,
  saveStudentDataBulk: `${environment.apiURL}/api/customer/registration`,
  saveStudentTimeslot: `${environment.apiURL}/api/student/time-slot`,
  fetchTimeslots: `${environment.apiURL}/api/student/time-slot`,
  fetchTimeslotsForStudents: `${environment.apiURL}/api/customer/registration`,
  requestTimeslot: `${environment.apiURL}/api/student/time-slot/request`,
  updateTrainerProfile: `${environment.apiURL}/api/trainer`,
  trainerChat: `${environment.apiURL}/api/trainer/send-message`,
  fetchSkills: `${environment.apiURL}/api/skill`,
  fetchCities: `${environment.apiURL}/api/city`,
  fetchLanguages: `${environment.apiURL}/api/language`,
  fetchTransport: `${environment.apiURL}/api/transport`,
  fetchTrainerProfile: `${environment.apiURL}/api/trainer`,
  fetchTrainerTimeSlot: `${environment.apiURL}/api/trainer/event`,
  fetchTrainerTimeSlotDetial: `${environment.apiURL}/api/trainer/student/`,
  fetchTrainerQuestions: `${environment.apiURL}/api/question`,
  fetchTrainerDetails: `${environment.apiURL}/api/trainer/detail`,
  trainerUploadResume: `${environment.apiURL}/api/trainer/resume`,
  trainerUploadSignedDoc: `${environment.apiURL}/api/trainer/signed-doc`,
  confirmEmail: `${environment.apiURL}/api/auth/password/email`,
  resetPassword: `${environment.apiURL}/api/auth/password/reset`,
  changePassword: `${environment.apiURL}/api/auth/password/change`,
  proposalAPI: `${environment.apiURL}/api/trainer/group`,
  lesson: `${environment.apiURL}/api/trainer/lesson`,
  student: `${environment.apiURL}/api/trainer/student`,
  lessonDetail: `${environment.apiURL}/api/trainer/lesson`,
  creatLesson: `${environment.apiURL}/api/trainer/group`,
  updateLesson: `${environment.apiURL}/api/trainer/lesson`,
  searchLessons: `${environment.apiURL}/api/trainer/group`,
  creatAdditionalInfo: `${environment.apiURL}/api/trainer/group`,
  updateAdditionalInfo: `${environment.apiURL}/api/trainer/additional-information`,
  lessonCancelAbsent: `${environment.apiURL}/api/trainer/lesson`,
  lessonReschedule: `${environment.apiURL}/api/trainer/lesson`,
  getEvaluation: `${environment.apiURL}/api/student/evaluation`,
  updateEvaluation: `${environment.apiURL}/api/student/evaluation`,
  createEvaluation: `${environment.apiURL}/api/student/evaluation`,
  trainerEvaluations: `${environment.apiURL}/api/trainer/evaluations/performance`,
  studentApi: `${environment.apiURL}/api/student`,
  creatGoal: `${environment.apiURL}/api/trainer/group`,
  updateGoal: `${environment.apiURL}/api/trainer/goal`,
  contractDisplay: `${environment.apiURL}/api/trainer/group`,
  customerProposal: `${environment.apiURL}/api/customer/registration`,
  trainerRegistration: `${environment.apiURL}/api/trainer/registration`,
  agreementFile: `${environment.apiURL}/api/trainer/download-agreement`,
  contractFile: `${environment.apiURL}/api/trainer/group`,
  resendVerifyEmail: `${environment.apiURL}/api/auth/email/resend`,
  downloadQuotationPDF: `${environment.apiURL}/api/customer/quotation`,
};
