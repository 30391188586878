import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { OfferService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-quote-display',
  templateUrl: './quote-display.component.html',
  styleUrls: ['./quote-display.component.sass'],
})
export class QuoteDisplayComponent implements OnInit {
  quoteId: any;
  offer: any;
  offerData: any;
  address = '';
  langs = languages;
  priceTable: any;
  status: string = null;
  token: string;
  spinerLoading = true;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private offerService: OfferService,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService,
  ) {
    this.quoteId = +this.activeRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.token = params.token;
    });

    this.offerService.getPDF(this.quoteId).subscribe((data) => {
      const binaryData = [];
      const dataType = data.type;
      binaryData.push(data);
      const blob = new Blob(binaryData, { type: dataType });
      const iframe = document.querySelector('iframe');
      iframe.src = URL.createObjectURL(blob);
    });

    this.languageChangeHandler();
    // Language Translation
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.offerService.getOffer(this.quoteId).subscribe(
      (res: any) => {
        this.offerData = res;
        const addre1 = res?.data?.contact?.adress_line_1
          ? res?.data?.contact?.adress_line_1
          : '';
        const addre2 = res.data?.contact?.address_line_2
          ? res.data?.contact?.address_line_2
          : '';
        this.address = addre1 + addre2;
        if (
          res?.data?.status === 'accepted' ||
          res?.data?.status === 'declined' ||
          res?.data?.status === 'prepare'
        ) {
          this.status = res?.data?.status;
        }
        this.priceTable = res?.data?.items;
        this.spinerLoading = false;
      },
      (err) => {
        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad',
        );
        this.spinerLoading = false;
        if (err.status === 403) {
          this.router.navigate(['/unauthorized']);
        }
      },
    );
  }

  declineRoute() {
    return this.router.navigate([`/quote/${this.quoteId}/decline`], {
      queryParams: { token: this.token },
    });
  }

  acceptRoute() {
    return this.router.navigate([`/quote/${this.quoteId}/accept`], {
      queryParams: { token: this.token },
    });
  }
}
