<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />

<div style="z-index: 1; position: absolute; left: 45%; top: 40%">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div class="container">
  <h2>{{ "TrainerResume.title" | translate }}</h2>

  <p>
    {{ "TrainerResume.noteLabel" | translate }}
    <br />
    {{ "TrainerResume.noteDetails" | translate }}
  </p>
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="resumeForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="inputTag">
            <input
              type="file"
              name="uploadFile"
              required
              (change)="onFileSelect($event)"
              accept=".doc,.docx,.pdf"
            />
          </div>
          <div class="buttonLayout">
            <ng-container *ngIf="resume">
              <a href="{{ resume }}" class="btn button" target="_blank">
                {{ "TrainerResume.downloadExistingBtn" | translate }}
              </a>
            </ng-container>

            <button
              type="submit"
              class="btn button"
              [disabled]="loading || resumeForm.invalid"
            >
              {{ "TrainerResume.submitBtn" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  style="z-index: 1; position: absolute; left: 45%; top: 40%"
  *ngIf="loading"
>
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
