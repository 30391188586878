import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MaterialModule } from './material/material.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TokenInterceptor } from './helpers';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DayPilotModule } from 'daypilot-pro-angular';
import { LoginComponent } from './componentes/auth/login';
import { ConfirmEmailComponent } from './componentes/auth/confirmation/confirm-email/confirm-email.component';
import { NewPasswordComponent } from './componentes/auth/confirmation/new-password/new-password.component';

import { AboutComponent } from './componentes/about/about.component';
import { HomeComponent } from './componentes/home/home.component';
import { FooterComponent } from './componentes/common/footer/footer.component';
import { NoPageFoundComponent } from './componentes/no-page-found/no-page-found.component';
import { QuoteDisplayComponent } from './componentes/quote/quote-display/quote-display.component';
import { QuoteDeclineComponent } from './componentes/quote/quote-decline/quote-decline.component';
import { QuoteAcceptComponent } from './componentes/quote/quote-accept/quote-accept.component';

import { DownloadFileComponent } from './componentes/trainer/download-file/download-file.component';
import { TraierProfileComponent } from './componentes/trainer/traier-profile/traier-profile.component';
import { TrainerCVComponent } from './componentes/trainer/trainer-cv/trainer-cv.component';
import { TrainerInformationComponent } from './componentes/trainer/trainer-information/trainer-information.component';
import { ChangePasswordComponent } from './componentes/trainer/change-password/change-password.component';

import { UpdateInfoComponent } from './componentes/courseRegistration/update-info/update-info.component';
import { DisplayComponent } from './componentes/courseRegistration/display/display.component';
import { AcceptComponent } from './componentes/courseRegistration/accept/accept.component';
import { StudentDetailComponent } from './componentes/courseRegistration/student-detail/student-detail.component';
import { StudentTimeslotComponent } from './componentes/courseRegistration/student-timeslot/student-timeslot.component';
import { TimeslotDialogComponent } from './componentes/courseRegistration/timeslot-dialog/timeslot-dialog.component';

import { LanguageTranslateComponent } from './componentes/common/language-translate/language-translate.component';
import { UnauthorizedComponent } from './componentes/unauthorized/unauthorized.component';
import { IFrameResizerDirective } from './iframe-resizer.directive';
import { TimeSchedulerComponent } from './componentes/trainer/time-scheduler/time-scheduler.component';
import { DisplayProposalComponent } from './componentes/studentGrouping/display-proposal/display-proposal.component';
import { AcceptProposalComponent } from './componentes/studentGrouping/accept-proposal/accept-proposal.component';
import { DeclineProposalComponent } from './componentes/studentGrouping/decline-proposal/decline-proposal.component';
import { GroupInfoComponent } from './componentes/studentGrouping/subComponents/group-info/group-info.component';
import { AddtionalInfoComponent } from './componentes/studentGrouping/subComponents/addtional-info/addtional-info.component';
import { GoalsComponent } from './componentes/studentGrouping/subComponents/goals/goals.component';
import { ProgressReportComponent } from './componentes/studentGrouping/subComponents/progress-report/progress-report.component';
import { DeleteConfirmationComponent } from './componentes/studentGrouping/subComponents/common/delete-confirmation/delete-confirmation.component';
import { LessonsUpdateFormComponent } from './componentes/studentGrouping/subComponents/common/lessons-update-form/lessons-update-form.component';
import { AddtionalInfoUpdateFormComponent } from './componentes/studentGrouping/subComponents/common/addtional-info-update-form/addtional-info-update-form.component';
import { GoalsUpdateFormComponent } from './componentes/studentGrouping/subComponents/common/goals-update-form/goals-update-form.component';
import { ProposalDetailsComponent } from './componentes/studentGrouping/proposal-details/proposal-details.component';
import { MomentModule } from 'ngx-moment';
import { LessonComponent } from './componentes/studentGrouping/lesson/lesson.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { StudentInfoComponent } from './componentes/studentGrouping/lesson/student-info/student-info.component';

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { ChatBoxComponent } from './componentes/trainer/chat-box/chat-box.component';
import { GernalInfoComponent } from './componentes/studentGrouping/lesson/gernal-info/gernal-info.component';
import { LessonReportComponent } from './componentes/studentGrouping/lesson/lesson-report/lesson-report.component';
import { PeopleAbsentComponent } from './componentes/studentGrouping/lesson/cancelLesson/people-absent/people-absent.component';
import { RescheduleLessonComponent } from './componentes/studentGrouping/lesson/cancelLesson/reschedule-lesson/reschedule-lesson.component';
import { LessonsDetailComponent } from './componentes/lessons/lessons-detail/lessons-detail.component';
import { DialogOverviewExampleDialog } from './componentes/lessons/lessons-detail/lessons-detail.component';
import { AutoLogoutService } from '@services/autologout.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { IntermediateEvaluationComponent } from './componentes/studentEvaluation/intermediate-evaluation/intermediate-evaluation.component';
import { FinalEvaluationComponent } from './componentes/studentEvaluation/final-evaluation/final-evaluation.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { EvaluationsFeedbackComponent } from './componentes/trainer/evaluations-feedback/evaluations-feedback.component';
import { StatisticsPresenceComponent } from './componentes/statistics-presence/statistics-presence.component';
import { ChartsModule } from 'ng2-charts';
import { PieChartComponent } from './componentes/statistics-presence/pie-chart/pie-chart.component';
import { EvalPieChartComponent } from './componentes/statistics-presence/eval-pie-chart/eval-pie-chart.component';
import { CancelPlanningComponent } from './componentes/makingPlanning/cancel-planning/cancel-planning.component';
import { DisplayPlanningComponent } from './componentes/makingPlanning/display-planning/display-planning.component';
import { AcceptPlanningComponent } from './componentes/makingPlanning/accept-planning/accept-planning.component';
import { TrainerDashboardComponent } from './componentes/trainer/trainer-dashboard/trainer-dashboard.component';
import { EmailVerificationComponent } from './componentes/auth/confirmation/email-verification/email-verification.component';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AboutComponent,
    DialogOverviewExampleDialog,
    NoPageFoundComponent,
    QuoteDisplayComponent,
    QuoteDeclineComponent,
    FooterComponent,
    QuoteAcceptComponent,
    ConfirmEmailComponent,
    NewPasswordComponent,
    TraierProfileComponent,
    TrainerCVComponent,
    TrainerInformationComponent,
    DownloadFileComponent,
    UpdateInfoComponent,
    DisplayComponent,
    AcceptComponent,
    StudentDetailComponent,
    StudentTimeslotComponent,
    TimeslotDialogComponent,
    LanguageTranslateComponent,
    UnauthorizedComponent,
    ChangePasswordComponent,
    IFrameResizerDirective,
    TimeSchedulerComponent,
    DisplayProposalComponent,
    AcceptProposalComponent,
    DeclineProposalComponent,
    GroupInfoComponent,
    AddtionalInfoComponent,
    GoalsComponent,
    ProgressReportComponent,
    DeleteConfirmationComponent,
    LessonsUpdateFormComponent,
    AddtionalInfoUpdateFormComponent,
    GoalsUpdateFormComponent,
    ProposalDetailsComponent,
    LessonComponent,
    StudentInfoComponent,
    ChatBoxComponent,
    GernalInfoComponent,
    LessonReportComponent,
    PeopleAbsentComponent,
    RescheduleLessonComponent,
    LessonsDetailComponent,
    IntermediateEvaluationComponent,
    FinalEvaluationComponent,
    EvaluationsFeedbackComponent,
    StatisticsPresenceComponent,
    PieChartComponent,
    EvalPieChartComponent,
    CancelPlanningComponent,
    DisplayPlanningComponent,
    AcceptPlanningComponent,
    TrainerDashboardComponent,
    EmailVerificationComponent,
  ],

  imports: [
    ChartsModule,
    BrowserModule,
    AngularEditorModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59,
      },
    }),
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DayPilotModule,

    NgMultiSelectDropDownModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatSelectSearchModule,
    NgxMatNativeDateModule,
    NgxStarRatingModule,
    GooglePlaceModule,
  ],

  providers: [
    HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AutoLogoutService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
