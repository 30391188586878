import { Component, OnInit } from '@angular/core';
import {
  GroupInfoService,
  AuthV2Service,
  LessonCRUDService,
  LocalLanguageService,
} from '@services/index';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { languages } from 'app/shared/language.translation';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-trainer-dashboard',
  templateUrl: './trainer-dashboard.component.html',
  styleUrls: ['./trainer-dashboard.component.sass'],
  providers: [AuthV2Service],
})
export class TrainerDashboardComponent implements OnInit {
  totalGroups = 0;
  searchLessonFilters: FormGroup;
  totalLessons = 0;
  localLang = 'en';
  unCompletedLessons = 0;
  lessonLoading = true;
  CompletedLessons = 0;
  lessons = [];
  langs = languages;
  subscription = null;
  constructor(
    private groupInfo: GroupInfoService,
    private lessonService: LessonCRUDService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    private router: Router,
    private localLanguageService: LocalLanguageService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.searchLessonFilters = this.formBuilder.group({
      from: new FormControl('', Validators.required),
      till: new FormControl('', Validators.required),
    });
    this.lessonLoading = true;
    this.subscription = this.groupInfo.getAllGroups();
    this.subscription.subscribe(
      (res) => {
        this.totalGroups = res.data.length;
      },
      (error) => {
        if(error.status!==401){
        this.notificationService.error(error.status, error.error.message);
      }
      }
    );
    this.subscription = this.groupInfo.getAllLessons();
    this.subscription.subscribe(
      (res) => {
        this.lessons = res.data;
        this.totalLessons = res.data.length;
        res.data.forEach((element) => {
          if (element.lesson_end != null) {
            this.CompletedLessons++;
          }
        });
        this.unCompletedLessons = this.totalLessons - this.CompletedLessons;
        this.lessonLoading = false;
      },
      (error) => {
        this.lessonLoading = false;
        this.notificationService.error(error.status, error.error.message);
      }
    );
  }
  public useLanguage(lang: string): void {
    this.localLang = lang;
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }
  naviagate(val) {
    this.router.navigate([val]);
  }
  openLesson(key) {
    this.router.navigate([`/group/${key.group_id}/lesson/${key.id}`]);
  }
  openGroup() {
    this.router.navigate([`/group`]);
  }

  getlocale() {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }
  showStatus(key) {
    if (this.localLang === 'en') {
      if (!key) {
        return 'On Schedule';
      } else {
        return 'Cancelled';
      }
    } else if (this.localLang === 'de') {
      if (!key) {
        return 'Nach Zeitplan';
      } else {
        return 'Abgesagt';
      }
    } else if (this.localLang === 'nl') {
      if (!key) {
        return 'Op schema';
      } else {
        return 'Geannuleerd';
      }
    } else if (this.localLang === 'fr') {
      if (!key) {
        return 'À l\'horaire';
      } else {
        return 'Annulé';
      }
    }
  }

  resetRequest() {
    this.lessonLoading = true;
    this.searchLessonFilters.reset();
    this.subscription = this.groupInfo.getAllLessons();
    this.subscription.subscribe(
      (res) => {
        this.lessons = res.data;
        this.totalLessons = res.data.length;
        res.data.forEach((element) => {
          if (element.lesson_end != null) {
            this.CompletedLessons++;
          }
        });
        this.unCompletedLessons = this.totalLessons - this.CompletedLessons;
        this.lessonLoading = false;
      },
      (error) => {
        this.lessonLoading = false;
        this.notificationService.error(error.status, error.error.message);
      }
    );
  }
  searchLessons(value) {
    this.lessonLoading = true;
    const from = moment(value.from).add(1, 'days').toISOString();
    const till = moment(value.till).add(1, 'days').toISOString();
    this.lessonService.searchAllLessons(from, till).subscribe(
      (res) => {
        this.lessons = res.data;
        this.lessonLoading = false;
      },
      (error) => {
        this.lessonLoading = false;
        this.notificationService.error(error.status, error.error.message);
      }
    );
  }
}
