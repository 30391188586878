import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  StudentRegistrationService,
  LocalLanguageService,
} from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';

export interface DialogTimeSlotData {
  id: string;
  language: string;
}
@Component({
  selector: 'app-timeslot-dialog',
  templateUrl: './timeslot-dialog.component.html',
  styleUrls: ['./timeslot-dialog.component.sass'],
})
export class TimeslotDialogComponent implements OnInit {
  timeslotForm: FormGroup;
  submitted = false;
  loading = false;
  buttonDisable = false;
  langs = languages;

  constructor(
    public dialogRef: MatDialogRef<TimeslotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTimeSlotData,
    private formBuilder: FormBuilder,
    private studentRegisterServce: StudentRegistrationService,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {}

  ngOnInit(): void {
    this.timeslotForm = this.formBuilder.group({
      comment: [null, [Validators.required]],
    });

    this.useLanguage(this.data.language);
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.timeslotForm.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.loading = true;
    this.submitted = true;

    if (this.timeslotForm.invalid) {
      return;
    }

    const data = new FormData();
    data.append('student_id', this.data.id);
    data.append('message', this.f.comment.value);

    this.studentRegisterServce.requestTimeslot(data).subscribe(
      (res) => {
        this.notificationService.success(
          '201',
          'Successfully, Sent your request!'
        );
        this.loading = false;
        this.buttonDisable = true;
        this.onNoClick();
      },
      (error) => {
        this.loading = false;
        this.notificationService.error(error.status, error.error.message);
        this.onNoClick();
      }
    );
  }
}
