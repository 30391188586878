import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GoalsInfoCRUDService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';

export interface DialogGoalsInfo {
  id: string;
  language: string;
  groupId: string;
  goalsDetails: {
    id: number;
    title: string;
    content: string;
    is_completed: boolean;
  };
}

@Component({
  selector: 'app-goals-update-form',
  templateUrl: './goals-update-form.component.html',
  styleUrls: ['./goals-update-form.component.sass'],
})
export class GoalsUpdateFormComponent implements OnInit {
  goalsInfoForm: FormGroup;
  loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private goalService: GoalsInfoCRUDService,
    private notificationService: NotificationsService,
    public dialogRef: MatDialogRef<GoalsUpdateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogGoalsInfo
  ) {}

  ngOnInit(): void {
    this.goalsInfoForm = this.formBuilder.group({
      content: ['', Validators.required],
      title: ['', Validators.required],
      is_completed: [false],
    });
    if (this.data.goalsDetails) {
      this.goalsInfoForm.controls.title.setValue(
        this.data.goalsDetails.title
      );
      this.goalsInfoForm.controls.content.setValue(
        this.data.goalsDetails.content
      );
      this.goalsInfoForm.controls.is_completed.setValue(
        this.data.goalsDetails.is_completed
      );
    }
  }
  get form() {
    return this.goalsInfoForm.controls;
  }
  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(value) {
    if (this.goalsInfoForm.invalid) {
      return 0;
    }
    this.loading = true;
    if (this.data.goalsDetails && this.data.goalsDetails.id) {
      this.goalService.updateGoal(+this.data.goalsDetails.id, value).subscribe(
        (res) => {
          this.loading = false;
          this.notificationService.success(200, 'Goal Updated, Successfully');
          this.closeDialog(); 

        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.message);
          this.closeDialog(); 

        }
      );
    } else {
      this.goalService.createGoal(+this.data.groupId, value).subscribe(
        (res) => {
          this.loading = false;
          this.notificationService.success(200, 'Goal Added, Successfully');
          this.closeDialog(); 
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.message);
          this.closeDialog(); 

        }
      );
    }
  }
}
