<mat-toolbar class="menu" >
  <!-- <mat-toolbar-row (window:resize)="onResize($event)"> -->
  <mat-toolbar-row>
    <img
      width="120"
      alt="Angular Logo"
      src="../assets/agataal_logo_white.png"
      (click)="routing('/home')"
      class="menuLogo"
    />

    <span class="search-bar"></span>

    <div class="topbar-menu">
      <a mat-button routerLink='/home' [routerLinkActive]="['active']">
        <mat-icon>home</mat-icon
        ><span class="aligned-with-icon">{{
          "Menu.mainMenu.home" | translate
        }}</span>
      </a>

      <a mat-button routerLink='/about' [routerLinkActive]="['active']">
        <mat-icon>contact_support</mat-icon
        ><span class="aligned-with-icon">{{
          "Menu.mainMenu.about" | translate
        }}</span></a
      >

      <span>
        <a mat-button (click)="sidenav.toggle()" *ngIf="isAuth()">
          <mat-icon title="Profile">person</mat-icon>
          {{"Menu.mainMenu.profile" | translate}}
        </a>
      </span>
      <span>
        <a mat-button routerLink='/auth/login' *ngIf="!isAuth()" [routerLinkActive]="['active']">
          <mat-icon>account_box</mat-icon
          ><span class="aligned-with-icon">{{
            "Menu.mainMenu.login" | translate
          }}</span>
        </a>
      </span>
    </div>

    <div class="topbar-menu-dots">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <a mat-button (click)="routing('/home')">
            <mat-icon>home</mat-icon
            ><span class="aligned-with-icon">{{
              "Menu.mainMenu.home" | translate
            }}</span>
          </a>
        </button>

        <button mat-menu-item>
          <a mat-button (click)="routing('/about')">
            <mat-icon>contact_support</mat-icon
            ><span class="aligned-with-icon">{{
              "Menu.mainMenu.about" | translate
            }}</span></a
          >
        </button>
        <button mat-menu-item *ngIf="isAuth()">
          <span *ngIf="isAuth()">
            <a mat-button (click)="sidenav.toggle()">
              <mat-icon title="Profile">person</mat-icon>
              <span class="aligned-with-icon">Profile</span>
              </a>
          </span>
        </button>

        <button mat-menu-item *ngIf="!isAuth()">
          <span *ngIf="!isAuth()">
            <a mat-button (click)="routing('/auth/login')">
              <mat-icon>account_box</mat-icon
              ><span class="aligned-with-icon">{{
                "Menu.mainMenu.login" | translate
              }}</span>
            </a>
          </span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list class="sideBarNav" >
      <a mat-list-item routerLink='/trainer/dashboard' *ngIf="isAuth()" [routerLinkActive]="['active']">

        <mat-icon>dashboard</mat-icon>
        {{ "Menu.trainerMenu.dashboard" | translate }}

    </a>
      <a mat-list-item  routerLink='/trainer/profile' *ngIf="isAuth()" [routerLinkActive]="['active']">
        <mat-icon>person</mat-icon> {{ "Menu.trainerMenu.profile" | translate }}
      </a>
      <a mat-list-item routerLink='/trainer/resume' *ngIf="isAuth()" [routerLinkActive]="['active']">
        <mat-icon>book</mat-icon> {{ "Menu.trainerMenu.resume" | translate }}
      </a>
      <a
        mat-list-item
        routerLink='/trainer/information'
        *ngIf="isAuth()"
        [routerLinkActive]="['active']"
      >
        <mat-icon>info</mat-icon> {{ "Menu.trainerMenu.info" | translate }}
      </a>
      <a mat-list-item routerLink='/trainer/contract' *ngIf="isAuth()" [routerLinkActive]="['active']">
        <mat-icon>assignment</mat-icon>
        {{ "Menu.trainerMenu.agreement" | translate }}
      </a>

      <a
        mat-list-item
        routerLink='/trainer/time/schedule'
        *ngIf="isAuth()"
        [routerLinkActive]="['active']"
      >
        <mat-icon>schedule</mat-icon>
        {{ "Menu.trainerMenu.schedule" | translate }}
      </a>
      <a mat-list-item routerLink='/group' *ngIf="isAuth()" [routerLinkActive]="['active']">
        <mat-icon>groups</mat-icon>
        {{ "Menu.trainerMenu.group" | translate }}
      </a>

      <a mat-list-item routerLink='/trainer/chat' *ngIf="isAuth()" [routerLinkActive]="['active']">
        <mat-icon>chat</mat-icon>
        {{ "Menu.trainerMenu.contact" | translate }}
      </a>
      <a
        mat-list-item
        routerLink='/trainer/evaluations/group'
        *ngIf="isAuth()"
        [routerLinkActive]="['active']"
      >
        <mat-icon>poll</mat-icon>
        {{ "Menu.trainerMenu.evaluations" | translate }}
      </a>
      <a
        mat-list-item
        routerLink='/trainer/password/change'
        *ngIf="isAuth()"
        [routerLinkActive]="['active']"
      >
        <mat-icon>vpn_key</mat-icon>
        {{ "Menu.trainerMenu.password" | translate }}
      </a>

      <a mat-list-item (click)="logout()" *ngIf="isAuth()">
        <mat-icon>logout</mat-icon>
        {{ "Menu.trainerMenu.logout" | translate }}</a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content
    ><div id="wrapper">
      <div class="scrollbar" id="scrollbarStyle">
        <div class="force-overflow">
          <router-outlet></router-outlet>
          <simple-notifications [options]="options"></simple-notifications>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer [translate]="translate"></app-footer>
