<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<div class="container">
  <h2 class="pageTitle">{{ "PasswordChange.title" | translate }}</h2>
  <br />
  <form
    [formGroup]="passwordChangeForm"
    (ngSubmit)="onSubmit(passwordChangeForm.value)"
  >
    <div class="form-group">
      <label for="Current Password"
        >{{ "PasswordChange.currentPasswordLabel" | translate
        }}<span class="star"> *</span></label
      >
      <input
        type="password"
        formControlName="current_password"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.current_password.errors }"
      />
      <div
        *ngIf="submitted && f.current_password.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.current_password.errors.required">
          {{ "validations.passwordReq" | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="New Password"
        >{{ "PasswordChange.passwordLabel" | translate
        }}<span class="star"> *</span></label
      >
      <input
        type="password"
        formControlName="password"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      />
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">
          {{ "validations.passwordReq" | translate }}
        </div>
        <div *ngIf="f.password.errors.minlength">
          {{ "validations.passwordLength" | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="Confirm Password"
        >{{ "PasswordChange.confirmPasswordLabel" | translate
        }}<span class="star"> *</span></label
      >
      <input
        type="password"
        formControlName="password_confirmation"
        class="form-control"
        [ngClass]="{
          'is-invalid': submitted && f.password_confirmation.errors
        }"
      />
      <div
        *ngIf="submitted && f.password_confirmation.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.password_confirmation.errors.required">
          {{ "validations.passwordReq" | translate }}
        </div>
        <div *ngIf="passwordChangeForm.errors">
          {{ "validations.misMatchPassword" | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn button" [disabled]="loading">
        {{ "PasswordChange.resetBtn" | translate }}
      </button>
      <img
        *ngIf="loading"
        class="pl-3"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
      <a (click)="goBack()" class="btn btn-link">
        {{ "PasswordReset.cancelBtn" | translate }}</a
      >
    </div>
  </form>
</div>
