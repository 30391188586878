<div>
    <p>{{label}}</p>
    <canvas  baseChart 
        [data]="pieChartData" 
        [labels]="pieChartLabels" 
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="pieChartLegend">
    </canvas>
    </div>