import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DialogTimeSlotData } from '../../../../../interfaces';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { LessonCRUDService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';

export interface DialogLesson {
  id: string;
  language: string;
  groupId: string;
  lessonDetails: {
    id: number;
    start: string;
    end: string;
    remarks: string;
  };
}

@Component({
  selector: 'app-lessons-update-form',
  templateUrl: './lessons-update-form.component.html',
  styleUrls: ['./lessons-update-form.component.sass'],
})
export class LessonsUpdateFormComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LessonsUpdateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogLesson,
    private formBuilder: FormBuilder,
    private lessonService: LessonCRUDService,
    private notificationService: NotificationsService,
    private http: HttpClient,
    private zone: NgZone
  ) {}

  get form() {
    return this.lessonForm.controls;
  }
  lessonForm: FormGroup;
  loading = false;
  public date;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public maxDate;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required]),
  });
  public dateControl = new FormControl(new Date(2021, 9, 4, 5, 6, 7));
  public dateControlMinMax = new FormControl(new Date());

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];
  endDateValid = '';

  @ViewChild('picker') picker1: any;
  @ViewChild('picker') picker2: any;

  ngOnInit(): void {
    const currentData = new Date();
    this.lessonForm = this.formBuilder.group({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
      remarks: new FormControl(''),
    });

    if (this.data.lessonDetails) {
      this.lessonForm.controls.start.setValue(this.data.lessonDetails.start);
      this.lessonForm.controls.end.setValue(this.data.lessonDetails.end);
      this.lessonForm.controls.remarks.setValue(
        this.data.lessonDetails.remarks
      );
    }
    this.date = new Date(2021, 9, 4, 5, 6, 7);
  }

  endDateValidators() {
    const endDate = new Date(this.lessonForm.controls.start.value);
    endDate.setHours(endDate.getHours() + 1);
    return endDate;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  onSubmit(value) {
    if (this.lessonForm.invalid) {
      return 0;
    }
    this.loading = true;
    if (this.data.lessonDetails.id) {
      this.lessonService
        .updateLesson(+this.data.lessonDetails.id, value)
        .subscribe(
          (res) => {
            this.loading = false;
            this.notificationService.success(
              200,
              'Successfully, updated your lesson!'
            );
            this.dialogRef.close();

          },
          (error) => {
            this.loading = false;
            this.notificationService.error(error.status, error.error.errors.start || error.error.message);
            this.dialogRef.close();
          }
        );
    } else {
      this.lessonService.createLesson(+this.data.groupId, value).subscribe(
        (res) => {
          this.loading = false;
          this.notificationService.success(
            200,
            'Lesson is created successfully!'
          );
          this.dialogRef.close();
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.errors.start || error.error.message);
          this.dialogRef.close();
        }
      );
    }
  }

  toggleMinDate(evt: any) {
    if (evt.checked) {
      this._setMinDate();
    } else {
      this.minDate = null;
    }
  }

  toggleMaxDate(evt: any) {
    if (evt.checked) {
      this._setMaxDate();
    } else {
      this.maxDate = null;
    }
  }

  closePicker() {
    this.picker1.cancel();
  }

  private _setMinDate() {
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate() - 1);
  }

  private _setMaxDate() {
    const now = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(now.getDate() + 1);
  }
}
