import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthV2Service,
  CustomerAuthService,
  LocalLanguageService,
  StudentAuthService,
  TokenStoreService,
} from '@services/index';
import { Observable, throwError } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  publishReplay,
  refCount,
  switchMap,
  tap,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TokenResponseModel } from '../interfaces/auth.model';

const EXCLUDE = new RegExp(
  `^${environment.apiURL}(?:/api/auth/login|/api/auth/refresh)`
);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshObservable: Observable<string> = null;

  constructor(
    private injector: Injector,
    private tokenStore: TokenStoreService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthV2Service,
    private customerAuthService: CustomerAuthService,
    private studentAuthService: StudentAuthService,
    private localLanguageService: LocalLanguageService
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.apiURL)) {
      return next.handle(req);
    }

    const newReq = req.clone({
      setHeaders: {
        'X-Locale': this.localLanguageService.getLocalLanguage(),
      },
    });

    if (newReq.headers.has('Authorization') || EXCLUDE.test(req.url)) {
      return next.handle(newReq);
    }

    let token = localStorage.getItem('ACCESS_TOKEN');
    if (token) {
      return this.injectToken(newReq, next, token, true);
    }

    token = sessionStorage.getItem('ACCESS_TOKEN');
    if (token) {
      return this.injectToken(newReq, next, token, false);
    }

    return next.handle(newReq);
  }

  private injectToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string,
    shouldRefresh: boolean
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
      )
      .pipe(
        catchError((error) => {
          if (shouldRefresh) {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                if(error.error.reason=== 'require_password_change'){
                  this.router.navigate(['/trainer/password/change']);
                }
                return this.handle401Error(request, next, token, error);
              }

              if (error.status === 403) {
                
                const sessionToken = sessionStorage.getItem('ACCESS_TOKEN');
                if (sessionToken) {
                  return this.injectToken(request, next, sessionToken, false);
                }
              }
            }
          }

          return throwError(error);
        })
      );
  }

  handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string,
    originalError: any = null
  ): Observable<HttpEvent<any>> {
    if (!this.refreshObservable) {
      const http = this.injector.get(HttpClient);

      this.refreshObservable = http
        .post<TokenResponseModel>(
          `${environment.apiURL}/api/auth/refresh`,
          null,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .pipe(
          tap((newToken) => this.tokenStore.setToken(newToken)),
          map((newToken) => newToken.access_token),
          finalize(() => {
            this.refreshObservable = null;
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.refreshObservable.pipe(
      catchError((error) => {
        this.authService.logout();
        this.customerAuthService.removeCustomerTokens();
        this.studentAuthService.removeCustomerTokens();
        this.router.navigate(['/unauthorized']).catch((reason) => {
          console.warn(reason);
        });

        return throwError(originalError || error);
      }),
      switchMap((newToken) => this.injectToken(request, next, newToken, false))
    );
  }
}
