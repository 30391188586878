<div class="container">
  <h3>{{ "LessonUpdateForm.title" | translate }}</h3>
  <br />
  <form
    class="example-form"
    [formGroup]="lessonForm"
    (ngSubmit)="onSubmit(lessonForm.value)"
  >
    <mat-form-field class="example-full-width">
      <input
        matInput
        required
        [ngxMatDatetimePicker]="picker1"
        placeholder="Start Date&Time:"
        formControlName="start"
        [min]="minDate"
        [max]="maxDate"
        [disabled]="disabled"
      />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #picker1
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="enableMeridian"
      >
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <br />
    <mat-form-field class="example-full-width">
      <input
        matInput
        required
        [ngxMatDatetimePicker]="picker2"
        placeholder="End Date&Time:"
        formControlName="end"
        [min]="endDateValidators()"
        [max]="maxDate"
        [disabled]="disabled"
      />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #picker2
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="enableMeridian"
      >
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <br />
    <br />
    <mat-form-field class="example-full-width">
      <mat-label>{{ "LessonUpdateForm.remarksLabel" | translate }}</mat-label>
      <textarea
        matInput
        placeholder="Enter your comments..."
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        formControlName="remarks"
      ></textarea>
    </mat-form-field>
    <div mat-dialog-actions class="space">
      <button
        mat-raised-button
        type="submit"
        [disabled]="loading || lessonForm.invalid"
        class="button"
      >
        {{ "LessonUpdateForm.saveBtn" | translate }}
      </button>
      <button mat-button mat-dialog-close color="warn">
        {{ "LessonUpdateForm.cancelBtn" | translate }}
      </button>
    </div>
  </form>
  <br />
  <br />
  <p class="note">
    {{ "LessonUpdateForm.note" | translate }}
  </p>
</div>
