import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { City } from '../interfaces';
import { Observable } from 'rxjs';
import { API } from './api.urls';
@Injectable({ providedIn: 'root' })
export class CityService {
  constructor(private http: HttpClient) {}

  getLocationCities(): Observable<City[]> {
    return this.http.get<City[]>(`${API.getCities}`);
  }
}
