<div class="container">
  <br />
  <div class="lessonHeader">
    <h3>{{ "GoalsComponent.title" | translate }}</h3>

    <span>
      <button
        mat-fab
        class="button"
        aria-label="Example icon button with a add_circle icon"
        (click)="createHandler()"
      >
        +
      </button>
    </span>
  </div>
  <br />
  <div style="display: flex; justify-content: space-around">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
  <mat-accordion *ngIf="!loading">
    <mat-expansion-panel class="accStyle" *ngFor="let key of goals">
      <mat-expansion-panel-header>
        <mat-panel-title class="accTitle">
          {{ key.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p [innerHTML]="key.content"></p>
      <mat-card-actions>
        <button class="searchButton" mat-button (click)="editHandler(key)">
          <mat-icon>edit</mat-icon>
        </button>
        <span *ngIf="key.is_completed" style="color: forestgreen">
          {{ "GoalsComponent.completeStatus" | translate }}
        </span>
        <span *ngIf="!key.is_completed" style="color: red">{{
          "GoalsComponent.unCompletedStatus" | translate
        }}</span>
      </mat-card-actions>
      <br/>
    </mat-expansion-panel>
  </mat-accordion>
</div>
