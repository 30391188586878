<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />

<div class="container">
  <h2>{{ "About.title" | translate }}</h2>
  <p>
    <span class="noteStyle"
      ><b>{{ "About.logo1" | translate }}</b>
      {{ "About.logo1Details" | translate }}
      <b>{{ "About.logo2" | translate }}</b
      >{{ "About.logo2Details" | translate }}</span
    >
    <br />
    <br />
    <span class="noteStyle">{{ "About.note1" | translate }}</span>
    <br />
    <br />
    <span class="noteStyle">{{ "About.note2" | translate }}</span>
  </p>

  <br />
</div>
