<div style="z-index: 1; position: absolute; left: 45%; top: 40%">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>
      {{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />

  <div class="container">
    <h2 class="pageTitle">
      {{ "TrainerProfile.title" | translate }}
    </h2>

    <br />
    <span *ngIf="!loadingAttend">
      <span *ngIf="verifiedEmail" class="verifyEmailSpan">
        <a (click)="verifyEmail()" class="verifyEmail">
          {{ "TrainerProfile.emailResendLabel" | translate }}
        </a>
      </span>
    </span>
    <br />
    <form
      [formGroup]="trainerProfile"
      (ngSubmit)="updateProfileData(trainerProfile.value)"
    >
      <div class="form-group"></div>
      <br />
      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.salutation" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <br />
        <mat-form-field appearance="fill" class="selector">
          <mat-select formControlName="salutation">
            <mat-option value="mr">Mr.</mat-option>
            <mat-option value="ms">Ms.</mat-option>
            <mat-option value="other">Other</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['salutation'].valid &&
            trainerProfile.controls['salutation'].touched
          "
        >
          <div
            [hidden]="!trainerProfile.controls['salutation'].errors.required"
          >
            {{ "validations.salutationReq" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.firstName" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          type="text"
          class="form-control"
          formControlName="first_name"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['first_name'].valid &&
            trainerProfile.controls['first_name'].touched
          "
        >
          <div
            [hidden]="!trainerProfile.controls['first_name'].errors.required"
          >
            {{ "validations.firstNameReq" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.lastName" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          type="text"
          class="form-control"
          formControlName="last_name"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['last_name'].valid &&
            trainerProfile.controls['last_name'].touched
          "
        >
          <div
            [hidden]="!trainerProfile.controls['first_name'].errors.required"
          >
            {{ "validations.lastNameReq" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.emailLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input type="text" class="form-control" formControlName="email" />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['email'].valid &&
            trainerProfile.controls['email'].touched
          "
        >
          <div [hidden]="!trainerProfile.controls['email'].errors.required">
            {{ "validations.emailReq" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.phoneLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <div class="mb-2">
          <ngx-intl-tel-input
            [inputId]="'adsasd'"
            [cssClass]="'form-control'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="countryInit"
            [maxLength]="15"
            [tooltipField]="TooltipLabel.Name"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            name="phone"
            formControlName="phone"
          >
          </ngx-intl-tel-input>
        </div>
        <div
          class="alertError"
          *ngIf="
            trainerProfile.controls.phone.invalid &&
            trainerProfile.controls.phone.touched
          "
        >
          <div>
            {{ "validations.invalidPhone" | translate }}
          </div>
          <div [hidden]="!trainerProfile.controls['phone'].errors.required">
            {{ "validations.phoneReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.experinceLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          type="number"
          class="form-control"
          formControlName="experience"
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['experience'].valid &&
            trainerProfile.controls['experience'].touched
          "
        >
          <div
            [hidden]="!trainerProfile.controls['experience'].errors.required"
          >
            {{ "validations.experienceReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.address1Label" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          placeholder="Search"
          ngx-google-places-autocomplete
          #placesRef="ngx-places"
          (onAddressChange)="onAddressSelected($event)"
          id="address"
          class="form-control"
          formControlName="address_line_1"
          autocapitalize="on"
          required
        />
        <!--        <input-->
        <!--          appPlacesJs-->
        <!--          class="form-control"-->
        <!--          placeholder="Search"-->
        <!--          formControlName="address_line_1"-->
        <!--          id="address_line_1"-->
        <!--          required-->
        <!--          type="search"-->
        <!--          searchType="address"-->
        <!--          (selected)="onAddressSelected($event)"-->
        <!--        />-->
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['address_line_1'].valid &&
            trainerProfile.controls['address_line_1'].touched
          "
        >
          <div
            [hidden]="
              !trainerProfile.controls['address_line_1'].errors.required
            "
          >
            {{ "validations.addressReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.address2Label" | translate }}
          </label>
        </b>
        <input
          type="text"
          class="form-control"
          formControlName="address_line_2"
        />
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.postelCodeLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          type="text"
          class="form-control"
          formControlName="postal_code"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['postal_code'].valid &&
            trainerProfile.controls['postal_code'].touched
          "
        >
          <div
            [hidden]="!trainerProfile.controls['postal_code'].errors.required"
          >
            {{ "validations.postalCodeReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.cityLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          type="text"
          class="form-control"
          formControlName="city"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['city'].valid &&
            trainerProfile.controls['city'].touched
          "
        >
          <div [hidden]="!trainerProfile.controls['city'].errors.required">
            {{ "validations.cityReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.stateLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <input
          type="text"
          class="form-control"
          formControlName="state"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['state'].valid &&
            trainerProfile.controls['state'].touched
          "
        >
          <div [hidden]="!trainerProfile.controls['state'].errors.required">
            {{ "validations.stateReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.countryLabel" | translate }}
            <span class="star">*</span>
          </label>
        </b>
        <!-- <input
                    type="text"
                    class="form-control"
                    formControlName="country"
                    required
                > -->
        <mat-select
          class="form-control"
          formControlName="country"
          [compareWith]="compareById"
        >
          <mat-option>
            <ngx-mat-select-search
              ngModel
              (ngModelChange)="filterCountries($event)"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            [value]="country.id"
            *ngFor="let country of filteredCountries; trackBy: trackById"
          >
            {{ country.name }}
          </mat-option>
        </mat-select>
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['country'].valid &&
            trainerProfile.controls['country'].touched
          "
        >
          <div [hidden]="!trainerProfile.controls['country'].errors.required">
            {{ "validations.countryNameReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="!hiddenTags">
        <b>
          <label>Logitute</label>
        </b>
        <input
          type="text"
          class="form-control"
          placeholder="Enter longitude value"
          formControlName="longitude"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['longitude'].valid &&
            trainerProfile.controls['longitude'].touched
          "
        >
          <div [hidden]="!trainerProfile.controls['longitude'].errors.required">
            Longitude is required.
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="!hiddenTags">
        <b>
          <label>Latitude</label>
        </b>
        <input
          type="text"
          class="form-control"
          placeholder="Enter latitude value"
          formControlName="latitude"
          required
        />
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['latitude'].valid &&
            trainerProfile.controls['latitude'].touched
          "
        >
          <div [hidden]="!trainerProfile.controls['latitude'].errors.required">
            latitude is required.
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label>
            {{ "TrainerProfile.dateBirthLabel" | translate }}
          </label>
          <span class="star">*</span>
        </b>
        <br />
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker1"
            formControlName="date_of_birth"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <div
          class="alertError"
          *ngIf="
            !trainerProfile.controls['date_of_birth'].valid &&
            trainerProfile.controls['date_of_birth'].touched
          "
        >
          <div
            [hidden]="!trainerProfile.controls['date_of_birth'].errors.required"
          >
            {{ "validations.brithDateReq" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>
          <b>
            {{ "TrainerProfile.laptopLabel" | translate }}
          </b>
        </label>
        <br />
        <mat-radio-group aria-label="hasLatop?" formControlName="has_laptop">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          &nbsp;
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <button
          type="submit"
          class="btn button"
          [disabled]="loading || trainerProfile.invalid"
        >
          {{ "TrainerProfile.updateBtn" | translate }}
        </button>

        <img
          *ngIf="loading"
          class="pl-3"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
        <a routerLink="/home" class="btn btn-link">
          {{ "TrainerProfile.cancelBtn" | translate }}
        </a>
      </div>
    </form>
  </div>
</span>
