import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { City, Language, Skill, Transport } from '../interfaces';
import { Trainer } from '../interfaces';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class TrainerService {
  constructor(private http: HttpClient) {}

  // Update Trainer profile information

  updateTrainer(formData) {
    return this.http.put<any>(`${API.updateTrainerProfile}`, formData);
  }

  getskills(): Observable<Skill[]> {
    return this.http.get<Skill[]>(`${API.fetchSkills}`);
  }

  // Fetch cities from database

  getCities(): Observable<City[]> {
    return this.http.get<City[]>(`${API.fetchCities}`);
  }

  // fetch all languages from database

  getlanguages(): Observable<[Language]> {
    return this.http.get<[Language]>(`${API.fetchLanguages}`);
  }

  // fetch all transports

  getTransports(): Observable<[Transport]> {
    return this.http.get<[Transport]>(`${API.fetchTransport}`);
  }

  // Fetch Trainer profile

  getTrainerProfile(): Observable<[Trainer]> {
    return this.http.get<[Trainer]>(`${API.fetchTrainerProfile}?with[]=user`);
  }

  getTrainerTimeSlot(temp: string): Observable<any> {
    return this.http.get<any>(`${API.fetchTrainerTimeSlot}${temp}`);
  }

  getTimeSlotDetail(temp: string): Observable<any> {
    return this.http.get<any>(
      `${API.fetchTrainerTimeSlotDetial}${temp}/intake`,
    );
  }

  postTimeSlotDetail(temp: string, form): Observable<any> {
    return this.http.post<any>(
      `${API.fetchTrainerTimeSlotDetial}${temp}/intake`,
      form,
    );
  }

  updateTimeSlotDetail(temp: string, form): Observable<any> {
    return this.http.put<any>(
      `${API.fetchTrainerTimeSlotDetial}${temp}/intake`,
      form,
    );
  }

  getQuestions(): Observable<any> {
    return this.http.get<any>(`${API.fetchTrainerQuestions}`);
  }

  getTrainerDetails(): Observable<any> {
    return this.http.get<[any]>(`${API.fetchTrainerDetails}`);
  }

  getLanguages(): Observable<any> {
    return this.http.get<[any]>(`${API.fetchLanguages}`);
  }

  // Trainer Resume upload API

  public uploadResume(formData) {
    return this.http.post<any>(`${API.trainerUploadResume}`, formData);
  }

  trainerChat(value) {
    return this.http.post<any>(`${API.trainerChat}`, value);
  }

  trainerFetchEvaluations(): Observable<any> {
    return this.http.get<any>(`${API.trainerEvaluations}`);
  }

  getPresence(val): Observable<any> {
    return this.http.get<any>(`${API.customer}/${val}/attendance`);
  }

  getEvaluation(val): Observable<any> {
    return this.http.get<any>(`${API.customer}/${val}/evaluation`);
  }

  // Trainer Signed Document upload API
  public uploadSignedDoc(formData) {
    return this.http.post<any>(`${API.trainerUploadSignedDoc}`, formData);
  }

  getAgreementDoc(): Observable<any> {
    return this.http.get<any>(`${API.agreementFile}`, {
      responseType: 'blob' as 'json',
    });
  }
}
