import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class GroupInfoService {
  constructor(private http: HttpClient) {}

  // Update Trainer profile information
  getAllGroups(): Observable<any> {
    return this.http.get<[any]>(`${API.proposalAPI}`);
  }

  getRegistration(id): Observable<any> {
    return this.http.get<[any]>(`${API.trainerRegistration}/${id}`);
  }

  getGroupDetails(val): Observable<any> {
    return this.http.get<[any]>(`${API.proposalAPI}/${val}`);
  }

  getGroupAdditionalInfo(val): Observable<any> {
    return this.http.get<[any]>(
      `${API.proposalAPI}/${val}/additional-information`
    );
  }
  deleteAdditionalInfo(val): Observable<any> {
    return this.http.delete<[any]>(`${API.updateAdditionalInfo}/${val}`);
  }
  getGroupStudent(val): Observable<any> {
    return this.http.get<[any]>(`${API.proposalAPI}/${val}/student`);
  }

  getGroupStudentDetail(val): Observable<any> {
    return this.http.get<[any]>(`${API.student}/${val}/progress-report`);
  }
  getStudentInfo(val): Observable<any> {
    return this.http.get<[any]>(`${API.student}/${val}`);
  }
  getGroupGoal(val): Observable<any> {
    return this.http.get<[any]>(`${API.proposalAPI}/${val}/goal`);
  }

  getLessons(val): Observable<any> {
    return this.http.get<[any]>(`${API.proposalAPI}/${val}/lesson`);
  }
  getAllLessons(): Observable<any> {
    return this.http.get<[any]>(`${API.lesson}`);
  }
  getLessonDetail(val): Observable<any> {
    return this.http.get<[any]>(`${API.lessonDetail}/${val}`);
  }

  getStudents(val): Observable<any> {
    return this.http.get<[any]>(`${API.proposalAPI}/${val}/student`);
  }
  updateLesson(lesson: number, formData) {
    return this.http.put(`${API.lessonDetail}/${lesson}`, formData);
  }
  uploadFile(val, formData): Observable<any> {
    return this.http.post<any>(
      `${API.lessonDetail}/${val}/attendance-file`,
      formData
    );
  }
  getLessonAttendence(val): Observable<any> {
    return this.http.get<[any]>(`${API.lessonDetail}/${val}/lesson-reports`);
  }
  addLessonAttendance(val, formData): Observable<any> {
    return this.http.post<[any]>(
      `${API.lessonDetail}/${val}/lesson-reports`,
      formData
    );
  }
  addPorgressReport(val, formData): Observable<any> {
    return this.http.post<[any]>(
      `${API.student}/${val}/progress-report`,
      formData
    );
  }
  updatePorgressReport(val, formData): Observable<any> {
    return this.http.put<[any]>(
      `${API.student}/${val}/progress-report`,
      formData
    );
  }
  getTrainerInfo(): Observable<any> {
    return this.http.get<[any]>(`${API.updateTrainerProfile}/`);
  }
}
