<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<div class="container">
  <div class="main">
    <h1>{{ "UnAuthorized.oopsLabel" | translate }}</h1>
    <h2>{{ "UnAuthorized.errortitle" | translate }}</h2>
    <div class="invalid_page">
      {{ "UnAuthorized.errorMessage" | translate }}
    </div>
    <div>
      <a class="button" routerLink="/home">
        {{ "UnAuthorized.backBtn" | translate }}
      </a>
    </div>
  </div>
</div>
