<br />
<h3 *ngIf="!loading">
  {{ "GroupInfoComponent.title" | translate }} {{ name }}
</h3>
<div style="display: flex; justify-content: space-around">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div *ngIf="!loading" class="container">
  <br />
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>
        <mat-icon>book</mat-icon>&nbsp;
        {{ "GroupInfoComponent.readingCardLabel" | translate }}</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ "GroupInfoComponent.generalScoreLabel" | translate }}
        {{ readGeneral }}
        <br />
        <br />
        {{ "GroupInfoComponent.businessScoreLabel" | translate }}
        {{ readBusiness }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title
        ><mat-icon>create</mat-icon>&nbsp;{{
          "GroupInfoComponent.writingCardLabel" | translate
        }}</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ "GroupInfoComponent.generalScoreLabel" | translate }}
        {{ writeGeneral }}
        <br />
        <br />
        {{ "GroupInfoComponent.businessScoreLabel" | translate }}
        {{ writeBusiness }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title
        ><mat-icon>headset</mat-icon>&nbsp;{{
          "GroupInfoComponent.listeningCardLabel" | translate
        }}</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ "GroupInfoComponent.generalScoreLabel" | translate }}
        {{ listenGeneral }}
        <br />
        <br />
        {{ "GroupInfoComponent.businessScoreLabel" | translate }}
        {{ listenBusiness }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title
        ><mat-icon>mic</mat-icon>&nbsp;{{
          "GroupInfoComponent.speakingCardLabel" | translate
        }}</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ "GroupInfoComponent.generalScoreLabel" | translate }}
        {{ speakGeneral }}
        <br />
        <br />
        {{ "GroupInfoComponent.businessScoreLabel" | translate }}
        {{ speakBusiness }}
      </p>
    </mat-card-content>
  </mat-card>
</div>
<br />
<div>
  <form
    *ngIf="!lessonLoading"
    class="filterItems"
    [formGroup]="searchLessonFilters"
    (ngSubmit)="searchLessons(searchLessonFilters.value)"
  >
    <mat-form-field>
      <mat-label>{{ "GroupInfoComponent.start" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker1"
        formControlName="from"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "GroupInfoComponent.end" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker2"
        formControlName="till"
        [min]="searchLessonFilters.controls.from.value"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2 color="primary"></mat-datepicker>
    </mat-form-field>

    <button
      mat-raised-button
      type="submit"
      class="button"
      [disabled]="searchLessonFilters.invalid"
    >
      {{ "GroupInfoComponent.searchBtn" | translate }}
    </button>
    <button
      mat-raised-button
      type="button"
      color="accent"
      (click)="resetRequest()"
    >
      {{ "Dashboard.reset_Filter" | translate }}
    </button>
  </form>
</div>
<br />
<div class="lessonHeader">
  <h3>{{ "GroupInfoComponent.lessonTitle" | translate }}</h3>

  <div class="example-button-container">
    <button
      mat-fab
      class="button circle"
      aria-label="Example icon button with a add_circle icon"
      (click)="createHandler()"
    >
      +
    </button>
  </div>
</div>
<br />
<div style="display: flex; justify-content: space-around">
  <mat-spinner *ngIf="lessonLoading"></mat-spinner>
</div>
<div *ngIf="!lessonLoading" class="lessonsContainer">
  <mat-card class="lessons-card" *ngFor="let key of lessons; index as i">
    <mat-card-header (click)="lessonNavigation(key.id, key.group_id)">
      <mat-card-title
        ><a
          >{{ i + 1 }}: Lesson ({{
            key.start | amLocale: getlocale() | amLocal | amDateFormat: "LL LT"
          }}
          -
          {{
            key.end | amLocale: getlocale() | amLocal | amDateFormat: "LL LT"
          }})</a
        ></mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <p class="text" [innerHTML]="key.remarks"></p>
    </mat-card-content>
  </mat-card>
</div>
