import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { AuthV2Service } from '@services/index';

@Injectable({ providedIn: 'root' })
export class AutoLogoutService {
  MINUTES_UNITL_AUTO_LOGOUT = 10; // in Minutes
  CHECK_INTERVALL = 1000; // in ms
  STORE_KEY = 'lastAction';
  constructor(
    private auth: AuthV2Service,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  get lastAction() {
    return +localStorage.getItem(this.STORE_KEY);
  }
  set lastAction(value) {
    localStorage.setItem(this.STORE_KEY, value.toString());
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mouseover', () => this.reset());
      document.body.addEventListener('mouseout', () => this.reset());
      document.body.addEventListener('keydown', () => this.reset());
      document.body.addEventListener('keyup', () => this.reset());
      document.body.addEventListener('keypress', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, this.CHECK_INTERVALL);
    });
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft =
      this.lastAction + this.MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout && this.auth.isLoggedIn()) {
        this.auth.logout().subscribe(
          (res) => {
            console.log('you are logout');
          },
          (error) => {
            console.log(error);
          }
        );
        // window.location.reload();
        this.router.navigate(['/auth/login']);
      }
    });
  }
}
