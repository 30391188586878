import { Injectable } from '@angular/core';
import { AuthV2Service, CustomerAuthService } from '@services/index';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthV2Guard implements CanActivate {
  constructor(
    private authService: AuthV2Service,
    private route: Router,
    private customerAuth: CustomerAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.tokenAuthentication(state);
  }

  private tokenAuthentication(state) {
    const accessToken = this.authService.getAccessToken();
    if (accessToken) {
      return true;
    } else {
      this.authService.logout();
      this.route.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
