import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AuthV2Guard, CustomerAuthGuard, StudentAuthGuard } from './guards';

import { TrainerInformationComponent } from './componentes/trainer/trainer-information/trainer-information.component';
import { ConfirmEmailComponent } from './componentes/auth/confirmation/confirm-email/confirm-email.component';
import { NewPasswordComponent } from './componentes/auth/confirmation/new-password/new-password.component';
import { TraierProfileComponent } from './componentes/trainer/traier-profile/traier-profile.component';
import { QuoteDisplayComponent } from './componentes/quote/quote-display/quote-display.component';
import { NoPageFoundComponent } from './componentes/no-page-found/no-page-found.component';
import { TrainerCVComponent } from './componentes/trainer/trainer-cv/trainer-cv.component';
import { DownloadFileComponent } from './componentes/trainer/download-file/download-file.component';
import { AboutComponent } from './componentes/about/about.component';
import { HomeComponent } from './componentes/home/home.component';
import { LoginComponent } from './componentes/auth/login';
import { QuoteDeclineComponent } from './componentes/quote/quote-decline/quote-decline.component';
import { UpdateInfoComponent } from './componentes/courseRegistration/update-info/update-info.component';
import { DisplayComponent } from './componentes/courseRegistration/display/display.component';
import { AcceptComponent } from './componentes/courseRegistration/accept/accept.component';
import { StudentDetailComponent } from './componentes/courseRegistration/student-detail/student-detail.component';
import { StudentTimeslotComponent } from './componentes/courseRegistration/student-timeslot/student-timeslot.component';
import { QuoteAcceptComponent } from './componentes/quote/quote-accept/quote-accept.component';
import { UnauthorizedComponent } from './componentes/unauthorized/unauthorized.component';
import { ChangePasswordComponent } from './componentes/trainer/change-password/change-password.component';
import { TimeSchedulerComponent } from './componentes/trainer/time-scheduler/time-scheduler.component';
import { AcceptProposalComponent } from './componentes/studentGrouping/accept-proposal/accept-proposal.component';
import { DeclineProposalComponent } from './componentes/studentGrouping/decline-proposal/decline-proposal.component';
import { DisplayProposalComponent } from './componentes/studentGrouping/display-proposal/display-proposal.component';
import { ProposalDetailsComponent } from './componentes/studentGrouping/proposal-details/proposal-details.component';
import { LessonComponent } from './componentes/studentGrouping/lesson/lesson.component';
import { ChatBoxComponent } from './componentes/trainer/chat-box/chat-box.component';
import { LessonsDetailComponent } from './componentes/lessons/lessons-detail/lessons-detail.component';
import { IntermediateEvaluationComponent } from './componentes/studentEvaluation/intermediate-evaluation/intermediate-evaluation.component';
import { FinalEvaluationComponent } from './componentes/studentEvaluation/final-evaluation/final-evaluation.component';
import { EvaluationsFeedbackComponent } from './componentes/trainer/evaluations-feedback/evaluations-feedback.component';
import { StatisticsPresenceComponent } from './componentes/statistics-presence/statistics-presence.component';
import { CancelPlanningComponent } from './componentes/makingPlanning/cancel-planning/cancel-planning.component';
import { DisplayPlanningComponent } from './componentes/makingPlanning/display-planning/display-planning.component';
import { AcceptPlanningComponent } from './componentes/makingPlanning/accept-planning/accept-planning.component';
import { TrainerDashboardComponent } from './componentes/trainer/trainer-dashboard/trainer-dashboard.component';
import { EmailVerificationComponent } from './componentes/auth/confirmation/email-verification/email-verification.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/email_authentication', component: ConfirmEmailComponent },
  {
    path: `quote/:id`,
    component: QuoteDisplayComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'quote/:id/accept',
    component: QuoteAcceptComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'quote/:id/decline',
    component: QuoteDeclineComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'students/:studentId/timeslot',
    component: StudentTimeslotComponent,
    canActivate: [StudentAuthGuard],
  },

  {
    path: 'course/registration/:id',
    component: DisplayComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'course/registration/:id/update',
    component: UpdateInfoComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'course/registration/:id/accept',
    component: AcceptComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'course/registration/:id/students',
    component: StudentDetailComponent,
    canActivate: [CustomerAuthGuard],
  },

  {
    path: `auth/password/reset`,
    component: NewPasswordComponent,
  },
  {
    path: `trainer/:id/resetPassword`,
    component: NewPasswordComponent,
  },

  {
    path: 'trainer/profile',
    component: TraierProfileComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/resume',
    component: TrainerCVComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/information',
    component: TrainerInformationComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/contract',
    component: DownloadFileComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/chat',
    component: ChatBoxComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/time/schedule',
    component: TimeSchedulerComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/password/change',
    component: ChangePasswordComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/dashboard',
    component: TrainerDashboardComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'trainer/evaluations/group',
    component: EvaluationsFeedbackComponent,
    canActivate: [AuthV2Guard],
  },

  {
    path: `trainer/group/:groupId/contract/:contractId`,
    component: DisplayPlanningComponent,
    canActivate: [AuthV2Guard],
  },

  {
    path: `trainer/group/:groupId/contract/:contractId/accept`,
    component: AcceptPlanningComponent,
    canActivate: [AuthV2Guard],
  },
  // {
  //   path: `trainer/group/:groupId/contract/:contractId/decline`,
  //   component: CancelPlanningComponent,
  //   canActivate: [AuthV2Guard],
  // },

  // {
  //   path: 'trainer/evaluations/group/:groupId',
  //   component: EvaluationsFeedbackComponent,
  //   canActivate: [AuthV2Guard],
  // },
  {
    path: 'trainer/languagetest/:id',
    component: LessonsDetailComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'auth/email/verify',
    component: EmailVerificationComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },

  {
    path: 'group',
    component: DisplayProposalComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'group/:groupId',
    component: DisplayProposalComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'course/registration/:registration/statistics',
    component: StatisticsPresenceComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'group/:groupId/lesson/:lessonId',
    component: LessonComponent,
    canActivate: [AuthV2Guard],
  },
  {
    path: 'course/registration/:registration/proposal',
    component: ProposalDetailsComponent,
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'course/registration/:registration/proposal/accept',
    component: AcceptProposalComponent,
    canActivate: [CustomerAuthGuard],
  },

  {
    path: 'course/registration/:registration/proposal/decline',
    component: DeclineProposalComponent,
    canActivate: [CustomerAuthGuard],
  },

  {
    path: 'student/evaluation/intermediate',
    component: IntermediateEvaluationComponent,
    canActivate: [StudentAuthGuard],
  },
  {
    path: 'student/evaluation/final',
    component: FinalEvaluationComponent,
    canActivate: [StudentAuthGuard],
  },

  { path: '**', component: NoPageFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],

  declarations: [
    // this component wants to have access to built-in directives
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
