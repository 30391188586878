import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class StudentRegistrationService {
  constructor(private http: HttpClient) {}
  saveInformation(data, registration: number) {
    return this.http.post<any>(
      `${API.saveStudentDataBulk}/${registration}/students`,
      data
    );
  }

  saveTimeSlot(timeSlot: number) {
    return this.http.post<any>(
      `${API.saveStudentTimeslot}/${timeSlot}/claim`,
      []
    );
  }

  getTimeSlots(perPage: number, page: number): Observable<any> {
    return this.http.get<any>(
      `${API.fetchTimeslots}?per_page=+${perPage}&+page=+${page}`
    );
  }

  getTimeSlotsForStudents(registration: number): Observable<any> {
    return this.http.get<any>(
      `${API.fetchTimeslotsForStudents}/${registration}/time-slot`
    );
  }
  requestTimeslot(data) {
    return this.http.post<any>(`${API.requestTimeslot}`, data);
  }

  studentDetailsAPI(): Observable<any> {
    return this.http.get<any>(
      `${API.studentApi}?with[]=group.trainers&with[]=registration.customer&with[]=registration.type.language`
    );
  }
}
