import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GroupInfoService,
  AuthV2Service,
  LocalLanguageService,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-lesson-report',
  templateUrl: './lesson-report.component.html',
  styleUrls: ['./lesson-report.component.sass'],
  providers: [AuthV2Service],
})
export class LessonReportComponent implements OnInit, OnChanges {
  @Input() lessonId: number;
  @Input() language: string;
  lessons = [];
  dataRecieved = false;
  laoding = false;
  studentId = null;
  students = [];
  lessonAttendanceRecieved = false;
  submitAttendance = [];
  subscription = null;
  langs = languages;
  // language = 'en';
  backGround = 'primary';
  color = 'primary';

  constructor(
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private groupInfo: GroupInfoService,
    private localLanguageService: LocalLanguageService,
  ) {}

  ngOnInit(): void {
    this.laoding = true;

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.dataRecieved = true;
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('lessonId')) {
      if (changes.lessonId.currentValue != null) {
        this.lessonId = changes.lessonId.currentValue;
      }
    }

    if (this.lessonId != null) {
      this.laoding = true;
      this.lessonAttendanceRecieved = false;
      this.submitAttendance = [];
      this.students = [];
      this.subscription = this.groupInfo.getLessons(
        this.activeRoute.snapshot.params.groupId,
      );
      this.dataRecieved = false;
      this.subscription = this.groupInfo.getLessonAttendence(this.lessonId);
      this.subscription.subscribe((res) => {
        if (res.data.length > 0) {
          this.lessonAttendanceRecieved = true;
          this.submitAttendance = res.data;
          this.laoding = false;
        }
      });
      this.subscription = this.groupInfo.getGroupStudent(
        this.activeRoute.snapshot.params.groupId,
      );
      this.subscription.subscribe((res) => {
        this.studentId = res.data[0]?.id;
        this.students = res.data;
        if (this.submitAttendance.length === 0) {
          this.students.forEach((x) => {
            this.submitAttendance.push({
              student_id: x.id,
              present: false,
              content: '',
            });
          });
        }
        this.laoding = false;
      });
      this.dataRecieved = true;

      const browserlang = this.translate.getBrowserLang();
      if (browserlang) {
        this.translate.setDefaultLang(browserlang);
      } else {
        this.translate.setDefaultLang('en');
      }
      this.dataRecieved = true;
    }
  }

  editnotes(val, i) {
    this.submitAttendance[i].content = val;
  }

  getNotes(i) {
    return this.submitAttendance[i].content;
  }

  getStudentFirstName(id) {
    let a = '';
    this.students.forEach((x) => {
      if (x.id == id) {
        a = x.first_name;
        return;
      }
    });
    return a;
  }

  getStudentLastName(id) {
    let a = '';
    this.students.forEach((x) => {
      if (x.id == id) {
        a = x.last_name;
        return;
      }
    });
    return a;
  }

  updateatendance() {
    this.subscription = this.groupInfo
      .addLessonAttendance(this.lessonId, this.submitAttendance)
      .subscribe(
        (res) => {
          this.notificationService.success('200', 'Update Successfully');
        },
        (error) => {
          this.notificationService.error(
            error.status,
            error.error && error.error.message ? error.error.message : 'Failed',
          );

          // this.uploadloading = false;
        },
      );
  }

  addatendance() {
    this.subscription = this.groupInfo
      .addLessonAttendance(this.lessonId, this.submitAttendance)
      .subscribe(
        (res) => {
          this.notificationService.success('200', 'Update Successfully');
        },
        (error) => {
          this.notificationService.error(
            error.status,
            error.error && error.error.message ? error.error.message : 'Failed',
          );

          // this.uploadloading = false;
        },
      );
  }

  checkStatus(i) {
    return this.submitAttendance[i].present;
  }

  changeStatus(i) {
    this.submitAttendance[i].present = !this.submitAttendance[i].present;
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  getlocale() {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }
}
