<div class="container">
  <h4>{{ "GoalsForm.title" | translate }}</h4>
  <form
    class="example-form"
    [formGroup]="goalsInfoForm"
    (ngSubmit)="onSubmit(goalsInfoForm.value)"
  >
    <br />
    <div class="example-full-width">
      <b>
        <label>{{ "GoalsForm.titleLabel" | translate }}<span class="star"> *</span></label></b
      >
      <br />
      <input
        type="text"
        class="example-full-width input"
        placeholder="Enter title......"
        formControlName="title"
      />
      <div
        class="alert"
        *ngIf="
          !goalsInfoForm.controls['title'].valid &&
          goalsInfoForm.controls['title'].touched
        "
      >
        <div [hidden]="!goalsInfoForm.controls['title'].errors.required">
          {{ "validations.titleReq" | translate }}

        </div>
      </div>
    </div>
    <br />
    <div class="example-full-width">
      <b
        ><label>{{ "GoalsForm.contentLabel" | translate }}<span class="star"> *</span></label></b
      >
      <br />
      <textarea
        type="text"
        class="textareaTag"
        placeholder="Enter the content....."
        formControlName="content"
      ></textarea>
      <div
        class="alert"
        *ngIf="
          !goalsInfoForm.controls['content'].valid &&
          goalsInfoForm.controls['content'].touched
        "
      >
        <div [hidden]="!goalsInfoForm.controls['content'].errors.required">
          {{ "validations.contentReq" | translate }}

        </div>
      </div>
    </div>
    <br />
    <div class="example-full-width">
      <label><b> {{ "GoalsForm.isCompleted" | translate }}</b></label>
      <br />
      <mat-radio-group aria-label="isCompleted?" formControlName="is_completed">
        <mat-radio-button [value]="true">{{ "GoalsForm.yesLabel" | translate }}</mat-radio-button>
        &nbsp;
        <mat-radio-button [value]="false">{{ "GoalsForm.noLabel" | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div mat-dialog-actions class="space">
      <button
        mat-raised-button
        type="submit"
       class="button"
        [disabled]="loading || goalsInfoForm.invalid"
      >
        {{ "GoalsForm.saveBtn" | translate }}
      </button>
      <button mat-button mat-dialog-close (click)="closeDialog()">
        {{ "GoalsForm.cancelBtn" | translate }}
      </button>
    </div>
  </form>
</div>
