import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  TrainerService,
  StudentRegistrationService,
  CourseRegisterService,
} from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import * as moment from 'moment';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl,
    form: FormGroupDirective | NgForm
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.sass'],
})
export class StudentDetailComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private trainerService: TrainerService,
    private router: ActivatedRoute,
    private route: Router,
    private studentRegisterService: StudentRegistrationService,
    private courseRegisterService: CourseRegisterService,
    private notificationService: NotificationsService,
    public translate: TranslateService
  ) {
    this.form = fb.group({
      student: fb.array([]),
    });
    this.registrationId = +this.router.snapshot.params.id;
  }
  public formDummyData = null;
  buttonDisable = false;
  public form: FormGroup;
  timeslots = [];
  availableTimeslots = [];
  dropdownSettings: any;
  registrationId: number;
  subscription = null;
  subscription2 = null;
  noOfStudents = '';
  public contactList: FormArray;
  loading = false;
  studentTimeSlots = [];
  langs = languages;
  languageLocale = '';
  submitted = false;
  length = 0;
  pageSize = 10;
  page = 0;
  pageSizeOptions: number[] = [10];
  displayedStudents = 0;
  dropdownSettingsForLanguages = {};
  public languages = [];
  students = [];
  studentsLenth: number = null;
  spinerLoading = true;
  checklimit = 0;
  flag = false;
  firstCount = 0;
  secondConunt = 0;
  recordTimeslots = [];

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Belgium,
    CountryISO.Netherlands,
  ];

  matcher = new MyErrorStateMatcher();
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  ngOnInit() {
    (this.form.get('student') as FormArray).clear();
    this.registrationDetail();
    this.fetchTimeSlots();
    this.fetchLanguages();

    // this.getStudents(this.page);
    // Dropdown field setting

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      allowSearchFilter: true,

      limitSelection: 1,
      closeDropDownOnSelection: false,
    };
    this.dropdownSettingsForLanguages = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,

      closeDropDownOnSelection: false,
    };
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
      this.languageLocale = browserlang;
    } else {
      this.translate.setDefaultLang('en');
      this.languageLocale = 'en';
    }
  }
  checkCountryInit(i, key) {
    return this.getStudentsFormGroup(i).value[key];
  }
  registrationDetail() {
    this.subscription2 = this.courseRegisterService.getRegistration(
      this.registrationId
    );

    this.subscription2.subscribe((res) => {
      this.noOfStudents = res?.data?.student_count;
      this.studentsLenth = +this.noOfStudents;
      this.spinerLoading = false;

      this.pageEvent({ pageIndex: 0 });
    });
  }
  public useLanguage(lang: string): void {
    this.translate.use(lang);
  }

  pageEvent(event) {
    (this.form.get('student') as FormArray).clear();
    this.pageSize = event.pageSize;
    this.getStudents(event.pageIndex);
  }

  getStudents(page) {
    page++;
    this.subscription = this.courseRegisterService.getRegistrationStudents(
      this.registrationId,
      10,
      page
    );
    this.page = page;
    this.subscription.subscribe(
      (res) => {
        res.data.forEach((x) => {
          if (x.time_slot && x.time_slot.start) {
            this.studentTimeSlots.push({
              value: `${moment(x.time_slot.start).format('h:mm A')} to ${moment(
                x.time_slot.end
              ).format('h:mm A MMM DD YYYY')}`,
              id: x.time_slot.id,
            });
          } else {
            this.studentTimeSlots.push({ value: null, i: null });
          }
        });
        this.formDummyData = res.data;

        this.formDrawer();
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Soming is bad, Not Found'
        );

        if (error.status === 403) {
          this.route.navigate(['/unauthorized']);
        }
      }
    );
  }
  // drawer form methods dynamically

  formDrawer() {
    let updateUserFlag = 0;
    if (this.formDummyData.length) {
      let pageTempSize = 0;

      if (this.formDummyData.length === 10) {
        pageTempSize = 10;
      } else if (this.formDummyData.length > 10) {
        pageTempSize = 10;
      } else {
        pageTempSize = this.formDummyData.length;
        this.flag = true;
      }

      if (this.formDummyData.length) {
        for (let i = 0; i < pageTempSize; i++) {
          this.updateUser(this.formDummyData[i]);
          updateUserFlag = updateUserFlag + 1;
          if (this.flag) {
            this.firstCount = this.firstCount + 1;
          }
        }

        this.checklimit = this.checklimit + pageTempSize;

        if (this.checklimit < +this.noOfStudents) {
          if (updateUserFlag < 10) {
            let addForms = 0;
            if (+this.noOfStudents >= 10) {
              addForms = 10 - this.firstCount;
            } else {
              addForms = +this.noOfStudents - this.firstCount;
            }
            for (let add = 0; add < addForms; add++) {
              this.addUser();
            }
          } else {
          }
          updateUserFlag = 0;
        }
      }

    } else {
      const studentsToDisplay = this.studentsLenth - (this.page - 1) * 10;

      let pageTempSizeAdd = 0;
      if (studentsToDisplay === 10) {
        pageTempSizeAdd = 10;
      } else if (studentsToDisplay > 10) {
        pageTempSizeAdd = 10;
      } else {
        pageTempSizeAdd = studentsToDisplay;
      }

      if (studentsToDisplay) {
        for (let i = 0; i < pageTempSizeAdd; i++) {
          this.addUser();
        }
        return 0;
      }
    }

    this.onTimeslotChange();
  }

  // A method that calls in edit case.
  setTimeslot(timeslot) {
    return {
      value: `${moment(timeslot.start).format('h:mm A')} to ${moment(
        timeslot.end
      ).format('h:mm A MMM DD YYYY')}`,
      id: timeslot.id,
    };
  }

  timeslotsofStudent(i) {
    return {
      value: `${moment(this.formDummyData[i].time_slot.start).format(
        'h:mm A'
      )} to ${moment(this.formDummyData[i].time_slot.end).format(
        'h:mm A MMM DD YYYY'
      )}`,
      id: this.formDummyData[i].time_slot.id,
    };
  }

  onLanguageSelection(i, val) {
    if (val >= 0) {
      this.getStudentsFormGroup(i).controls.primary_language_id.setValue(val);
    } else {
      this.getStudentsFormGroup(i).controls.primary_language_id.setValue(null);
    }
  }

  getLanguageSelection(i) {
    return this.getStudentsFormGroup(i).value.primary_language_id;
  }

  updateUser(student?: any) {
    let timeslot = null;
    if (student && student.time_slot) {
      timeslot = this.setTimeslot(student.time_slot);
    }
    const a = student.phone;
    const phoneNumber = parsePhoneNumberFromString(a ? a : '');
    const b = student.mobile_phone;
    const mobilePhoneNumber = parsePhoneNumberFromString(b ? b : '');
    let initmobile = '';

    if (mobilePhoneNumber) {
      initmobile = mobilePhoneNumber.country.toLocaleLowerCase();
    }
    let initphone = '';
    if (phoneNumber) {
      initphone = phoneNumber.country.toLocaleLowerCase();
    }
    const fg = this.fb.group({
      first_name: [
        student ? student.first_name : '',
        Validators.compose([Validators.required]),
      ],
      last_name: [
        student ? student.last_name : '',
        Validators.compose([Validators.required]),
      ],
      countryInitmobile: initmobile,
      countryInitphone: initphone,

      locale: [student ? student.locale : this.languageLocale],

      phone: new FormControl(phoneNumber ? phoneNumber.nationalNumber : '', []),
      social_security_number: [
        student ? student.social_security_number : '',
        Validators.compose([Validators.required]),
      ],
      email: [
        student ? student.email : '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      mobile_phone: new FormControl(
        mobilePhoneNumber ? mobilePhoneNumber.nationalNumber : '',
        []
      ),
      job_title: [student ? student.job_title : ''],
      time_slot: [timeslot ? timeslot.value : ''],
      time_slot_id: [timeslot ? timeslot.id : null],
      primary_language: [
        student ? student.primary_language.name : '',
        Validators.compose([Validators.required]),
      ],
      primary_language_id: [student ? student.primary_language.id : null],
      studentId: [student ? student.id : null],
    });

    if (timeslot) {
    }

    (this.form.get('student') as FormArray).push(fg);
  }

  addUser() {
    const fg = this.fb.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      locale: [],
      phone: new FormControl(null, []),
      countryInitmobile: '',
      countryInitphone: '',
      social_security_number: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobile_phone: new FormControl(null, []),
      job_title: [],
      time_slot: new FormControl(null),
      time_slot_id: new FormControl(null),
      primary_language: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      primary_language_id: [null],
    });

    (this.form.get('student') as FormArray).push(fg);
  }

  onCancle() {
    this.form.reset();
  }

  // Method if a student select one time remaining students can't select this timeslot
  onTimeslotChange() {
    (this.form.controls.student as FormArray).controls.forEach(
      (student: FormGroup) => {
        student.controls.time_slot_id.setValue(
          student.value.time_slot ? student.value.time_slot.id : null
        );
      }
    );

    this.availableTimeslots =
      this.timeslots &&
      this.timeslots.filter(
        (timeslot) =>
          !this.form.value.student.find(
            (student) =>
              student.time_slot && student.time_slot.id === timeslot.id
          )
      );
  }

  fetchTimeSlots(): void {
    const pageTimeSlot = 0;
    this.studentRegisterService
      .getTimeSlotsForStudents(this.registrationId)
      .subscribe((data) => {
        this.length = data.meta.total;
        this.timeslots = data.data.length
          ? data.data.map((key) => {
            return {
              value: `${moment(key.start).format('h:mm A')} to ${moment(
                key.end
              ).format('h:mm A MMM DD YYYY')}`,
              id: key.id,
            };
          })
          : null;
        this.recordTimeslots = this.timeslots;
      });
  }

  getStudentsFormGroup(index): FormGroup {
    this.contactList = this.form.get('student') as FormArray;

    const formGroup = this.contactList.controls[index] as FormGroup;
    return formGroup;
  }

  onSubmit(formValue): void {
    this.submitted = true;
    this.loading = true;
    this.students = [];

    let timesId = null;
    let timename = null;

    for (const key of formValue.student) {
      if (key.phone != null) {
        key.phone = key.phone.internationalNumber;
      }
      if (key.mobile_phone != null) {
        key.mobile_phone = key.mobile_phone.internationalNumber;
      }

      let languageId = '';

      if (key.time_slot_id && key.time_slot_id[0] && key.time_slot_id[0].id) {
        key.time_slot = key.time_slot_id.value;
        timename = key.time_slot_id[0];
        timesId = +key.time_slot_id[0].id;
        delete key.time_slot_id;
      }

      key.time_slot_id = timesId;
      key.time_slot = timename;
      if (key.primary_language && key.primary_language[0].id) {
        languageId = key.primary_language[0].id;
        key.primary_language_id = parseInt(languageId, 10);
      }
      delete key.timeslot;
      delete key.language;
      if (key.studentId) {
        key.id = key.studentId;
      }

      key.locale = key.locale ? key.locale : this.languageLocale;
      this.students.push(key);
    }

    this.studentRegisterService
      .saveInformation(this.students, this.registrationId)
      .subscribe(
        (data) => {
          if (data) {
            this.buttonDisable = false;
            this.loading = false;
            this.notificationService.success(
              '200',
              'Successfully, Saved your information. '
            );
          }
        },
        (error) => {
          this.loading = false;
          this.buttonDisable = false;
          this.notificationService.error(error.status, error.error.message);
        }
      );
  }

  fetchLanguages(): void {
    this.trainerService.getlanguages().subscribe((data: any) => {
      this.languages = data.data.map((e) => ({
        id: e.id,
        name: e.name,
      }));
    });
  }

  compareById(first: any, second: any): boolean {
    return first && second ? first.id === second.id : first === second;
  }
}
