<mat-drawer mode="side" opened style="z-index: 0">
  <div class="studentDrawer">
    {{ "LessonDetails.allStudents" | translate }}
  </div>
  <mat-action-list style="padding-top: 0">
    <div *ngFor="let key of students">
      <mat-list-item
        [ngStyle]="{
          'background-color': key.id === studentId ? '#252e4b' : '#ffffff',
          color: key.id === studentId ? '#ffffff' : '#252e4b'
        }"
        role="listitem"
        class="listdata"
        style="height: 35px; border-bottom: 1px solid lightgray"
        (click)="showInfo(key.id)"
        >{{ key.name }}</mat-list-item
      >
    </div>
  </mat-action-list>
</mat-drawer>
<mat-drawer-content>
  <div style="position: absolute; left: 32%; top: 19%; z-index: 1">
    <mat-spinner *ngIf="lessonAttendanceRecieved"></mat-spinner>
  </div>
  <mat-card style="width: 100%">
    <table *ngIf="students.length > 0">
      <thead></thead>
      <tbody>
        <tr>
          <td>{{ "LessonDetails.idColumn" | translate }}</td>
          <td>{{ studentDetail.id }}</td>
        </tr>
        <tr>
          <td>{{ "StudentInfo.firstName" | translate }}</td>
          <td>{{ studentDetail.first_name }}</td>
        </tr>
        <tr>
          <td>{{ "StudentInfo.lastName" | translate }}</td>
          <td>{{ studentDetail.last_name }}</td>
        </tr>
        <tr>
          <td>{{ "LessonDetails.emailLabel" | translate }}</td>
          <td>{{ studentDetail.email }}</td>
        </tr>
        <tr>
          <td>{{ "LessonDetails.phoneLabel" | translate }}</td>
          <td>{{ studentDetail.phone }}</td>
        </tr>
        <tr>
          <td>{{ "LessonDetails.mobileLabel" | translate }}</td>
          <td>{{ studentDetail.mobile_phone }}</td>
        </tr>
        <tr>
          <td>{{ "LessonDetails.statusColumn" | translate }}</td>
          <td>{{ showStatus(studentDetail.id) }}</td>
        </tr>
        <tr>
          <td>{{ "LessonDetails.remarksColumn" | translate }}</td>
          <td>{{ showRemarks(studentDetail.id) }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="students.length === 0">
      <br />
      <h2 style="text-align: center">No Student Found</h2>
      <br />
    </div>
  </mat-card>
</mat-drawer-content>
