<mat-card style="width: 100%">
  <div style="display: flex; justify-content: space-around; z-index: 1">
    <mat-spinner *ngIf="laoding"></mat-spinner>
  </div>
  <div *ngIf="students.length > 0">
    <Table *ngIf="students.length > 0" class="table table-striped tableStyle">
      <thead>
        <tr>
          <th>{{ "LessonDetails.idColumn" | translate }}</th>
          <th>{{ "StudentInfo.firstName" | translate }}</th>
          <th>{{ "StudentInfo.lastName" | translate }}</th>
          <th>{{ "LessonDetails.statusColumn" | translate }}</th>
          <th>{{ "LessonDetails.remarksColumn" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let key of submitAttendance; index as i">
          <td>
            {{ key.student_id }}
          </td>
          <td>
            {{ getStudentFirstName(key.student_id) }}
          </td>
          <td>
            {{ getStudentLastName(key.student_id) }}
          </td>
          <td>
            <div style="display: flex; flex-direction: column">
              <mat-checkbox
                [checked]="checkStatus(i)"
                (change)="changeStatus(i)"
                class="example-margin"
                >{{ "LessonDetails.presentCheck" | translate }}</mat-checkbox
              >
              <mat-checkbox
                [checked]="!checkStatus(i)"
                (change)="changeStatus(i)"
                class="example-margin"
                >{{ "LessonDetails.absentCheck" | translate }}</mat-checkbox
              >
            </div>
          </td>
          <td>
            <textarea
              (input)="editnotes($event.target.value, i)"
              class="remarks"
              [value]="getNotes(i)"
              matInput
              placeholder="Remarks"
              rows="3"
              cols="16"
            ></textarea>
          </td>
        </tr>
      </tbody>
    </Table>
    <div style="display: flex; justify-content: space-around; margin-top: 15px">
      <button
        *ngIf="!lessonAttendanceRecieved"
        mat-raised-button
        class="button"
        type="button"
        (click)="addatendance()"
      >
        {{ "LessonDetails.saveBtn" | translate }}
      </button>
      <button
        *ngIf="lessonAttendanceRecieved"
        mat-raised-button
        class="button"
        type="button"
        (click)="updateatendance()"
      >
        {{ "LessonDetails.updateBtn" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="students.length === 0">
    <br />
    <h2 style="text-align: center">No Student Found</h2>
    <br />
  </div>
</mat-card>
