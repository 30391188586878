import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from '../common/delete-confirmation/delete-confirmation.component';
import { LessonsUpdateFormComponent } from '../common/lessons-update-form/lessons-update-form.component';
import {
  GroupInfoService,
  AuthV2Service,
  LessonCRUDService,
  LocalLanguageService,
} from '@services/index';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.sass'],
  providers: [AuthV2Service],
})
export class GroupInfoComponent implements OnInit, OnChanges {
  @Input() groupId: number;
  @Input() language: string;
  searchLessonFilters: FormGroup;
  gId = null;
  listenBusiness = null;
  listenGeneral = null;
  name = null;
  locale = 'en';
  readBusiness = null;
  readGeneral = null;
  subscription = null;
  remarks = null;
  loading = false;
  scheduleRemarks = null;
  speakBusiness = null;
  speakGeneral = null;
  writeBusiness = null;
  writeGeneral = null;
  lessons = [];
  lessonLoading = false;
  grId = 0;
  lessonData = {};
  langs = languages;
  localLanguage = 'en';
  // language: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private groupInfo: GroupInfoService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private lessonService: LessonCRUDService,
    private localLanguageService: LocalLanguageService,
    private notificationService: NotificationsService
  ) {}

  ngOnChanges(changes) {

    if (changes.hasOwnProperty('groupId')) {

      if (changes.groupId.currentValue != null){
        this.gId = changes.groupId.currentValue;
      }
    }
    if (this.gId != null ) {
      this.loading = true;
      this.subscription = this.groupInfo.getGroupDetails(
        this.gId
      );
      this.subscription.subscribe((res) => {

      this.listenBusiness =
        res.data.listen_business != null
          ? res.data.listen_business
          : '';
      this.listenGeneral =
        res.data.listen_general != null
          ? res.data.listen_general
          : '';
      this.name = res.data.name;
      this.readBusiness =
        res.data.read_business != null
          ? res.data.read_business
          : '';
      this.readGeneral =
        res.data.read_general != null
          ? res.data.read_general
          : '';
      this.remarks =
        res.data.remarks != null ? res.data.remarks : '';
      this.scheduleRemarks =
        res.data.schedule_remarks != null
          ? res.data.schedule_remarks
          : '';
      this.speakBusiness =
        res.data.speak_business != null
          ? res.data.speak_business
          : '';
      this.speakGeneral =
        res.data.speak_general != null
          ? res.data.speak_general
          : '';
      this.writeBusiness =
        res.data.write_business != null
          ? res.data.write_business
          : '';
      this.writeGeneral =
        res.data.write_general != null
          ? res.data.write_general
          : '';
      this.loading = false;
      });
      this.lessonLoading = true;
      this.subscription = this.groupInfo.getLessons(
        this.gId
      );
      this.subscription.subscribe((res) => {
         this.lessons = res.data;
         this.lessonLoading = false;
      });
    }
  }

  request():void{
    if (this.gId != null){
      this.lessonLoading = true;
      this.subscription = this.groupInfo.getLessons(
        this.gId
      );
      this.subscription.subscribe((res) => {
        this.lessons = res.data;
        this.lessonLoading = false;
      });
    }
  }

  resetRequest(): void{
    this.searchLessonFilters = this.formBuilder.group({
      from: new FormControl('', Validators.required),
      till: new FormControl('', Validators.required),
    });
    if (this.gId != null){
      this.lessonLoading = true;
      this.subscription = this.groupInfo.getLessons(
        this.gId
      );
      this.subscription.subscribe((res) => {
        this.lessons = res.data;
        this.lessonLoading = false;
      });
    }
  }

  ngOnInit(): void {
    this.searchLessonFilters = this.formBuilder.group({
      from: new FormControl('', Validators.required),
      till: new FormControl('', Validators.required),
    });
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  endDateFilter() {
    const endFilter = new Date(this.searchLessonFilters.controls.from.value);
    return endFilter.setDate(endFilter.getDate() + 1);
  }

  getlocale(): string {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }

  lessonNavigation(lessonId, groupId): void {
    this.router.navigate([`/group/${groupId}/lesson/${lessonId}`]);
  }

  onEventClick(lessonId): void {
    this.router.navigate([`/group/${this.gId}/lesson/${lessonId}`]);
  }

  openDeleteConfirmator(): void {
    this.dialog.open(DeleteConfirmationComponent);
  }

  createHandler(): void {
    this.lessonData = null;
    this.openLessonForm();
  }

  editHandler(value): void {
    this.lessonData = value;
    this.openLessonForm();
  }

  openLessonForm(): void {
    let dataForm = {};
    if (this.lessonData == null) {
      dataForm = {};
    } else {
      dataForm = this.lessonData;
    }
    const dialogRef = this.dialog.open(LessonsUpdateFormComponent, {
      width: '50%',
      height: '70%',
      data: {
        id: 1,
        language: this.language,
        groupId: this.gId,
        lessonDetails: dataForm,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.request();
    });
  }


  searchLessons(value): void {
    this.lessonLoading = true;
    value.from = moment(value.from).add(1, 'days').toISOString();
    value.till = moment(value.till).add(1, 'days').toISOString();
    this.lessonService
      .searchLessons(this.groupId, value.from, value.till)
      .subscribe((res) => {
        this.lessons = res.data;
        this.lessonLoading = false;
      });
  }
}
