import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MakingPlanningService {
  constructor(private http: HttpClient) {}

  // Get offer/Quotation details

  getPlanningContract(groupId: number, contractId: number): Observable<any> {
    return this.http.get<any>(
      `${API.contractDisplay}/${groupId}/course-contract/${contractId}`
    );
  }

  getContractFile(groupId: number, contractId: number): Observable<any> {
    return this.http.get<any>(
      `${API.contractFile}/${groupId}/course-contract/${contractId}/download`,
      {responseType: 'blob' as 'json'}
    );
  }

  // Accept the Quotation

  acceptPlanningContract(groupId: number, contractId: number, offer) {
    return this.http
      .post<any>(
        `${API.contractDisplay}/${groupId}/course-contract/${contractId}/accept`,
        offer,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          // a switch to get progress and response from a file uploading section
          switch (event.type) {
            case HttpEventType.UploadProgress:
              // calculate the file uploading progress.
              const uploadProgress = Math.round(
                (100 * event.loaded) / event.total
              );
              return { status: 'progress', message: uploadProgress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  // Decline the Quotation

  declinePlanningContract(groupId: number, contractId: number, offer: {message: string}) {
    return this.http.post(
      `${API.contractDisplay}/${groupId}/course-contract/${contractId}/decline`,
      offer
    );
  }
}
