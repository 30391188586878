<div style="position: absolute; z-index: 1; left: 45%; top: 50%">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<br />
<div class="wrapper">
  <form
    [formGroup]="timeSlotDetail"
    (ngSubmit)="updateSlot(timeSlotDetail.value)"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <mat-card class="mb-4">
            <p>
              {{ "InitialLangaugeTest.evaluatorLabel" | translate
              }}{{ trainerName }}
            </p>
            <p>
              {{ "InitialLangaugeTest.studentLabel" | translate }}{{ sName }}
            </p>
            <p>
              {{ "InitialLangaugeTest.emailLabel" | translate }}{{ sEmail }}
            </p>
          </mat-card>
        </div>
        <div class="col-lg-4">
          <mat-card class="mb-4">
            <div *ngIf="sCompany">
              <p>
                {{ "InitialLangaugeTest.companyLabel" | translate
                }}{{ customerName }}
              </p>
              <p>
                {{ "InitialLangaugeTest.functionLabel" | translate
                }}{{ sFunction }}
              </p>
            </div>
            <p>{{ "InitialLangaugeTest.telLabel" | translate }}{{ sTel }}</p>
          </mat-card>
        </div>
        <div class="col-lg-4">
          <mat-card class="mb-4">
            <p>
              {{ "InitialLangaugeTest.mobileLabel" | translate }}{{ sMobile }}
            </p>
            <p>
              {{ "InitialLangaugeTest.nativeLangLabel" | translate
              }}{{ sNativeLanguage }}
            </p>
            <p>
              {{ "InitialLangaugeTest.mainLanguageLabel" | translate
              }}{{ sMainLanguage }}
            </p>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-lg-4">
          <mat-card class="mb-4">
            <mat-form-field class="full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.availabilityLabel" | translate
              }}</mat-label>
              <textarea
                type="text"
                class="form-control"
                formControlName="availability"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-4">
          <mat-card class="mb-4">
            <mat-form-field class="full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.commentsLabel" | translate
              }}</mat-label>
              <textarea
                type="text"
                class="form-control"
                formControlName="comments"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-4">
          <mat-card class="mb-4">
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.yourJobLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="job_type"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-6">
          <mat-card class="mb-4">
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.yourDutiesLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="job_duties"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-6">
          <mat-card class="mb-4">
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label
                >{{ "InitialLangaugeTest.courseEnollmentLabel" | translate }}
              </mat-label>
              <textarea
                class="form-control"
                formControlName="why_you_learn"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <mat-card style="width: 100%">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 mb-4">
              <p>
                {{ "InitialLangaugeTest.communicationType.label1" | translate
                }}{{ trainerLanguage
                }}{{
                  "InitialLangaugeTest.communicationType.label2" | translate
                }}
              </p>
              <mat-checkbox
                (change)="editCommunicationWith('colleagues')"
                [checked]="checkCommunicationWith('colleagues')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.communicationType.colleaguesLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editCommunicationWith('boss')"
                [checked]="checkCommunicationWith('boss')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.communicationType.bossLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editCommunicationWith('customers')"
                [checked]="
                  checkCommunicationWith('internal_/_external_customers')
                "
                class="example-margin"
                >{{
                  "InitialLangaugeTest.communicationType.customersLabel"
                    | translate
                }}</mat-checkbox
              >
              <br />
              <mat-checkbox
                (change)="
                  changeCommunicationWith(!communicationWithOther, [
                    'colleagues',
                    'boss',
                    'customers'
                  ])
                "
                [checked]="
                  checkCommunicationWithOther([
                    'colleagues',
                    'boss',
                    'customers'
                  ])
                "
                class="example-margin"
                >{{
                  "InitialLangaugeTest.communicationType.othersLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <input
                *ngIf="communicationWithOther"
                matInput
                placeholder="Other value"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="
                  editCommunicationWithInput(
                    ['colleagues', 'boss', 'customers'],
                    $event.target.value
                  )
                "
                [value]="
                  checkCommunicationWithInput([
                    'colleagues',
                    'boss',
                    'customers'
                  ])
                "
              />
            </div>
            <div class="col-lg-4 mb-4">
              <p>
                {{
                  "InitialLangaugeTest.workOfSituations.noteLabel" | translate
                }}
                {{ trainerLanguage }}?
              </p>
              <mat-checkbox
                (change)="editSituation('on_the_phone')"
                [checked]="checkSituation('on_the_phone')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.workOfSituations.phoneLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editSituation('direct_calls')"
                [checked]="checkSituation('direct_calls')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.workOfSituations.callLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editSituation('during_meetings')"
                [checked]="checkSituation('during_meetings')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.workOfSituations.meetingLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editSituation('in_writing')"
                [checked]="checkSituation('in_writing')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.workOfSituations.writtingLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="
                  changeSituation(!situationOther, [
                    'on_the_phone',
                    'direct_calls',
                    'during_meetings',
                    'in_writing'
                  ])
                "
                [checked]="
                  checkSituationOther([
                    'on_the_phone',
                    'direct_calls',
                    'during_meetings',
                    'in_writing'
                  ])
                "
                class="example-margin"
                >{{
                  "InitialLangaugeTest.workOfSituations.otherLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <input
                *ngIf="situationOther"
                matInput
                placeholder="Other value"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="
                  editSituationInput(
                    [
                      'on_the_phone',
                      'direct_calls',
                      'during_meetings',
                      'in_writing'
                    ],
                    $event.target.value
                  )
                "
                [value]="
                  checkSituationInput([
                    'on_the_phone',
                    'direct_calls',
                    'during_meetings',
                    'in_writing'
                  ])
                "
              />
            </div>
            <div class="col-lg-4 mb-4">
              <p>
                {{ "InitialLangaugeTest.hearAndSpeak.label1" | translate }}
                {{ trainerLanguage }}
                {{ "InitialLangaugeTest.hearAndSpeak.label2" | translate }}
              </p>
              <mat-checkbox
                (change)="editHearSpeak('daily')"
                [checked]="checkHearSpeak('daily')"
                class="example-margin"
              >
                {{
                  "InitialLangaugeTest.hearAndSpeak.dailyLabel" | translate
                }}</mat-checkbox
              >
              <br />
              <mat-checkbox
                (change)="editHearSpeak('often')"
                [checked]="checkHearSpeak('often')"
                class="example-margin"
              >
                {{
                  "InitialLangaugeTest.hearAndSpeak.oftenLabel" | translate
                }}</mat-checkbox
              >
              <br />
              <mat-checkbox
                (change)="editHearSpeak('occasionally')"
                [checked]="checkHearSpeak('occasionally')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.hearAndSpeak.occasionallyLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editHearSpeak('almost_never')"
                [checked]="checkHearSpeak('almost_never')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.hearAndSpeak.almostNeverLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editHearSpeak('never')"
                [checked]="checkHearSpeak('never')"
                class="example-margin"
                >{{ "InitialLangaugeTest.hearAndSpeak.neverLabel" | translate }}
              </mat-checkbox>
            </div>
            <div class="col-lg-4 mb-4">
              <p>
                {{ "InitialLangaugeTest.firstPlaceGoal.noteLabel" | translate }}
              </p>
              <mat-checkbox
                (change)="editWant('better_understand')"
                [checked]="checkWant('better_understand')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.understandingLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editWant('fluent')"
                [checked]="checkWant('fluent')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.fluentLabel" | translate
                }}</mat-checkbox
              >
              <br />
              <mat-checkbox
                (change)="editWant('speak_without_mistakes')"
                [checked]="checkWant('speak_without_mistakes')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.speakLabel" | translate
                }}</mat-checkbox
              >
              <br />
              <mat-checkbox
                (change)="editWant('expand_general_or_professional_vocabulary')"
                [checked]="
                  checkWant('expand_general_or_professional_vocabulary')
                "
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.vocabularyLabel"
                    | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editWant('better')"
                [checked]="checkWant('better')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.betterLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="editWant('writing_texts')"
                [checked]="checkWant('writing_texts')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.wrtingLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <mat-checkbox
                (change)="changeWant()"
                [checked]="
                  checkWantOther([
                    'better',
                    'writing_texts',
                    'better_understand',
                    'fluent',
                    'speak_without_mistakes',
                    'expand_general_or_professional_vocabulary'
                  ])
                "
                class="example-margin"
                >{{
                  "InitialLangaugeTest.firstPlaceGoal.othersLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <input
                *ngIf="wantOther"
                matInput
                placeholder="Other value"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="editWantInput($event.target.value)"
                [value]="checkWantInput()"
              />
            </div>
            <div class="col-lg-4 mb-4">
              <p>
                {{ "InitialLangaugeTest.placeOfLearn.note" | translate
                }}{{ trainerLanguage }}?
              </p>
              <mat-checkbox
                (change)="editLearn('at_school')"
                [checked]="checkLearn('at_school')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.placeOfLearn.schoolLabel" | translate
                }}
              </mat-checkbox>
              <input
                *ngIf="checkLearn('at_school')"
                matInput
                placeholder="School name"
                style="
                  width: fit-content;
                  border-bottom: 1px solid grey;
                  display: block;
                "
                (input)="editLearnInput('at_school', $event.target.value)"
                [value]="checkLearnInput('at_school')"
              />
              <br />
              <mat-checkbox
                (change)="editLearn('during_higher_studies')"
                [checked]="checkLearn('during_higher_studies')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.placeOfLearn.higherStudiesLabel"
                    | translate
                }}</mat-checkbox
              >
              <input
                *ngIf="checkLearn('during_higher_studies')"
                matInput
                placeholder="Enter name"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="
                  editLearnInput('during_higher_studies', $event.target.value)
                "
                [value]="checkLearnInput('during_higher_studies')"
              />
              <br />
              <mat-checkbox
                (change)="editLearn('speaking_contacts')"
                [checked]="checkLearn('speaking_contacts')"
                class="example-margin"
              >
                {{ trainerLanguage
                }}{{
                  "InitialLangaugeTest.placeOfLearn.speakingLabel" | translate
                }}</mat-checkbox
              >
              <input
                *ngIf="checkLearn('speaking_contacts')"
                matInput
                placeholder="Enter name"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="
                  editLearnInput('speaking_contacts', $event.target.value)
                "
                [value]="checkLearnInput('speaking_contacts')"
              />
              <br />
              <mat-checkbox
                (change)="editLearn('stay')"
                [checked]="checkLearn('stay')"
                class="example-margin"
                >{{ "InitialLangaugeTest.placeOfLearn.stayLabel" | translate }}
              </mat-checkbox>
              <input
                *ngIf="checkLearn('stay')"
                matInput
                placeholder="place name"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="editLearnInput('stay', $event.target.value)"
                [value]="checkLearnInput('stay')"
              />
              <br />
              <mat-checkbox
                (change)="editLearn('in_the_workplace')"
                [checked]="checkLearn('in_the_workplace')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.placeOfLearn.workplaceLabel" | translate
                }}
              </mat-checkbox>
              <input
                *ngIf="checkLearn('in_the_workplace')"
                matInput
                placeholder="name"
                style="
                  width: fit-content;
                  border-bottom: 1px solid grey;
                  display: block;
                "
                (input)="
                  editLearnInput('in_the_workplace', $event.target.value)
                "
                [value]="checkLearnInput('in_the_workplace')"
              />
              <br />
              <mat-checkbox
                (change)="editLearn('through_language_courses')"
                [checked]="checkLearn('through_language_courses')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.placeOfLearn.throughLanguagelabel"
                    | translate
                }}
              </mat-checkbox>
              <input
                *ngIf="checkLearn('through_language_courses')"
                matInput
                placeholder="name"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="
                  editLearnInput(
                    'through_language_courses',
                    $event.target.value
                  )
                "
                [value]="checkLearnInput('through_language_courses')"
              />
              <br />
              <mat-checkbox
                (change)="editLearn('other', true)"
                [checked]="checkLearn('other')"
                class="example-margin"
                >{{
                  "InitialLangaugeTest.placeOfLearn.othersLabel" | translate
                }}
              </mat-checkbox>
              <br />
              <input
                *ngIf="learnOther"
                matInput
                placeholder="Other value"
                style="width: fit-content; border-bottom: 1px solid grey"
                (input)="editLearnInput('other', $event.target.value)"
                [value]="checkLearnInput('other')"
              />
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="container-fluid">
      <mat-card class="mt-4 mb-4">
        <div style="display: flex; flex-direction: column">
          <p>{{ "InitialLangaugeTest.otherLangauges.note" | translate }}</p>
          <div style="display: flex; flex-wrap: wrap">
            <div *ngFor="let key of languages; index as i">
              <div style="flex: 1 0 21%; margin-right: 50px">
                <mat-checkbox
                  class="form-control"
                  (change)="editLanguage(key.id)"
                  [checked]="checkLanguage(key.id)"
                  class="example-margin"
                  >{{ key.name }}
                </mat-checkbox>
              </div>
            </div>
            <div style="display: flex">
              <mat-checkbox
                class="form-control"
                (change)="toggleOtherLanguage()"
                class="example-margin"
                [checked]="isOtherLanguage"
              >
                {{
                  "InitialLangaugeTest.otherLangauges.otherLabel" | translate
                }}
              </mat-checkbox>
              <input
                *ngIf="isOtherLanguage"
                class="form-control"
                formControlName="speak_language_other"
                matInput
                placeholder="Other value"
                style="
                  border-bottom: 1px solid grey;
                  margin-left: 20px;
                  margin-top: -7px;
                "
              />
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 mt-4 mb-4">
          <mat-card>
            <mat-form-field class="full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.conversationLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="what_these_conversation_about"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-6 mt-4 mb-4">
          <mat-card>
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label
                >{{ "InitialLangaugeTest.difficultyLevel" | translate }}
                {{ trainerLanguage }}?</mat-label
              >
              <textarea
                class="form-control"
                formControlName="how_difficult_to_hear_language"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 mb-4">
          <mat-card>
            <mat-form-field class="full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.understandingLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="understanding"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-3 mb-4">
          <mat-card>
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.interactionLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="speaking_and_interaction"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-3 mb-4">
          <mat-card>
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.fluencyLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="fluency"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-3 mb-4">
          <mat-card>
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.genVocabularyLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="general_vocabulary"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 mb-4">
          <mat-card>
            <mat-form-field class="full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.busVocabularyLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="business_vocabulary"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-4 mb-4">
          <mat-card>
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.grammarLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="grammar_and_structure"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-lg-4 mb-4">
          <mat-card>
            <mat-form-field class="example-full-width" style="width: 100%">
              <mat-label>{{
                "InitialLangaugeTest.statementLabel" | translate
              }}</mat-label>
              <textarea
                class="form-control"
                formControlName="statement"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                matInput
                placeholder="Leave a comment"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>
      </div>
    </div>
    <div id="questions" class="container-fluid">
      <div class="row">
        <div class="col-lg-12 mb-4">
          <mat-card style="width: 100%">
            <div>
              <div
                *ngFor="let key of questions; index as i"
                style="display: flex; flex-direction: column"
              >
                <mat-checkbox
                  (change)="editanswers(key.id)"
                  [checked]="checkanswers(key.id)"
                  class="example-margin"
                  >{{ key.label }}
                </mat-checkbox>
                <textarea
                  *ngIf="checkanswers(key.id)"
                  (input)="editQAnswer(key.id, $event.target.value)"
                  [value]="checkQAnswer(key.id)"
                  style="width: 50%; background: lightgrey; border-radius: 13px"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                  matInput
                  placeholder="Write answer here"
                ></textarea>
              </div>
              <div
                *ngFor="let key of customQuestions; index as i"
                style="display: flex; flex-direction: column"
              >
                <div style="display: flex; flex-direction: row">
                  <mat-checkbox
                    (change)="editCustomAnswers(key.question)"
                    [checked]="checkCustomAnswers(key.question)"
                    class="example-margin"
                  >
                    {{ key.question }}
                  </mat-checkbox>
                  <mat-icon
                    color="warn"
                    (click)="delCostomQ(key.question)"
                    style="cursor: pointer"
                  >
                    delete</mat-icon
                  >
                </div>
                <textarea
                  *ngIf="checkCustomAnswers(key.question)"
                  (input)="editCustomQAnswer(key.question, $event.target.value)"
                  [value]="checkCustomQAnswer(key.question)"
                  style="width: 50%; background: lightgrey; border-radius: 13px"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                  matInput
                  placeholder="Write answer here"
                ></textarea>
              </div>
            </div>
            <button
              mat-mini-fab
              style="margin-left: 44%"
              color="primary"
              type="button"
              (click)="openDialog()"
            >
              +
            </button>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 mb-4">
          <mat-card style="width: 100%">
            <table style="width: 100%">
              <tr>
                <td>{{ "InitialLangaugeTest.speakLabel" | translate }}</td>
                <td>{{ "InitialLangaugeTest.gerneralLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="speak_general"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>{{ "InitialLangaugeTest.businessLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="speak_business"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td>{{ "InitialLangaugeTest.listenLabel" | translate }}</td>
                <td>{{ "InitialLangaugeTest.gerneralLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="listen_general"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>{{ "InitialLangaugeTest.businessLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="listen_business"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td>{{ "InitialLangaugeTest.readLabel" | translate }}</td>
                <td>{{ "InitialLangaugeTest.gerneralLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="read_general"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>{{ "InitialLangaugeTest.businessLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="read_business"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td>{{ "InitialLangaugeTest.writeLabel" | translate }}</td>
                <td>{{ "InitialLangaugeTest.gerneralLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="write_general"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>{{ "InitialLangaugeTest.businessLabel" | translate }}</td>
                <td>
                  <select
                    class="form-control"
                    formControlName="write_business"
                    name="singleSelect"
                    ng-model="data.singleSelect"
                  >
                    <optgroup label="Beginner ">
                      <option [value]="1">A01</option>
                      <option [value]="2">A02</option>
                    </optgroup>
                    <optgroup label="Basic user  ">
                      <option [value]="3">A1</option>
                      <option [value]="4">A2</option>
                    </optgroup>
                    <optgroup label="Independent user">
                      <option [value]="5">B1</option>
                      <option [value]="6">B2</option>
                    </optgroup>
                  </select>
                </td>
              </tr>
            </table>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <mat-card style="width: 100%">
          <button
            *ngIf="gotDataId == null"
            mat-raised-button
            style="margin-left: 43%"
            color="primary"
            type="submit"
            class="btn btn-primary"
          >
            {{ "InitialLangaugeTest.saveBtn" | translate }}
          </button>
          <button
            *ngIf="gotDataId != null"
            mat-raised-button
            style="margin-left: 43%"
            color="primary"
            type="submit"
            class="btn btn-primary"
          >
            {{ "InitialLangaugeTest.updateBtn" | translate }}
          </button>
        </mat-card>
      </div>
    </div>
  </form>
</div>
