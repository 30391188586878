<br />
<br />

<div class="lessonHeader">
  <h3>{{ "AddtionalInfoComponent.title" | translate }}</h3>

  <div class="example-button-container">
    <button
      mat-fab
      class="button"
      aria-label="Example icon button with a add_circle icon"
      (click)="createHandler()"
    >
      +
    </button>
  </div>
</div>
<br />
<div style="display: flex; justify-content: space-around;">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div *ngIf="!loading">
  
  <mat-card class="lessons-card" *ngFor="let key of files; index as i">
    <mat-card-header >
      <mat-card-title *ngIf="key.fil==null"> {{ key.title }} </mat-card-title>
      <a *ngIf="key.file!=null" href="{{ key['file'] }}" target="_blank"
          ><mat-icon>book</mat-icon><span class="aligned-with-icon">{{key['file_name']}}</span></a
        >
      <div style="position: absolute; right: 13px; top: 8px;">
        <button mat-button (click)="editHandler(key)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-button (click)="openDeleteConfirmator(key)">
          <mat-icon color="warn">delete</mat-icon>
        </button>
    </div>
    </mat-card-header>
    <mat-card-content *ngIf="key.file==null">
      <p class="text" [innerHTML]="key.content"></p>
    </mat-card-content>
    <mat-card-actions> </mat-card-actions>
  </mat-card>
</div>
