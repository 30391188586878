import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferService, LocalLanguageService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-quote-accept',
  templateUrl: './quote-accept.component.html',
  styleUrls: ['./quote-accept.component.sass'],
})
export class QuoteAcceptComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  quoteId = 0;
  isAgreed = 0;
  error: string;
  checked = 0;
  loading = false;
  pageLoading = false;
  langs = languages;
  acceptQuoteForm: FormGroup;
  file = { status: '', message: '', filePath: '' };
  onAccept = false;

  constructor(
    private formBuilder: FormBuilder,
    private offerService: OfferService,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService,
    private router: Router,
  ) {
    this.quoteId = +this.activeRoute?.snapshot?.params?.id;
  }

  ngOnInit() {
    this.acceptQuoteForm = this.formBuilder.group({
      accepted: ['', [Validators.required]],
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }

    this.fetchData();
  }

  fetchData() {
    this.pageLoading = true;

    this.offerService.getOffer(this.quoteId).subscribe(
      (res: any) => {
        this.onAccept = !!(
          res?.data?.status === 'accepted' ||
          res?.data?.status === 'declined' ||
          res?.data?.status === 'prepare'
        );

        this.pageLoading = false;
      },
      (err) => {
        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad',
        );
        this.pageLoading = false;
        if (err.status === 403) {
          this.router.navigate(['/unauthorized']);
        }
      },
    );
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  onSubmit(value) {
    this.loading = true;

    this.offerService.acceptQuote(this.quoteId, value).subscribe(
      (res) => {
        this.loading = false;

        this.notificationService.success(
          200,
          'Successfully submit your request',
        );
        this.onAccept = true;
        this.loading = false;
      },
      (err) => {
        this.loading = false;

        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad',
        );
      },
    );
  }
}
