import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CourseRegisterService, LocalLanguageService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';

@Component({
  selector: 'app-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.sass'],
})
export class UpdateInfoComponent implements OnInit {
  updateInfoForm: FormGroup;
  loading = false;
  submitted = false;
  registerId = 0;
  lastComment = null;
  lastStatus = null;
  subscription: any;
  buttonDisabled = false;
  langs = languages;
  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private courseRegistrationService: CourseRegisterService,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {
    this.registerId = +this.activeRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.updateInfoForm = this.formBuilder.group({
      message: ['', Validators.required],
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.updateInfoForm.controls;
  }

  onSubmit(value) {
    this.submitted = true;
    if (this.updateInfoForm.invalid) {
      return;
    }
    this.loading = true;

    this.courseRegistrationService.updateInfo(this.registerId, value).subscribe(
      (data) => {
        if (data) {
          this.loading = false;
          this.notificationService.success(
            '201',
            'Successfully, Sent your Request...!'
          );
        }
        this.buttonDisabled = true;
      },
      (error) => {
        this.loading = false;
        this.notificationService.error(error.status, error.error.message);
      }
    );
  }
}
