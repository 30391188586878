<div class="container">
  <h3>{{ "LessonCancelAbsent.title" | translate }}</h3>
  <form [formGroup]="chatForm" (ngSubmit)="onSubmit(chatForm.value)">
    <div class="example-full-width">
      <mat-form-field appearance="fill">
        <mat-label>{{ "LessonCancelAbsent.titleLabel" | translate }}</mat-label>
        <mat-select matNativeControl formControlName="cancellation_reason" required>
          <mat-option value="1">{{ "LessonCancelAbsent.option1" | translate }}</mat-option>
          <mat-option value="2">{{ "LessonCancelAbsent.option2" | translate }}</mat-option>
          <mat-option value="4">{{ "LessonCancelAbsent.other" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="example-full-width">
      <label for="content"
        >{{ "LessonCancelAbsent.messageLabel" | translate
        }}<span class="star"> *</span></label
      >
      <textarea
        #input
        type="text"
        formControlName="cancellation_reason_detail"
        class="form-control"
        required
        maxlength="256"
        minlength="3"
      >
      </textarea>
      <small
        >{{ "validations.messageLength" | translate
        }}{{ input.value?.length || 0 }} / 256</small
      >
      <br />
      <div
        class="alertError"
        *ngIf="
          !chatForm.controls['cancellation_reason_detail'].valid &&
          chatForm.controls['cancellation_reason_detail'].touched
        "
      >
        <div
          [hidden]="
            !chatForm.controls['cancellation_reason_detail'].errors.required
          "
        >
          {{ "validations.messageReq" | translate }}
        </div>
      </div>
      <br />
    </div>

    <button
      [disabled]="loading || chatForm.invalid"
      class="btn button"
      type="submit"
    >
      {{ "LessonCancelAbsent.submitBtn" | translate }}
    </button>
  </form>
  <button mat-button (click)="onNoClick()" color="warn">
    {{ "LessonCancelAbsent.cancelBtn" | translate }}
  </button>
  <br />
</div>
