<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />
<div style="z-index: 1; position: absolute; left: 40%; top: 40%">
  <mat-spinner *ngIf="lessonLoading"></mat-spinner>
</div>
<div style="padding: 80px">
  <mat-card class="mainCard" >
    <div class="row">
      <h1 class="pageTitle">{{ "Dashboard.dashboard" | translate }}</h1>
    </div>
    <div class="row">
      <button
        mat-raised-button
        type="button"
        class="button"
        (click)="naviagate('/trainer/profile')"
      >
        {{ "Dashboard.myProfile" | translate }}
      </button>
      <button
        mat-raised-button
        type="button"
        class="button"
        (click)="naviagate('/trainer/information')"
      >
        {{ "Dashboard.myProfInfo" | translate }}
      </button>
      <button
        mat-raised-button
        type="button"
        class="button"
        (click)="naviagate('/trainer/contract')"
      >
        {{ "Dashboard.myAgrement" | translate }}
      </button>
      <button
        mat-raised-button
        type="button"
        class="button"
        (click)="naviagate('/trainer/resume')"
      >
        {{ "Dashboard.myCv" | translate }}
      </button>
      <button
        mat-raised-button
        type="button"
        class="button"
        (click)="naviagate('/trainer/time/schedule')"
      >
        {{ "Dashboard.mySchedule" | translate }}
      </button>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <mat-card
          class="mb-10"
          (click)="openGroup()"
          style="cursor: pointer; border-radius: 25px"
        >
          <h1 class="fontColor">{{ totalGroups }}</h1>
          <p class="title">{{ "Dashboard.totalGroups" | translate }}</p>
        </mat-card>
      </div>

      <div class="col-lg-3">
        <mat-card class="mb-10" style="border-radius: 25px">
          <h1 class="fontColor">{{ totalLessons }}</h1>
          <p class="title">{{ "Dashboard.total_L" | translate }}</p>
        </mat-card>
      </div>

      <div class="col-lg-3">
        <mat-card class="mb-10" style="border-radius: 25px">
          <h1 class="fontColor">{{ CompletedLessons }}</h1>
          <p class="title">{{ "Dashboard.completed_L" | translate }}</p>
        </mat-card>
      </div>
      <div class="col-lg-3">
        <mat-card class="mb-10" style="border-radius: 25px">
          <h1 class="unCompletedFont">{{ unCompletedLessons }}</h1>
          <p class="title">{{ "Dashboard.uncompleted_L" | translate }}</p>
        </mat-card>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="row" style="flex-direction: column; padding: 35px">
      <h2>{{ "Dashboard.lesson" | translate }}</h2>
      <div>
        <form
          class="filterItems"
          [formGroup]="searchLessonFilters"
          (ngSubmit)="searchLessons(searchLessonFilters.value)"
        >
          <mat-form-field>
            <mat-label>{{ "GroupInfoComponent.start" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              formControlName="from"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "GroupInfoComponent.end" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="till"
              [min]="searchLessonFilters.controls.from.value"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
          </mat-form-field>

          <button
            mat-raised-button
            type="submit"
            class="button"
            [disabled]="searchLessonFilters.invalid"
          >
            {{ "GroupInfoComponent.searchBtn" | translate }}
          </button>
          <button
            mat-raised-button
            type="button"
            color="accent"
            (click)="resetRequest()"
          >
            {{ "Dashboard.reset_Filter" | translate }}
          </button>
        </form>
      </div>
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let key of lessons"
          style="background: aliceblue"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              ({{
                key.start
                  | amLocale: getlocale()
                  | amLocal
                  | amDateFormat: "L LT"
              }}
              -
              {{
                key.end
                  | amLocale: getlocale()
                  | amLocal
                  | amDateFormat: "L LT"
              }})
            </mat-panel-title>
            <mat-panel-description>
              <button
                mat-raised-button
                class="button"
                type="button"
                style="cursor: pointer"
                (click)="openLesson(key)"
              >
                {{ "Dashboard.open" | translate }}
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            style="
              display: flex;
              flex-direction: row;
              place-content: space-evenly;
            "
          >
            <p>
              {{ "Dashboard.status" | translate }}:{{
                showStatus(key.cancelled)
              }}
            </p>
            <div style="display: flex; flex-direction: row">
              <p>{{ "Dashboard.remarks" | translate }}:</p>
              <p [innerHTML]="key.remarks"></p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card>
</div>
