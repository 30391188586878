import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CourseRegisterService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MakingPlanningService } from '@services/index';

export interface DialogCancelContract {
  groupId: string;
  contractId: string;
  language: string;
}

@Component({
  selector: 'app-cancel-planning',
  templateUrl: './cancel-planning.component.html',
  styleUrls: ['./cancel-planning.component.sass'],
})
export class CancelPlanningComponent implements OnInit {
  timeslotForm: FormGroup;
  submitted = false;
  loading = false;
  buttonDisable = false;
  langs = languages;

  constructor(
    public dialogRef: MatDialogRef<CancelPlanningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogCancelContract,
    private formBuilder: FormBuilder,
    private planningService: MakingPlanningService,
    private notificationService: NotificationsService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.timeslotForm = this.formBuilder.group({
      message: [null, [Validators.required]],
    });
    // this.translate.setDefaultLang(this.data.language);
    this.useLanguage(this.data.language);
  }

  public useLanguage(lang: string): void {
    this.translate.use(lang);
  }

  get f() {
    return this.timeslotForm.controls;
  }

  onNoClick(status: boolean): void {
    this.dialogRef.close(status);
  }

  onSubmit(values: {message: string}) {
    this.submitted = true;

    if (this.timeslotForm.invalid) {
      return;
    }
    this.loading = true;
    this.planningService
      .declinePlanningContract(+this.data.groupId, +this.data.contractId, values)
      .subscribe(
        (data) => {
          this.notificationService.success(
            '200',
            'Successfully, Sent your request!'
          );
          this.loading = false;
          this.buttonDisable = true;
          this.onNoClick(true);
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.message);
          this.onNoClick(false);
        }
      );
  }
}
