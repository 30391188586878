<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card-header">
          <h3>{{ "StudentInfo.title" | translate }}</h3>
        </div>
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmit(form.value)"
          class="form-horizontal"
        >
          <div formArrayName="student" class="card-body">
            <div
              class="container student-container"
              class="col-12"
              *ngFor="
                let student of form.get('student')['controls'];
                let i = index
              "
              formGroupName="{{ i }}"
            >
              <b
                ><label
                  >{{ "StudentInfo.studentLabel" | translate }}:
                  {{ i + 1 + (page - 1) * 10 }}</label
                ></b
              >
              <div class="form-group col-12">
                <label
                  >{{ "StudentInfo.firstName" | translate
                  }}<span class="star"> *</span></label
                >
                <input formControlName="first_name" class="form-control" />
                <span
                  class="text-danger"
                  *ngIf="
                    (getStudentsFormGroup(i).controls['first_name'].touched &&
                      getStudentsFormGroup(i).controls['first_name'].hasError(
                        'required'
                      )) ||
                    (submitted &&
                      getStudentsFormGroup(i).controls['first_name'].hasError(
                        'required'
                      ))
                  "
                >
                  {{ "validations.firstNameReq" | translate }}
                </span>
              </div>

              <div class="form-group col-12">
                <label
                  >{{ "StudentInfo.lastName" | translate
                  }}<span class="star"> *</span></label
                >
                <input formControlName="last_name" class="form-control" />
                <span
                  class="text-danger"
                  *ngIf="
                    (getStudentsFormGroup(i).controls['last_name'].touched &&
                      getStudentsFormGroup(i).controls['last_name'].hasError(
                        'required'
                      )) ||
                    (submitted &&
                      getStudentsFormGroup(i).controls['last_name'].hasError(
                        'required'
                      ))
                  "
                >
                  {{ "validations.lastNameReq" | translate }}
                </span>
              </div>

              <div class="form-group col-12">
                <label
                  >{{ "StudentInfo.emailLabel" | translate
                  }}<span class="star"> *</span></label
                >
                <input formControlName="email" class="form-control" />
                <span
                  class="text-danger"
                  *ngIf="
                    (getStudentsFormGroup(i).controls['email'].touched &&
                      getStudentsFormGroup(i).controls['email'].hasError(
                        'required'
                      )) ||
                    (submitted &&
                      getStudentsFormGroup(i).controls['email'].hasError(
                        'required'
                      ))
                  "
                >
                  {{ "validations.emailReq" | translate }}
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    (getStudentsFormGroup(i).controls['email'].touched &&
                      getStudentsFormGroup(i).controls['email'].hasError(
                        'required'
                      )) ||
                    (submitted &&
                      getStudentsFormGroup(i).controls['email'].hasError(
                        'required'
                      ))
                  "
                >
                  {{ "validations.validEmail" | translate }}
                </span>
              </div>

              <div class="form-group col-12">
                <label>{{ "StudentInfo.phoneLabel" | translate }} </label>

                <div class="mb-2">
                  <ngx-intl-tel-input
                    #phoneLength
                    [inputId]="'adsasd'"
                    [cssClass]="'form-control'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="true"
                    [selectedCountryISO]="
                      checkCountryInit(i, 'countryInitphone')
                    "
                    [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="phone"
                    formControlName="phone"
                  >
                  </ngx-intl-tel-input>
                  <br />
                  <span
                    class="text-danger"
                    *ngIf="
                      getStudentsFormGroup(i).controls['phone'].touched &&
                      getStudentsFormGroup(i).controls['phone'].hasError(
                        'validatePhoneNumber'
                      )
                    "
                  >
                    {{ "validations.phonePattern" | translate }}
                  </span>
                </div>
              </div>

              <div class="form-group col-12">
                <label
                  >{{ "StudentInfo.socialSecurityNumberLabel" | translate
                  }}<span class="star"> *</span></label
                >
                <input
                  formControlName="social_security_number"
                  class="form-control"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (getStudentsFormGroup(i).controls['social_security_number']
                      .touched &&
                      getStudentsFormGroup(i).controls[
                        'social_security_number'
                      ].hasError('required')) ||
                    (submitted &&
                      getStudentsFormGroup(i).controls[
                        'social_security_number'
                      ].hasError('required'))
                  "
                >
                  {{ "validations.securityReq" | translate }}
                </span>
              </div>

              <div class="form-group col-12">
                <label>{{ "StudentInfo.mobileLabel" | translate }} </label>

                <div class="mb-2">
                  <ngx-intl-tel-input
                    #mobileLength
                    [inputId]="'adsasd'"
                    [cssClass]="'form-control'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="true"
                    [selectedCountryISO]="
                      checkCountryInit(i, 'countryInitmobile')
                    "
                    [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="mobile_phone"
                    formControlName="mobile_phone"
                  >
                  </ngx-intl-tel-input>
                  <br />
                  <span
                    class="text-danger"
                    *ngIf="
                      getStudentsFormGroup(i).controls['mobile_phone']
                        .touched &&
                      getStudentsFormGroup(i).controls['mobile_phone'].hasError(
                        'validatePhoneNumber'
                      )
                    "
                  >
                    {{ "validations.mobilePhonePattern" | translate }}
                  </span>
                </div>
              </div>

              <div class="form-group col-12">
                <label>{{ "StudentInfo.jobtitleLabel" | translate }} </label>
                <input formControlName="job_title" class="form-control" />
              </div>

              <div class="form-group col-12">
                <label [for]="'timeslot_' + i">{{
                  "StudentInfo.timeslotLabel" | translate
                }}</label>
                <mat-form-field appearance="fill" class="selector col-12">
                  <mat-label>Select Time Slots</mat-label>
                  <mat-select
                    [id]="'timeslot_' + i"
                    formControlName="time_slot_id"
                    (selectionChange)="onTimeslotChange()"
                    [compareWith]="compareById"
                  >
                    <mat-option>--</mat-option>
                    <mat-option
                      [value]="student.value.time_slot"
                      *ngIf="student.value.time_slot"
                      >{{ student.value.time_slot.value }}</mat-option
                    >
                    <mat-option
                      *ngFor="let key of availableTimeslots"
                      [value]="key"
                      >{{ key.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- TODO Why was this here, it seems obsolete?? -->

              <div class="form-group col-12">
                <label for="primary_language"
                  >{{ "TrainerInformation.languageLabel" | translate }}
                  <span class="star"> *</span>
                </label>

                <mat-form-field appearance="fill" class="selector col-12">
                  <mat-label>Select Language</mat-label>
                  <mat-select
                    required
                    formControlName="primary_language_id"
                    [errorStateMatcher]="matcher"
                    [value]="getLanguageSelection(i)"
                    (selectionChange)="onLanguageSelection(i, $event.value)"
                  >
                    <mat-option>--</mat-option>
                    <mat-option
                      *ngFor="let key of languages"
                      [value]="key.id"
                      >{{ key.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <span
                  class="text-danger"
                  *ngIf="
                    getStudentsFormGroup(i).controls['primary_language_id']
                      .touched &&
                    getStudentsFormGroup(i).controls[
                      'primary_language_id'
                    ].hasError('required')
                  "
                >
                  {{ "validations.languageReq" | translate }}</span
                >
              </div>
            </div>
          </div>
          <button
            class="btn btn-success m-1"
            type="submit"
            [disabled]="buttonDisable || loading"
          >
            {{ "StudentInfo.saveBtn" | translate }}
          </button>
          <img
            *ngIf="loading"
            class="pl-3"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </form>
        <mat-paginator
          [length]="studentsLenth"
          [pageSize]="pageSize"
          (page)="pageEvent($event)"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</span>
