<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>
  <br />
  <br />
  <br />
  <span *ngIf="intermediateForm">
    <div class="container">
      <mat-card>
        <h3>{{ "StudentIntermediateEvaluation.title" | translate }}</h3>
        <br />
        <br />
        <br />
        <p>
          {{ "StudentIntermediateEvaluation.note" | translate }}
        </p>
        <br />
        <form
          [formGroup]="intermediateEvaluationForm"
          (ngSubmit)="onSubmit(intermediateEvaluationForm.value)"
        >
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentInfo.firstName" | translate }}:</b>&nbsp;&nbsp;{{
                stdFirstName
              }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentInfo.lastName" | translate }}:</b>&nbsp;&nbsp;{{
                stdLastName
              }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{
                "StudentIntermediateEvaluation.orgainzationLabel" | translate
              }}</b
              >&nbsp;&nbsp; {{ organizationName }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <span *ngIf="trainerName">
              <p>
                <b>{{
                  "StudentIntermediateEvaluation.trainerNameLabel" | translate
                }}</b>
                <span *ngFor="let key of trainerName; index as i"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ i + 1 }}</b
                  >:&nbsp;{{ key.name }}</span
                >
              </p>
            </span>
          </div>

          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{
                "StudentIntermediateEvaluation.languageLabel" | translate
              }}</b
              >&nbsp;&nbsp;{{ targertedLanguage }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentIntermediateEvaluation.dateLabel" | translate }}</b
              >&nbsp;&nbsp;
              {{ currentDate | date: "yyyy-MM-dd" }}
            </p>
          </div>

          <br />
          <br />
          <div>
            <h5>
              {{ "StudentIntermediateEvaluation.training.title" | translate }}
            </h5>
            <br />
            <p>
              {{
                "StudentIntermediateEvaluation.training.likeLabel" | translate
              }}
            </p>
            <br />
            <p>
              {{ "StudentIntermediateEvaluation.languageLabel" | translate }} :
            </p>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.contactTrainer"
                  | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="content_of_training"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>

            <div class="example-full-width">
              <label>
                o
                {{
                  "StudentIntermediateEvaluation.training.topicInterests"
                    | translate
                }}</label
              >
              <br />
              <mat-form-field class="example-full-width">
                <mat-label>{{
                  "StudentIntermediateEvaluation.training.interestLabel"
                    | translate
                }}</mat-label>
                <textarea
                  matInput
                  formControlName="subject_interests"
                  class="textareaStyle"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="example-full-width">
              <label>
                o
                {{
                  "StudentIntermediateEvaluation.training.wantToLearn"
                    | translate
                }}
              </label>
              <br />
              <mat-form-field class="example-full-width">
                <mat-label>{{
                  "StudentIntermediateEvaluation.training.outcomesLabel"
                    | translate
                }}</mat-label>
                <textarea
                  matInput
                  formControlName="future_subjects"
                  class="textareaStyle"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.trainingStructure"
                  | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="structure_of_training"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.lessonActvities"
                  | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="activities_during_lesson"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.subjectMatters"
                  | translate
              }} </label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="variation_in_subject_matters"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.lessonPace" | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="pace_of_lessons"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.receiveDocuments"
                  | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="documents_received"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentIntermediateEvaluation.training.homeTasks" | translate
              }} </label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="specified_home_tasks"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label>{{
              "StudentIntermediateEvaluation.training.speakInTraining"
                | translate
            }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              formControlName="often_spoke_during_training"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of trainerSpoke"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <mat-form-field class="example-full-width">
              <mat-label>{{
                "StudentIntermediateEvaluation.training.explanationLabel"
                  | translate
              }}</mat-label>
              <textarea
                matInput
                formControlName="training_comments"
                required
                class="textareaStyle"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
              ></textarea>
            </mat-form-field>
          </div>
          <br />
          <!-- Half-time evaluation of Agataal language training -->
          <div>
            <h4>
              {{ "StudentIntermediateEvaluation.trainer.title" | translate }}
            </h4>
            <br />
            <div formArrayName="trainer_evaluations" class="card-body">
              <div
                class="col-12"
                *ngFor="
                  let trainer of intermediateEvaluationForm.get(
                    'trainer_evaluations'
                  )['controls'];
                  let i = index
                "
                formGroupName="{{ i }}"
              >
                <mat-form-field [hidden]="true">
                  <input matInput type="text" formControlName="trainer_id" />
                </mat-form-field>
                <h6>
                  {{
                    "StudentIntermediateEvaluation.trainer.subHeadingTitle"
                      | translate
                  }}
                  &nbsp;&nbsp;
                </h6>
                <br />
                <p>
                  {{ "StudentIntermediateEvaluation.trainer.note" | translate }}
                  <br />
                  <b>{{
                    "StudentIntermediateEvaluation.trainer.name" | translate
                  }}</b
                  >&nbsp;&nbsp;{{ trainerName[i].name }}
                </p>
                <br />
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentIntermediateEvaluation.trainer.teachingWay"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="way_of_teaching"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentIntermediateEvaluation.trainer.adjustmentLevel"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="adjustment_level"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentIntermediateEvaluation.trainer.subjectMatter"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="subject_knowledge"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentIntermediateEvaluation.trainer.contactTrainer"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="contact_language_trainer"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentIntermediateEvaluation.trainer.correctionMistakes"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="correction_of_mistakes"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentIntermediateEvaluation.trainer.teachingMaterial"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="use_of_teaching_materials"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="example-full-width">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{
                      "StudentIntermediateEvaluation.trainer.comments"
                        | translate
                    }}</mat-label>
                    <textarea
                      matInput
                      formControlName="remarks"
                      required
                      class="textareaStyle"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="4"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- The Organisation -->
          <div>
            <h4>
              {{
                "StudentIntermediateEvaluation.organization.title" | translate
              }}
            </h4>
            <br />

            <div class="example-full-width">
              <label
                >•
                {{
                  "StudentIntermediateEvaluation.organization.note" | translate
                }}</label
              ><span class="star"> *</span>
              <br />
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group radioDisplay"
                formControlName="organization_of_training"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let key of evaluation"
                  [value]="key.code"
                >
                  {{ key.name }} &nbsp;&nbsp;
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="example-full-width">
              <mat-form-field class="example-full-width">
                <mat-label>
                  {{
                    "StudentIntermediateEvaluation.organization.comments"
                      | translate
                  }}
                </mat-label>
                <textarea
                  matInput
                  formControlName="organization_comments"
                  required
                  class="textareaStyle"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="example-full-width">
              <label
                >•
                {{
                  "StudentIntermediateEvaluation.organization.trainingExpectations"
                    | translate
                }}</label
              ><span class="star"> *</span>
              <br />
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group radioDisplay"
                formControlName="meet_expectations"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let key of expectationsStatus"
                  [value]="key.code"
                >
                  {{ key.name }} &nbsp;&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <div class="example-full-width">
                <mat-form-field class="example-full-width">
                  <mat-label>
                    {{
                      "StudentIntermediateEvaluation.organization.explanantion"
                        | translate
                    }}</mat-label
                  >
                  <textarea
                    matInput
                    formControlName="meet_expectations_explanation"
                    required
                    class="textareaStyle"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="4"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>

            <div>
              <mat-form-field class="example-full-width">
                <mat-label>{{
                  "StudentIntermediateEvaluation.organization.suggestions"
                    | translate
                }}</mat-label>
                <textarea
                  matInput
                  formControlName="suggestions"
                  required
                  class="textareaStyle"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <button
            [disabled]="intermediateEvaluationForm.invalid"
            mat-raised-button
            type="submit"
            class="button"
          >
            {{ "StudentIntermediateEvaluation.saveBtn" | translate }}
          </button>
        </form>
      </mat-card>
    </div>
  </span>
  <span *ngIf="!intermediateForm">
    <div class="container">
      <mat-card>
        <h4>You already sumbitted you response.</h4>
      </mat-card>
    </div>
  </span>
</span>
