<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />
  <br />
  <br />
  <div class="container">
    <mat-card>
      <div *ngIf="!evaluationFormDisplay">
        <h3>{{ "StudentFinalEvaluation.title" | translate }}</h3>
        <br />
        <br />
        <br />
        <p>
          {{ "StudentFinalEvaluation.note" | translate }}
        </p>
        <br />

        <form
          [formGroup]="finalEvaluatonForm"
          (ngSubmit)="onSubmit(finalEvaluatonForm.value)"
        >
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentInfo.firstName" | translate }}:</b>&nbsp;&nbsp;{{
                stdFirstName
              }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentInfo.lastName" | translate }}:</b>&nbsp;&nbsp;{{
                stdLastName
              }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{
                "StudentIntermediateEvaluation.orgainzationLabel" | translate
              }}</b
              >&nbsp;&nbsp; {{ organizationName }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <span *ngIf="trainerName">
              <p>
                <b>{{
                  "StudentIntermediateEvaluation.trainerNameLabel" | translate
                }}</b>
                <span *ngFor="let key of trainerName; index as i"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ i + 1 }}</b
                  >:&nbsp;{{ key.name }}</span
                >
              </p>
            </span>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{
                "StudentIntermediateEvaluation.languageLabel" | translate
              }}</b
              >&nbsp;&nbsp;{{ targertedLanguage }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentIntermediateEvaluation.dateLabel" | translate }}</b
              >&nbsp;&nbsp;
              {{ currentDate | date: "yyyy-MM-dd" }}
            </p>
          </div>

          <br />
          <br />

          <h5>{{ "StudentFinalEvaluation.training.title" | translate }}</h5>
          <br />
          <p>{{ "StudentFinalEvaluation.training.likeLabel" | translate }}</p>
          <br />

          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.contactTrainer" | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="content_of_training"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.trainingStructure" | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="structure_of_training"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.lessonActvities" | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="activities_during_lesson"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.subjectMatters" | translate
              }} </label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="variation_in_subject_matters"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.lessonPace" | translate
              }} </label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="pace_of_lessons"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.receiveDocuments" | translate
              }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="documents_received"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label
              >•
              {{
                "StudentFinalEvaluation.training.homeTasks" | translate
              }} </label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="specified_home_tasks"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <label>{{
              "StudentFinalEvaluation.training.speakInTraining" | translate
            }}</label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              formControlName="often_spoke_during_training"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of trainerSpoke"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <mat-form-field class="example-full-width">
              <mat-label>{{
                "StudentFinalEvaluation.training.explanationLabel" | translate
              }}</mat-label>
              <textarea
                matInput
                formControlName="training_comments"
                required
                class="textareaStyle"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
              ></textarea>
            </mat-form-field>
            <!-- <div
            *ngIf="
              !finalEvaluatonForm.controls['training_comments'].valid &&
              finalEvaluatonForm.controls['training_comments'].touched
            "
            class="invalid-feedback"
          >
            Training comments are required
       
          </div> -->
          </div>
          <br />

          <div class="example-full-width">
            <hr />
            <div formArrayName="trainer_evaluations" class="card-body">
              <div
                class="col-12"
                *ngFor="
                  let trainer of finalEvaluatonForm.get('trainer_evaluations')[
                    'controls'
                  ];
                  let i = index
                "
                formGroupName="{{ i }}"
              >
                <mat-form-field [hidden]="true">
                  <input matInput type="text" formControlName="trainer_id" />
                </mat-form-field>
                <h6>
                  {{ "StudentFinalEvaluation.trainer.title" | translate }}
                  &nbsp;&nbsp;
                </h6>
                <br />
                <p>
                  {{ "StudentFinalEvaluation.trainer.note" | translate }}

                  <br />
                </p>
                <p class="studentDetails">
                  <b
                    >{{ i + 1 }}:&nbsp;&nbsp;{{
                      "StudentFinalEvaluation.trainer.name" | translate
                    }}</b
                  >&nbsp;&nbsp;{{ trainerName[i].name }}
                </p>
                <br />
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentFinalEvaluation.trainer.teachingWay" | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="way_of_teaching"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentFinalEvaluation.trainer.adjustmentLevel"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="adjustment_level"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentFinalEvaluation.trainer.subjectMatter" | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="subject_knowledge"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentFinalEvaluation.trainer.contactTrainer"
                        | translate
                    }} </label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="contact_language_trainer"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentFinalEvaluation.trainer.correctionMistakes"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="correction_of_mistakes"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="example-full-width">
                  <label
                    >•
                    {{
                      "StudentFinalEvaluation.trainer.teachingMaterial"
                        | translate
                    }}</label
                  ><span class="star"> *</span>
                  <br />
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group radioDisplay"
                    formControlName="use_of_teaching_materials"
                  >
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let key of evaluation"
                      [value]="key.code"
                    >
                      {{ key.name }} &nbsp;&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="example-full-width">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{
                      "StudentFinalEvaluation.trainer.comments" | translate
                    }}</mat-label>
                    <textarea
                      matInput
                      formControlName="remarks"
                      required
                      class="textareaStyle"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="4"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4>
              {{ "StudentFinalEvaluation.organization.title" | translate }}
            </h4>
            <br />

            <div class="example-full-width">
              <label
                >•
                {{
                  "StudentFinalEvaluation.organization.note" | translate
                }}</label
              ><span class="star"> *</span>
              <br />
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group radioDisplay"
                formControlName="organization_of_training"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let key of evaluation"
                  [value]="key.code"
                >
                  {{ key.name }} &nbsp;&nbsp;
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="example-full-width">
              <mat-form-field class="example-full-width">
                <mat-label
                  >{{
                    "StudentFinalEvaluation.organization.comments" | translate
                  }}
                </mat-label>
                <textarea
                  matInput
                  formControlName="organization_comments"
                  required
                  class="textareaStyle"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="example-full-width">
              <label
                >•
                {{
                  "StudentFinalEvaluation.organization.trainingExpectations"
                    | translate
                }}</label
              ><span class="star"> *</span>
              <br />
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group radioDisplay"
                formControlName="meet_expectations"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let key of expectationsStatus"
                  [value]="key.code"
                >
                  {{ key.name }} &nbsp;&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <div>
                <mat-form-field class="example-full-width">
                  <mat-label>{{
                    "StudentFinalEvaluation.organization.explanantion"
                      | translate
                  }}</mat-label>
                  <textarea
                    matInput
                    formControlName="meet_expectations_explanation"
                    required
                    class="textareaStyle"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="4"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="example-full-width">
              <mat-form-field class="example-full-width">
                <mat-label>{{
                  "StudentFinalEvaluation.organization.suggestions" | translate
                }}</mat-label>
                <textarea
                  matInput
                  formControlName="suggestions"
                  required
                  class="textareaStyle"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <!-- How you know about Agataal -->
          <div class="example-full-width">
            <h4>{{ "StudentFinalEvaluation.about.title" | translate }}</h4>
            <br />
            <div>
              <label
                >• {{ "StudentFinalEvaluation.about.note" | translate }} </label
              ><span class="star"> *</span>
              <br />

              <span
                class="example-radio-button checkStyle"
                *ngFor="let key of agataalMet; let i = index"
              >
                <mat-checkbox
                  [value]="key.code"
                  [checked]="selected === i"
                  (change)="selected = i"
                  formControlName="met_agataal_through"
                >
                  {{ key.name }} &nbsp;&nbsp;
                </mat-checkbox>
              </span>
            </div>
          </div>
          <button
            [disabled]="finalEvaluatonForm.invalid"
            mat-raised-button
            type="submit"
            class="button"
          >
            {{ "StudentFinalEvaluation.saveBtn" | translate }}
          </button>
        </form>
      </div>
      <div *ngIf="evaluationFormDisplay">
        <form
          [formGroup]="followUpEvaluationForm"
          (ngSubmit)="evaluationPart2(followUpEvaluationForm.value)"
        >
          <h3>
            {{ "StudentFinalEvaluation.title" | translate }}
          </h3>
          <br />
          <br />
          <p>
            {{ "StudentFinalEvaluation.followUPEvaluation.note" | translate }}
          </p>
          <br />
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentInfo.firstName" | translate }}:</b>&nbsp;&nbsp;{{
                stdFirstName
              }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentInfo.lastName" | translate }}:</b>&nbsp;&nbsp;{{
                stdLastName
              }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p><b>Organization:</b>&nbsp;&nbsp; {{ organizationName }}</p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <span *ngIf="trainerName">
              <p>
                <b>{{
                  "StudentFinalEvaluation.trainerNameLabel" | translate
                }}</b>
                <span *ngFor="let key of trainerName; index as i"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ i + 1 }}:&nbsp;{{
                    key.name
                  }}</span
                >
              </p>
            </span>
          </div>
          <br />
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentFinalEvaluation.languageLabel" | translate }}</b
              >&nbsp;&nbsp;{{ targertedLanguage }}
            </p>
          </div>
          <div class="example-full-width" class="studentDetails">
            <p>
              <b>{{ "StudentFinalEvaluation.dateLabel" | translate }}</b
              >&nbsp;&nbsp;
              {{ currentDate | date: "yyyy-MM-dd" }}
            </p>
          </div>

          <br />
          <br />
          <div class="example-full-width">
            <label
              >{{
                "StudentFinalEvaluation.followUPEvaluation.satisfied"
                  | translate
              }} </label
            ><span class="star"> *</span>
            <br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group radioDisplay"
              formControlName="satisfied_with_result"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let key of evaluation"
                [value]="key.code"
              >
                {{ key.name }} &nbsp;&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="example-full-width">
            <mat-form-field class="example-full-width">
              <mat-label
                >{{
                  "StudentFinalEvaluation.followUPEvaluation.explanation"
                    | translate
                }}
              </mat-label>
              <textarea
                matInput
                formControlName="satisfied_with_result_explanation"
                required
                class="textareaStyle"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="example-full-width">
            <mat-form-field class="example-full-width">
              <mat-label
                >{{
                  "StudentFinalEvaluation.followUPEvaluation.courseStrengths"
                    | translate
                }}
              </mat-label>
              <textarea
                matInput
                formControlName="strong_points"
                required
                class="textareaStyle"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="example-full-width">
            <mat-form-field class="example-full-width">
              <mat-label
                >{{
                  "StudentFinalEvaluation.followUPEvaluation.courseWeekness"
                    | translate
                }}
              </mat-label>
              <textarea
                matInput
                formControlName="weak_points"
                required
                class="textareaStyle"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="example-full-width">
            <mat-form-field class="example-full-width">
              <mat-label
                >{{
                  "StudentFinalEvaluation.organization.suggestions" | translate
                }}
              </mat-label>
              <textarea
                matInput
                formControlName="additional_suggestions"
                required
                class="textareaStyle"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="example-full-width">
            <h4>{{ "StudentFinalEvaluation.about.title" | translate }}</h4>
            <div>
              <label
                >• {{ "StudentFinalEvaluation.about.note" | translate }} </label
              ><span class="star"> *</span>

              <br />

              <span
                class="example-radio-button checkStyle"
                *ngFor="let key of agataalMet; let i = index"
              >
                <mat-checkbox
                  [value]="key.code"
                  [checked]="selected === i"
                  (change)="selected = i"
                  formControlName="met_agataal_through"
                >
                  {{ key.name }} &nbsp;&nbsp;
                </mat-checkbox>
              </span>
            </div>
          </div>
          <button
            [disabled]="followUpEvaluationForm.invalid"
            mat-raised-button
            type="submit"
            class="button"
          >
            {{ "StudentFinalEvaluation.saveBtn" | translate }}
          </button>
        </form>
      </div>
    </mat-card>
  </div>
</span>
