import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupInfoService, TrainerService } from '@services/index';
import { Notification, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

export interface DialogAdditionalInfo {
  additionalInfoId: string;
}

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.sass'],
})
export class DeleteConfirmationComponent implements OnInit {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    private groupInfo: GroupInfoService,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogAdditionalInfo
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.close();
  }
  onSubmit() {
    this.loading = true;
    this.groupInfo.deleteAdditionalInfo(+this.data.additionalInfoId).subscribe(
      (res) => {
        this.loading = false;
        this.notificationService.success(200, 'File Deleted, Successfully');
        this.dialogRef.close();
      },
      (error) => {
        this.loading = false;
        this.notificationService.error(error.status, error.error.message);
        this.dialogRef.close();
      }
    );
  }
}
