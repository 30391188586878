import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../shared/language.translation';
@Component({
  selector: 'app-no-page-found',
  templateUrl: './no-page-found.component.html',
  styleUrls: ['./no-page-found.component.sass'],
})
export class NoPageFoundComponent implements OnInit {
  langs = languages;
  spinerLoading = true;
  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }

    this.spinerLoading = false;
  }
  public useLanguage(lang: string): void {
    this.translate.use(lang);
  }
}
