import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TrainerService, LocalLanguageService } from '@services/index';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

const levels = {
  A01: '1',
  A02: '2',
  A1: '3',
  A2: '4',
  B1: '5',
  B2: '6',
};

export interface DialogData {
  question: string;
}

@Component({
  selector: 'app-lessons-detail',
  templateUrl: './lessons-detail.component.html',
  styleUrls: ['./lessons-detail.component.sass'],
})
export class LessonsDetailComponent implements OnInit {
  timeSlotDetail: FormGroup;
  loading = false;
  dataRecieved = false;
  gotDataId = null;
  test = null;
  questions = [];
  customQuestions = [];
  trainerLanguage = 'language';
  communicationWithOther = false;
  situationOther = false;
  wantOther = false;
  learnOther = false;
  customQuestionsSelected = [];
  otherLanguage = false;
  languages = null;
  lang = [];
  answers = [];
  speak_language_other = null;
  who_communicate_language_at_work = [];
  when_speak_language = [];
  how_often_speak_language = null;
  what_these_conversation_about = null;
  what_you_do_in_first_place = null;
  where_when_you_learn_language = {};
  subscription = null;
  cOther = false;
  trainerName = '';
  sName = '';
  sCompany: false;
  sFunction = '';
  sTel = '';
  sMobile = '';
  sNativeLanguage = '';
  sMainLanguage = '';
  sEmail = '';
  customerName = '';
  isOtherLanguage = false;
  langs = languages;

  constructor(
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private trainerService: TrainerService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
  ) {
    this.timeSlotDetail = this.formBuilder.group({
      trainer: {},
      student: {},
      answers: [],
      availability: '',
      comments: '',
      job_type: '',
      job_duties: '',
      why_you_learn: '',
      who_communicate_language_at_work: [],
      when_speak_language: [],
      speak_language_other: '',
      how_often_speak_language: '',
      what_these_conversation_about: '',
      what_you_do_in_first_place: '',
      where_when_you_learn_language: {},
      how_difficult_to_hear_language: '',
      understanding: '',
      speaking_and_interaction: '',
      fluency: '',
      general_vocabulary: '',
      business_vocabulary: '',
      grammar_and_structure: '',
      statement: '',
      speak_general: '',
      speak_business: '',
      listen_general: '',
      listen_business: '',
      read_general: '',
      read_business: '',
      write_general: '',
      write_business: '',
    });
  }

  ngOnInit(): void {
    this.languageChangeHandler();
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }
  // language method
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.loading = true;
    this.subscription = this.trainerService.getTimeSlotDetail(
      this.activeRoute.snapshot.params.id,
    );
    this.subscription.pipe(first()).subscribe(
      (res) => {
        this.saveData(res);
        this.dataRecieved = true;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.notificationService.error('404', 'Student Not Found');
      },
    );
    this.subscription = this.trainerService.getQuestions();
    this.subscription.pipe(first()).subscribe(
      (res) => {
        this.questions = res.data;
      },
      (err) => {
        this.notificationService.error('404', 'Questions Not Found');
      },
    );
    this.subscription = this.trainerService.getLanguages();
    this.subscription.pipe(first()).subscribe(
      (res) => {
        this.languages = res.data;
        const temp = res.data.find((element) => element.id === this.test);
        this.sMainLanguage = temp.name;
      },
      (err) => {
        this.notificationService.error('404', 'Languages Not Found');
      },
    );
  }
  saveData(res) {
    res.data.answers.forEach((temp) => {
      if (temp.question_id == null) {
        this.customQuestions.push({
          question: temp.custom_question,
          answer: temp.answer,
        });
        this.customQuestionsSelected.push({
          question: temp.custom_question,
          answer: temp.answer,
        });
      } else {
        delete temp.custom_question;
        this.questions.push(temp);
        this.answers.push(temp);
      }
    });
    this.gotDataId = res.data.id;
    this.timeSlotDetail.controls.trainer.setValue(res.data.trainer);
    this.trainerName = res.data.trainer.name;
    this.timeSlotDetail.controls.student.setValue(res.data.student);
    this.sName = res.data.student.name;
    this.sEmail = res.data.student.email;
    this.sCompany = res.data.student.registration.job_title;
    this.sFunction = res.data.student.job_title;
    this.sTel = res.data.student.phone;
    this.sMobile = res.data.student.mobile_phone;
    this.sNativeLanguage = res.data.student.locale;
    this.test = res.data.student.primary_language_id;
    this.trainerLanguage = res.data.student.registration.type.language.name;
    this.customerName = res.data.student.registration.customer.name;
    this.timeSlotDetail.controls.availability.setValue(
      res.data ? res.data?.student?.availability : '',
    );
    this.timeSlotDetail.controls.comments.setValue(
      res.data ? res.data.comments : '',
    );
    this.timeSlotDetail.controls.job_type.setValue(
      res.data ? res.data.job_type : '',
    );
    this.timeSlotDetail.controls.job_duties.setValue(
      res.data ? res.data.job_duties : '',
    );
    this.timeSlotDetail.controls.why_you_learn.setValue(
      res.data ? res.data.why_you_learn : '',
    );
    this.who_communicate_language_at_work =
      res.data.who_communicate_language_at_work;
    this.when_speak_language = res.data.when_speak_language;
    this.how_often_speak_language = res.data.how_often_speak_language;
    this.timeSlotDetail.controls.what_these_conversation_about.setValue(
      res.data ? res.data.what_these_conversation_about : '',
    );
    this.what_you_do_in_first_place = res.data.what_you_do_in_first_place;
    this.where_when_you_learn_language = res.data.where_when_you_learn_language;
    this.timeSlotDetail.controls.how_difficult_to_hear_language.setValue(
      res.data ? res.data.how_difficult_to_hear_language : '',
    );
    res.data.languages_speak.forEach((x) => {
      this.lang.push(x.id);
    });

    this.timeSlotDetail.controls.understanding.setValue(
      res.data ? res.data.understanding : '',
    );
    this.timeSlotDetail.controls.speaking_and_interaction.setValue(
      res.data ? res.data.speaking_and_interaction : '',
    );
    this.timeSlotDetail.controls.fluency.setValue(
      res.data ? res.data.fluency : '',
    );
    this.timeSlotDetail.controls.general_vocabulary.setValue(
      res.data ? res.data.general_vocabulary : '',
    );
    this.timeSlotDetail.controls.business_vocabulary.setValue(
      res.data ? res.data.business_vocabulary : '',
    );
    this.timeSlotDetail.controls.grammar_and_structure.setValue(
      res.data ? res.data.grammar_and_structure : '',
    );
    this.timeSlotDetail.controls.statement.setValue(
      res.data ? res.data.statement : '',
    );
    this.timeSlotDetail.controls.speak_general.setValue(
      res.data ? levels[res.data.speak_general] : '',
    );
    this.timeSlotDetail.controls.speak_business.setValue(
      res.data ? levels[res.data.speak_business] : '',
    );
    this.timeSlotDetail.controls.listen_general.setValue(
      res.data ? levels[res.data.listen_general] : '',
    );
    this.timeSlotDetail.controls.listen_business.setValue(
      res.data ? levels[res.data.listen_business] : '',
    );
    this.timeSlotDetail.controls.read_general.setValue(
      res.data ? levels[res.data.read_general] : '',
    );
    this.timeSlotDetail.controls.read_business.setValue(
      res.data ? levels[res.data.read_business] : '',
    );
    this.timeSlotDetail.controls.write_general.setValue(
      res.data ? levels[res.data.write_general] : '',
    );
    this.timeSlotDetail.controls.write_business.setValue(
      res.data ? levels[res.data.write_business] : '',
    );
    this.timeSlotDetail.controls.speak_language_other.setValue(
      res.data ? res.data.speak_language_other : '',
    ); // test
    if (res.data.speak_language_other != null) {
      this.isOtherLanguage = true;
    }
  }
  checkOtherLanguageInput() {
    return this.otherLanguage;
  }
  checkOtherLanguage() {
    if (Object.is(this.timeSlotDetail['speak_language_other'], null)) {
      this.otherLanguage = false;
    } else {
      this.otherLanguage = true;
    }
    return this.otherLanguage;
  }
  checkSituation(val) {
    if (
      this.when_speak_language != null &&
      this.when_speak_language.indexOf(val) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkLearn(val) {
    if (
      this.where_when_you_learn_language != null &&
      val in this.where_when_you_learn_language
    ) {
      return true;
    }
    return false;
  }
  checkLearnInput(val) {
    return this.where_when_you_learn_language[val];
  }
  editLearnInput(val, e) {
    this.where_when_you_learn_language[val] = e;
  }
  checkCommunicationWith(val) {
    if (
      this.who_communicate_language_at_work != null &&
      this.who_communicate_language_at_work.indexOf(val) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkHearSpeak(val) {
    if (this.how_often_speak_language == val) {
      return true;
    } else {
      return false;
    }
  }
  checkWant(val) {
    if (this.what_you_do_in_first_place == val) {
      return true;
    } else {
      return false;
    }
  }
  checkanswers(val) {
    let re = false;
    this.answers.map((e) => {
      if (e.question_id == val) {
        re = true;
        return;
      }
    });
    return re;
  }
  checkQAnswer(val) {
    let data = null;
    if (this.checkanswers(val)) {
      this.answers.map((e) => {
        if (e.question_id === val) {
          data = e.answer;
          return;
        }
      });
    }
    return data;
  }
  editLanguage(val) {
    if (this.lang == null) {
      this.lang = [];
    }
    if (this.checkLanguage(val)) {
      this.lang = this.lang.filter((e) => e !== val);
    } else {
      this.lang.push(val);
    }
  }
  editQAnswer(val, data) {
    this.answers.map((e) => {
      if (e.question_id === val) {
        e.answer = data;
        return;
      }
    });
  }
  editanswers(val) {
    if (this.checkanswers(val)) {
      this.answers = this.answers.filter((e) => e.question_id !== val);
    } else {
      this.answers.push({ question_id: val, answer: null });
    }
  }
  editHearSpeak(val) {
    this.how_often_speak_language = val;
  }
  editWant(val) {
    this.wantOther = false;
    this.what_you_do_in_first_place = val;
  }
  editLearn(val, other?) {
    if (other) {
      this.learnOther = !this.learnOther;
    } else {
      this.learnOther = false;
    }
    this.where_when_you_learn_language = {};
    this.where_when_you_learn_language[val] = '';
  }
  editCommunicationWith(val) {
    if (this.who_communicate_language_at_work == null) {
      this.who_communicate_language_at_work = [];
    }
    if (this.who_communicate_language_at_work.indexOf(val) > -1) {
      this.who_communicate_language_at_work =
        this.who_communicate_language_at_work.filter((e) => e !== val);
    } else {
      this.who_communicate_language_at_work.push(val);
    }
  }
  editSituation(val) {
    if (this.when_speak_language == null) {
      this.when_speak_language = [];
    }
    if (this.when_speak_language.indexOf(val) > -1) {
      this.when_speak_language = this.when_speak_language.filter(
        (e) => e !== val,
      );
    } else {
      this.when_speak_language.push(val);
    }
  }
  checkLanguage(val) {
    if (this.lang != null && this.lang.indexOf(val) > -1) {
      return true;
    } else {
      return false;
    }
  }
  checkCustomAnswers(val) {
    let re = false;
    this.customQuestionsSelected.map((e) => {
      if (e.question == val) {
        re = true;
        return;
      }
    });
    return re;
  }
  editCustomAnswers(val) {
    if (this.checkCustomAnswers(val)) {
      this.customQuestionsSelected = this.customQuestionsSelected.filter(
        (e) => e.question != val,
      );
    } else {
      this.customQuestionsSelected.push({ question: val, answer: null });
    }
  }
  delCostomQ(val) {
    this.customQuestions = this.customQuestions.filter(
      (e) => e.question != val,
    );
    this.customQuestionsSelected = this.customQuestionsSelected.filter(
      (e) => e.question != val,
    );
  }
  editCustomQAnswer(val, data) {
    this.customQuestionsSelected.forEach((e) => {
      if (e.question === val) {
        e.answer = data;
        return;
      }
    });
  }
  checkCustomQAnswer(val) {
    let data = null;
    this.customQuestionsSelected.map((e) => {
      if (e.question == val) {
        data = e.answer;
        return;
      }
    });
    return data;
  }

  updateSlot(value) {
    if (!this.isOtherLanguage) {
      value.speak_language_other = null;
    }
    const newCus = [];
    this.customQuestionsSelected.forEach((x) => {
      const a = { custom_question: x.question, answer: x.answer };
      newCus.push(a);
    });
    value.languages_speak = this.lang;
    value.how_often_speak_language = this.how_often_speak_language;
    value.answers = this.answers.concat(newCus);
    value.who_communicate_language_at_work =
      this.who_communicate_language_at_work;
    value.when_speak_language = this.when_speak_language;
    value.what_you_do_in_first_place = this.what_you_do_in_first_place;
    value.where_when_you_learn_language = this.where_when_you_learn_language;
    const isValid = this.validateForm(value);
    if (!isValid) {
      this.snackBar.open('Questions answer cannot left blank', null, {
        duration: 2000,
      });
      this.scrollToView('questions');
      return;
    }
    if (this.gotDataId == null) {
      this.loading = true;
      this.subscription = this.trainerService.postTimeSlotDetail(
        this.activeRoute.snapshot.params.id,
        value,
      );
      this.subscription.pipe(first()).subscribe(
        (res) => {
          this.saveData(res);
          this.notificationService.success(
            '200',
            'Successfully, Added your data...!',
          );
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.message);
        },
      );
    } else {
      this.loading = true;
      this.subscription = this.trainerService.updateTimeSlotDetail(
        this.activeRoute.snapshot.params.id,
        value,
      );
      this.subscription.pipe(first()).subscribe(
        (res) => {
          this.saveData(res);
          this.notificationService.success('201', 'Successfully, Updated...!');
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.message);
        },
      );
    }
  }

  arr_diff(a1, a2) {
    const diff = [];
    if (a1 != null && a1.length > 0) {
      a1.forEach((element) => {
        if (!(a2.indexOf(element) > -1)) {
          diff.push(element);
        }
      });
    }
    return diff;
  }
  editCommunicationWithInput(arr, val) {
    const result = this.arr_diff(this.who_communicate_language_at_work, arr);
    if (result != null && result.length > 0) {
      this.who_communicate_language_at_work =
        this.who_communicate_language_at_work.filter((e) => e !== result[0]);
      if (val != null && val.length > 0) {
        this.who_communicate_language_at_work.push(val);
      }
    } else {
      this.who_communicate_language_at_work.push(val);
    }
  }
  editSituationInput(arr, val) {
    const result = this.arr_diff(this.when_speak_language, arr);
    if (result != null && result.length > 0) {
      this.when_speak_language = this.when_speak_language.filter(
        (e) => e !== result[0],
      );
      if (val != null && val.length > 0) {
        this.when_speak_language.push(val);
      }
    } else {
      this.when_speak_language.push(val);
    }
  }
  editWantInput(val) {
    this.what_you_do_in_first_place = val;
  }
  checkWantInput() {
    return this.what_you_do_in_first_place;
  }
  checkCommunicationWithInput(arr) {
    const result = this.arr_diff(this.who_communicate_language_at_work, arr);
    if (result != null && result.length > 0) {
      return result[0];
    }
    return '';
  }
  checkSituationInput(arr) {
    const result = this.arr_diff(this.when_speak_language, arr);
    if (result != null && result.length > 0) {
      return result[0];
    }
    return '';
  }
  checkCommunicationWithOther(arr) {
    const result = this.arr_diff(this.who_communicate_language_at_work, arr);
    if (result != null && result.length > 0) {
      this.communicationWithOther = true;
    }
    return this.communicationWithOther;
  }
  checkSituationOther(arr) {
    const result = this.arr_diff(this.when_speak_language, arr);
    if (result != null && result.length > 0) {
      this.situationOther = true;
    }
    return this.situationOther;
  }
  checkWantOther(arr) {
    if (Object.is(this.what_you_do_in_first_place, null)) {
      return false;
    }
    if (this.what_you_do_in_first_place == '') {
      return true;
    }
    if (arr.indexOf(this.what_you_do_in_first_place) > -1) {
      return false;
    } else {
      return true;
    }
  }
  changeCommunicationWith(val, arr) {
    this.communicationWithOther = val;
    if (!val) {
      const result = this.arr_diff(this.who_communicate_language_at_work, arr);
      if (result != null && result.length > 0) {
        this.who_communicate_language_at_work =
          this.who_communicate_language_at_work.filter((e) => e !== result[0]);
      }
    }
  }
  changeSituation(val, arr) {
    this.situationOther = val;
    if (!val) {
      const result = this.arr_diff(this.when_speak_language, arr);
      if (result != null && result.length > 0) {
        this.when_speak_language = this.when_speak_language.filter(
          (e) => e !== result[0],
        );
      }
    }
  }
  changeWant() {
    this.wantOther = !this.wantOther;
    this.what_you_do_in_first_place = '';
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '600px',
      data: { question: null },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null && result.length > 0) {
        this.customQuestions.push({ question: result, answer: null });
        this.customQuestionsSelected.push({ question: result, answer: null });
      }
    });
  }

  /**
   * validating empty answers
   * @param value contains form data
   */
  validateForm(value): boolean {
    let isValid = true;
    if (value && value.answers) {
      value.answers.some((item) => {
        if (item.answer === null || item.answer === '') {
          isValid = false;
        }
      });
    }
    return isValid;
  }

  toggleOtherLanguage() {
    this.isOtherLanguage = !this.isOtherLanguage;
  }

  /**
   * scroll to specified section
   * @param el id of questions section
   */
  scrollToView(el: string): any {
    document.getElementById(el).scrollIntoView({ behavior: 'smooth' });
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: './dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
