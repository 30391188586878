import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfirmedValidator } from '../../../../shared/confirmPassword.validator';
import { PasswordResetService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from './../../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.sass'],
})
export class NewPasswordComponent implements OnInit {
  resetForm: FormGroup;
  loading = false;
  submitted = false;
  userEmail: string;
  token: string;
  langs = languages;
  url = '';
  constructor(
    private formBuilder: FormBuilder,
    private passwordResetService: PasswordResetService,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService,
  ) {}

  ngOnInit() {
    const reset = this.activeRoute.snapshot.queryParams.reset;

    if (reset) {
      this.url = atob(reset.replace(/_/g, '/').replace(/-/g, '+'));
    }

    this.resetForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        password_confirmation: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'password_confirmation'),
      }
    );

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }
  
  get f() {
    return this.resetForm.controls;
  }

  onSubmit(value) {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;

    this.passwordResetService.forgetPassword(this.url, value).subscribe(
      (data) => {
        this.loading = false;
        this.notificationService.success(201, 'Your Password is changed!');
      },
      (error) => {
        this.notificationService.error(error.status, error.error.message);
        this.loading = false;
      }
    );
  }
}
