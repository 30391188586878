import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../shared/language.translation';
import { LocalLanguageService } from '@services/index';
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.sass'],
})
export class UnauthorizedComponent implements OnInit {
  langs = languages;
  constructor(
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {}

  ngOnInit(): void {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }
  // language method
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }
}
