import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfferService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-quote-decline',
  templateUrl: './quote-decline.component.html',
  styleUrls: ['./quote-decline.component.sass'],
})
export class QuoteDeclineComponent implements OnInit {
  messageForm: FormGroup;
  loading = false;
  submitted = false;
  quoteId = 0;
  langs = languages;
  isDeclined = false;
  pageLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private offerService: OfferService,
    private router: Router,
    private notificationService: NotificationsService,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
  ) {
    this.quoteId = +this.activeRoute?.snapshot?.params?.id;
  }

  ngOnInit() {
    this.messageForm = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    const browserlang = this.translate.getBrowserLang();

    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }

    this.fetchData();
  }

  fetchData() {
    this.pageLoading = true;

    this.offerService.getOffer(this.quoteId).subscribe(
      (res: any) => {
        this.isDeclined = !!(
          res?.data?.status === 'accepted' ||
          res?.data?.status === 'declined' ||
          res?.data?.status === 'prepare'
        );

        this.pageLoading = false;
      },
      (err) => {
        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad',
        );
        this.pageLoading = false;
        if (err.status === 403) {
          this.router.navigate(['/unauthorized']);
        }
      },
    );
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.messageForm.controls;
  }

  onSubmit(value) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.messageForm.invalid) {
      return;
    }
    this.loading = true;
    this.offerService.declineQuote(this.quoteId, value).subscribe(
      (data) => {
        if (data) {
          this.loading = false;
          this.notificationService.success(
            200,
            'Successfully sent for message',
          );
          this.isDeclined = true;
        }
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error.message ? error.error.message : 'Something Bad',
        );
        this.loading = false;
      },
    );
  }
}
