import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { PasswordResetService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from './../../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.sass'],
})
export class ConfirmEmailComponent implements OnInit {
  mailForm: FormGroup;
  loading = false;
  submitted = false;
  mailCheck = false;
  // returnUrl: string;
  langs = languages;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private passwordResetService: PasswordResetService,

    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService
  ) {}

  ngOnInit(): void {
    const emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.mailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailregex)]],
    });

    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    // Language Translations

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  // language method
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.mailForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.mailForm.invalid) {
      return;
    }

    this.loading = true;
    this.passwordResetService
      .confirmEmail({ email: this.f.email.value })
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;
          this.notificationService.success(
            '200',
            'Confirmation email sent to your email account'
          );
          // this.router.navigate(['/auth/login']);
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(
            error.status,
            error.error.errors
              ? error.error.errors.email
                ? error.error.errors.email
                : error.error.message
              : error.error.message
          );
        }
      );
  }
}
