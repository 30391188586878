import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  LessonCancelService,
  LocalLanguageService,
  GroupInfoService,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogPeopleAbsent {
  lessonId: string;
  language: string;
}

@Component({
  selector: 'app-people-absent',
  templateUrl: './people-absent.component.html',
  styleUrls: ['./people-absent.component.sass'],
})
export class PeopleAbsentComponent implements OnInit {
  chatForm: FormGroup;
  subscription = null;
  loading = false;
  submitted = false;
  langs = languages;

  constructor(
    public dialogRef: MatDialogRef<PeopleAbsentComponent>,
    private formBuilder: FormBuilder,
    private groupInfo: GroupInfoService,
    private notificationService: NotificationsService,
    private activeRoute: ActivatedRoute,

    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogPeopleAbsent,
    private localLanguageService: LocalLanguageService
  ) {}

  ngOnInit() {
    this.chatForm = this.formBuilder.group({
      cancellation_reason: ['cancel_lesson', Validators.required],
      cancellation_reason_detail: ['', Validators.required],
      cancelled: false,
    });
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.useLanguage(this.data.language);
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get f() {
    return this.chatForm.controls;
  }

  onSubmit(value) {
    value.cancelled = true;
    value.cancellation_reason = +value.cancellation_reason;
    // value.cancelled=true;
    this.submitted = true;
    this.groupInfo.updateLesson(+this.data.lessonId, value).subscribe((res) => {
      this.notificationService.success('200', 'Lesson Cancelled Successfully');
      this.loading = true;
    },err=>{
      this.notificationService.error(err.status,err.error.message);
    });
  }
}
