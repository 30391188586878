<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />
<div class="container">
  <h1>{{ "AcceptGroupProposal.title" | translate }}</h1>
  <p class="note">
    {{ "AcceptGroupProposal.noteLabel" | translate }}
    <br />
    {{ "AcceptGroupProposal.noteDetailsStart" | translate }}
    {{ "AcceptGroupProposal.noteDetailsEnd" | translate }}
  </p>
  <div class="row">
    <div class="col-md-6">
      <br />
      <form
        [formGroup]="acceptProposalForm"
        (ngSubmit)="onSubmit(acceptProposalForm.value)"
      >
        <mat-card>
          <mat-card-content>
            <section class="example-section">
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="isAgreed"
                formControlName="accepted"
                ><span>{{
                  "AcceptGroupProposal.checkBoxDetailsStart" | translate
                }}</span></mat-checkbox
              >
            </section>
          </mat-card-content>
        </mat-card>
        <div class="form-group">
          <button
            class="btn button"
            [disabled]="loading || !isAgreed || status"
          >
            {{ "AcceptGroupProposal.submitBtn" | translate }}
          </button>
          <img
            *ngIf="loading"
            class="pl-3"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </div>
        <br />

      </form>
    </div>
  </div>
</div>
