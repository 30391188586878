import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../interfaces/user.interface';
import { API } from './api.urls';

const headers = new HttpHeaders({ Accept: 'application/json' });

// HTTP POST using these headers

@Injectable({ providedIn: 'root' })
export class PasswordResetService {
  constructor(private http: HttpClient) {}

  confirmEmail(email) {
    return this.http.post(`${API.confirmEmail}`, email, {
      headers,
    });
  }

  resetPassword(formData) {
    return this.http.post(`${API.changePassword}`, formData, {
      headers,
    });
  }
  forgetPassword(url: string, formData) {
    return this.http.post(url, formData, {
      headers,
    });
  }
  changePassword(formData) {
    return this.http.put(`${API.resetPassword}`, formData, {
      headers,
    });
  }
}
