<div class="container">
  <h4>{{ "TimeSlotDialog.messageLabel" | translate }}</h4>
  <form [formGroup]="timeslotForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <textarea
        #input
        type="text"
        formControlName="comment"
        class="form-control"
        required
        maxlength="256"
        minlength="3"
        placeholder="{{ 'formData.timeslot' | translate }}"
        [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
      ></textarea>
      <small
        >{{ "validations.messageLength" | translate
        }}{{ input.value?.length || 0 }} / 256</small
      >
      <br />
      <span
        class="text-danger"
        *ngIf="
          timeslotForm.get('comment').touched &&
          timeslotForm.get('comment').hasError('required')
        "
      >
        {{ "validations.timeslotReq" | translate }}
      </span>
    </div>
    <button mat-button (click)="onNoClick()">
      {{ "TimeSlotDialog.cancelBtn" | translate }}
    </button>
    <button type="submit" class="btn button" [disabled]="buttonDisable">
      {{ "TimeSlotDialog.submitBtn" | translate }}
    </button>
  </form>
</div>
