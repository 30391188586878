import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class HelperService {
  constructor(private http: HttpClient) {}

  /**
   * Create and download file from binary response
   *
   * @param any data Array Buffer data
   * @param string fileName
   * @return void
   */
  makeAndDownLoadFile(data: any, fileName: string) {
    const binaryData = [];
    const dataType = data.type;
    binaryData.push(data);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
