import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { GroupInfoService, AuthV2Service } from '@services/index';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';
@Component({
  selector: 'app-student-info',
  templateUrl: './student-info.component.html',
  styleUrls: ['./student-info.component.sass'],
  providers: [AuthV2Service],
})
export class StudentInfoComponent implements OnInit, OnChanges {
  @Input() lessonId: number;
  @Input() language: string;
  studentId = null;
  students = [];
  subscription = null;
  studentDetail = {
    email: '',
    group_id: '',
    id: '',
    job_title: '',
    locale: '',
    mobile_phone: '',
    first_name: '',
    last_name: '',
    phone: '',
  };
  groupName = '';
  lessonAttendanceRecieved = true;
  submitAttendance = [];
  langs = languages;

  constructor(
    private groupInfo: GroupInfoService,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.groupInfo.getGroupDetails(
      this.activeRoute.snapshot.params.groupId,
    );

    this.subscription.subscribe((res) => {
      this.groupName = res.data.name;
    });

    this.subscription = this.groupInfo.getGroupStudent(
      this.activeRoute.snapshot.params.groupId,
    );

    this.subscription.subscribe((response) => {
      this.studentId = response.data[0]?.id;
      this.students = response.data;
      if (this.studentId) {
        this.groupInfo.getStudentInfo(this.studentId).subscribe((response) => {
          this.studentDetail = response.data;
        });
      }
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('lessonId')) {
      if (changes.lessonId.currentValue != null) {
        this.lessonId = changes.lessonId.currentValue;
      }
    }
    if (this.lessonId != null) {
      this.lessonAttendanceRecieved = true;
      this.subscription = this.groupInfo.getLessonAttendence(this.lessonId);
      this.subscription.subscribe((res) => {
        this.lessonAttendanceRecieved = false;
        this.submitAttendance = res.data;
      });
    }
  }
  showStatus(id) {
    const a = this.submitAttendance.find((x) => x.student_id == id);
    if (a == undefined) {
      return 'None';
    }
    if (a.present) {
      return 'Present';
    } else {
      return 'Absent';
    }
  }
  showRemarks(id) {
    const a = this.submitAttendance.find((x) => x.student_id == id);
    if (a == undefined) {
      return 'None';
    }
    return a.content;
  }
  showInfo(id) {
    this.studentId = id;
    this.lessonAttendanceRecieved = true;
    this.subscription = this.groupInfo.getStudentInfo(this.studentId);
    this.subscription.subscribe((res) => {
      this.studentDetail = res.data;
      this.lessonAttendanceRecieved = false;
    });
  }
}
