import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class AdditionalInfoCRUDService {
  constructor(private http: HttpClient) {}

  // Get offer/Quotation details

  createAdditionalInfo(group: number, data): Observable<any> {
    return this.http.post<any>(
      `${API.creatAdditionalInfo}/${group}/additional-information`,
      data
    );
  }

  updateAdditionalInfo(additional: number, data): Observable<any> {
    return this.http.post<any>(
      `${API.updateAdditionalInfo}/${additional}`,
      data
    );
  }
}
