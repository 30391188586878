import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  LocalLanguageService,
  TrainerService,
  AuthV2Service,
  EmailVerifyService,
} from '@services/index';
import * as moment from 'moment';
import { User } from '../../../interfaces';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { FormControl } from '@angular/forms';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getCode, getNames } from 'country-list';
import { MatSelectChange } from '@angular/material/select';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';

@Component({
  selector: 'app-traier-profile',
  templateUrl: './traier-profile.component.html',
  styleUrls: ['./traier-profile.component.sass'],
  providers: [AuthV2Service],
})
export class TraierProfileComponent implements OnInit {
  trainerProfile: FormGroup;
  loading = false;
  uploadloading = false;
  submitted = false;
  profile: any;
  countryInit = 'be';
  countryFilterValue: string;
  filteredCountries = null;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Belgium,
    CountryISO.Netherlands,
  ];
  phoneForm = new FormGroup({
    phone: new FormControl('', [Validators.required]),
  });

  subscription = null;
  countryList = [];
  currentUser: User;
  currentUserSubscription: Subscription;
  myModel = 0;
  langs = languages;

  hiddenTags = true;
  dropdownSettings: IDropdownSettings = {};

  trainerId: number;
  user_id: number;
  private places: any;
  spinerLoading = true;
  selectedItems: string;
  verifiedEmail = false;
  loadingAttend = false;

  constructor(
    private formBuilder: FormBuilder,
    private trainerService: TrainerService,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
    public dialog: MatDialog,
    private emailVerifyService: EmailVerifyService,
  ) {
    getNames().map((item) => {
      this.countryList.push({ id: getCode(item), name: item });
    });
    this.trainerProfile = this.formBuilder.group({
      salutation: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: [
        { value: '', disabled: true },
        [Validators.required, Validators.email],
      ],
      phone: ['', [Validators.required]],
      address_line_1: ['', [Validators.required]],
      address_line_2: [''],
      postal_code: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]],
      experience: [null, [Validators.required]],
      has_laptop: [''],
      date_of_birth: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      maxHeight: 100,
      limitSelection: 1,
      idField: 'id',
      textField: 'label',
    };

    this.languageChangeHandler();

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  onAddressSelected(address: Address) {
    this.trainerProfile.patchValue({
      address_line_1: address?.formatted_address || null,
      address_line_2: null,
      latitude: address?.geometry?.location?.lat() || null,
      longitude: address?.geometry?.location?.lng() || null,
    });

    for (const component of address.address_components as AddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'postal_code': {
          this.trainerProfile.patchValue({
            postal_code: component?.long_name || null,
          });
          break;
        }

        case 'locality':
          this.trainerProfile.patchValue({
            city: component?.long_name || null,
          });
          break;

        case 'administrative_area_level_1': {
          this.trainerProfile.patchValue({
            state: component?.long_name || null,
          });
          break;
        }

        case 'country':
          this.trainerProfile.patchValue({
            country: component?.short_name.toUpperCase() || null,
          });
          break;
      }
    }
  }

  compareById<T extends string | number>(
    item1: T | { id: T },
    item2: T | { id: T },
  ): boolean {
    const id1 = item1 && typeof item1 === 'object' ? item1.id : item1;
    const id2 = item2 && typeof item2 === 'object' ? item2.id : item2;

    return id1 === id2;
  }

  trackById<T extends string | number>(idx: number, item: { id: T }): T {
    return item.id;
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang); // use instead of setDefault
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.subscription = this.trainerService.getTrainerProfile();
    this.subscription.subscribe(
      (res) => {
        const a = res.data.phone;
        const phoneNumber = parsePhoneNumberFromString(a ? a : '');
        if (phoneNumber) {
          this.countryInit = phoneNumber.country.toLocaleLowerCase();
        }

        this.profile = res;
        this.trainerProfile.controls.salutation.setValue(
          res.data.salutation
            ? res.data.salutation === 'mrs'
              ? 'ms'
              : res.data.salutation
            : '',
        );
        this.selectedItems = res.data.salutation;
        this.trainerProfile.controls.first_name.setValue(res.data.first_name);
        this.trainerProfile.controls.last_name.setValue(res.data.last_name);
        this.trainerProfile.controls.email.setValue(res.data.email);
        this.trainerProfile.controls.phone.setValue(
          phoneNumber ? phoneNumber.nationalNumber : '',
        );
        this.trainerProfile.controls.address_line_1.setValue(
          res.data.address_line_1 ? res.data.address_line_1 : '',
        );
        this.trainerProfile.controls.address_line_2.setValue(
          res.data.address_line_2 ? res.data.address_line_2 : '',
        );
        this.trainerProfile.controls.postal_code.setValue(
          res.data.postal_code ? res.data.postal_code : '',
        );
        this.trainerProfile.controls.city.setValue(
          res.data.city ? res.data.city : '',
        );
        this.trainerProfile.controls.state.setValue(
          res.data.state ? res.data.state : '',
        );
        this.trainerProfile.controls.country.setValue(
          res.data.country ? res.data.country : '',
        );
        this.trainerProfile.controls.experience.setValue(
          res.data.experience ? res.data.experience : '',
        );
        this.trainerProfile.controls.has_laptop.setValue(res.data.has_laptop);
        this.trainerProfile.controls.date_of_birth.setValue(
          res.data.date_of_birth,
        );
        this.spinerLoading = false;
        if (
          res.data.user.email_verified_at == null ||
          !res.data.user.email_verified_at
        ) {
          this.verifiedEmail = true;
        }
      },
      (error) => {
        const err =
          error && error.error && error.error.message
            ? error.error.message
            : 'Failure';
        this.notificationService.error(error.status, err);
      },
    );
    this.filterCountries();
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  filterCountries(value = this.countryFilterValue) {
    this.countryFilterValue = value;
    if (this.countryList) {
      if (!value) {
        this.filteredCountries = this.countryList;

        return;
      }
      this.filteredCountries = this.countryList.filter(
        (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1,
      );
    }
  }

  onSelect(event: MatSelectChange) {
    this.trainerProfile.controls.country.setValue(event.value);
  }

  updateProfileData(values) {
    values.id = this.trainerId;
    const phone = values.phone.internationalNumber;
    delete values.phone;
    values.date_of_birth = moment(values.date_of_birth).format();
    values.phone = phone;
    values.latitude = values.latitude ? values.latitude : '0';
    values.longitude = values.longitude ? values.longitude : '0';
    values.user_id = this.user_id;
    this.loading = true;
    this.trainerService.updateTrainer(values).subscribe(
      (res) => {
        this.notificationService.success('200', 'Update Successfully');

        this.loading = false;
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error && error.error.message ? error.error.message : 'Failed',
        );

        this.loading = false;
        this.uploadloading = false;
      },
    );
  }

  get f() {
    return this.trainerProfile.controls;
  }

  verifyEmail() {
    this.loadingAttend = true;
    this.emailVerifyService.trainerVerifyEmail().subscribe(
      (res) => {
        this.verifiedEmail = false;
        this.loadingAttend = false;

        this.notificationService.success(
          '200',
          'Confirmation Email is sent to your email address',
        );
      },
      (error) => {
        this.loadingAttend = false;
        this.notificationService.error(error.status, error.error.message);
      },
    );
  }
}
