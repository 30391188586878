<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<br />
<div class="container">
  <mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened style="z-index: 0">
      <div class="lessonDrawer">
        {{ "LessonDetails.allLessonsLabel" | translate }}
      </div>
      <mat-action-list style="padding-top: 0">
        <div *ngFor="let key of lessons">
          <mat-list-item
            [ngStyle]="{
              'background-color': key.id == lessonId ? '#252e4b' : '#ffffff',
              color: key.id == lessonId ? '#ffffff' : '#252E4B'
            }"
            role="listitem"
            class="listdata"
            style="
              display: table-caption;
              height: 110px;
              width: 160px;
              border-bottom: 1px solid lightgray;
            "
            (click)="showInfo(key.id)"
            >({{
              key.start | amLocale: getlocale() | amLocal | amDateFormat: "L LT"
            }}
            -
            {{
              key.end | amLocale: getlocale() | amLocal | amDateFormat: "L LT"
            }})</mat-list-item
          >
        </div>
      </mat-action-list>
    </mat-drawer>
    <mat-drawer-content>
      <mat-tab-group
        animationDuration="1000ms"
        dynamicHeight
        class="tabs"
        #tabs
      >
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>group_work</mat-icon> &nbsp;
            {{ "LessonDetails.generalInfoLabel" | translate }}</ng-template
          >
          <app-gernal-info
            [lessonId]="lessonId"
            [language]="language"
          ></app-gernal-info>
        </mat-tab>

        <mat-tab label="Addtional Info" [disabled]="checkLesson(lessonId)">
          <ng-template mat-tab-label>
            <mat-icon>info</mat-icon> &nbsp;{{
              "LessonDetails.studentInfoTab" | translate
            }}</ng-template
          >
          <app-student-info
            [lessonId]="lessonId"
            [language]="language"
          ></app-student-info>
        </mat-tab>

        <mat-tab label="Objectives / Goals" [disabled]="checkLesson(lessonId)">
          <ng-template mat-tab-label>
            <mat-icon>emoji_objects</mat-icon> &nbsp;{{
              "LessonDetails.attendenceTab" | translate
            }}</ng-template
          >
          <app-lesson-report
            [lessonId]="lessonId"
            [language]="language"
          ></app-lesson-report>
        </mat-tab>
      </mat-tab-group>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
