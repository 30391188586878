<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<br />
<div class="container">
  <a (click)="sidenav.toggle()"><mat-icon>menu</mat-icon> </a>
  <mat-drawer-container class="example-container">
    <mat-drawer mode="side" #sidenav style="z-index: 0" class="drawerStyle">
      <div class="groupDrawer">
        {{ "DisplayDashboard.groupsLabel" | translate }}
      </div>
      <mat-action-list style="padding-top: 0">
        <div *ngFor="let key of groups">
          <mat-list-item
            [ngStyle]="{
              'background-color': key.id === groupId ? '#252E4B' : '#ffffff',
              color: key.id === groupId ? '#ffffff' : '#252E4B'
            }"
            role="listitem"
            class="listdata"
            style="height: 35px; border-bottom: 1px solid lightgray"
            (click)="showInfo(key.id)"
            >{{ key.name }}</mat-list-item
          >
        </div>
      </mat-action-list>
    </mat-drawer>
    <mat-drawer-content>
      <div *ngIf="groupsLength == 0">
        <h1 style="text-align: center; color: red">
          {{ "DisplayDashboard.data_not_found" | translate }}
        </h1>
      </div>
      <mat-tab-group
        *ngIf="groupsLength > 0"
        animationDuration="1000ms"
        dynamicHeight
        class="tabs"
        #tabs
      >
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>group_work</mat-icon> &nbsp;{{
              "DisplayDashboard.groupTabLabel" | translate
            }}</ng-template
          ><app-group-info
            [groupId]="groupId"
            [language]="language"
          ></app-group-info
        ></mat-tab>

        <mat-tab label="Addtional Info">
          <ng-template mat-tab-label>
            <mat-icon>info</mat-icon> &nbsp;{{
              "DisplayDashboard.addtionalTabLabel" | translate
            }}</ng-template
          >
          <app-addtional-info
            [groupId]="groupId"
            [language]="language"
          ></app-addtional-info
        ></mat-tab>

        <mat-tab label="Objectives / Goals">
          <ng-template mat-tab-label>
            <mat-icon>emoji_objects</mat-icon> &nbsp;{{
              "DisplayDashboard.goalsTabLabel" | translate
            }}</ng-template
          >
          <app-goals [groupId]="groupId" [language]="language"></app-goals
        ></mat-tab>

        <mat-tab label="Progress Reports" [disabled]="checkLessons()">
          <ng-template mat-tab-label>
            <mat-icon>analytics</mat-icon> &nbsp;{{
              "DisplayDashboard.progressTabLabel" | translate
            }}</ng-template
          >
          <app-progress-report
            [groupId]="groupId"
            [language]="language"
          ></app-progress-report
        ></mat-tab>
      </mat-tab-group>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
