import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import {
  DayPilot,
  DayPilotSchedulerComponent,
  DayPilotNavigatorComponent,
} from 'daypilot-pro-angular';
import { DataService } from './data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocalLanguageService } from '@services/index';
import { languages } from '../../../shared/language.translation';
import { delay } from 'rxjs/operators';

const localeMap = {
  en: 'en-gb',
  nl: 'nl-be',
  fr: 'fr-be',
  de: 'de-de',
};

@Component({
  selector: 'app-timesheet-component',
  templateUrl: './time-scheduler.component.html',
  styleUrls: ['./time-scheduler.component.css'],
})
export class TimeSchedulerComponent implements AfterViewInit, OnDestroy {
  constructor(
    private localLanguageService: LocalLanguageService,
    public translate: TranslateService,
    private ds: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) document,
  ) {
    this.languageSubscription = this.translate.onLangChange
      .pipe(delay(0))
      .subscribe(() => this.changeLang());
    this.doc = document;
  }
  @ViewChild('navigator') navigator: DayPilotNavigatorComponent;
  @ViewChild('calendar', { static: false })
  calendar: DayPilotSchedulerComponent;
  events: any[] = [];
  languageSubscription = null;
  lessonId = 0;
  langs = languages;
  navigatorConfig: any = {
    showMonths: 3,
    skipMonths: 3,
    selectMode: 'week',
    cellWidth: 30,
    cellHeight: 30,
    dayHeaderHeight: 30,

    titleHeight: 30,
  };
  doc: any;

  config: any = {
    viewType: 'Week',
    locale: 'nl-be',
    startDate: DayPilot.Date.today().firstDayOfWeek(),
    cellHeight: 30,
    headerHeight: 30,
    hourWidth: 60,
    onBeforeEventRender: (args) => {
      if (args.data.additional && args.data.additional.color) {
        args.data.backColor = args.data.additional.color;
        args.data.borderColor = this.darken(args.data.backColor, 0.1);
      }
    },
    onBeforeRowHeaderRender(args) {
      args.row.horizontalAlignment = 'center';

      const duration = args.row.events.totalDuration();
      if (duration.totalSeconds() > 0) {
        let str = duration.toString('H:mm');
        if (duration.totalDays() >= 1) {
          str =
            Math.floor(duration.totalHours()) + ':' + duration.toString('mm');
        }

        args.row.columns[1].html = str + ' hours';
      }
    },
    onEventClick: (args) => {
      if (args.e.data.text == this.translate.instant('Lesson')) {
        this.router.navigate([
          `/group/${args.e.data.groupId}/lesson/${args.e.data.lessonId}`,
        ]);
      }
      if (args.e.data.sId == null) {
        return;
      }
      this.router.navigate([`/trainer/languagetest/${args.e.data.sId}`]);
    },
  };
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang); // use instead of setDefault
  }
  changeLang() {
    this.calendar.control.update({ locale: localeMap[this.getlocale()] });
    this.navigator.control.update({ locale: localeMap[this.getlocale()] });
  }
  getlocale() {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }
  ngOnDestroy() {
    this.languageSubscription.unsubscribe();
  }
  darken(color: string, k: number): string {
    if (color[0] !== '#' || color.length !== 7) {
      throw new Error("Color expected in full hex format, e.g. '#ffffff'");
    }
    const R = parseInt(color.substring(1, 3), 16);
    const G = parseInt(color.substring(3, 5), 16);
    const B = parseInt(color.substring(5, 7), 16);
    return '#' + factor(R, k) + factor(G, k) + factor(B, k);

    function factor(c: number, k: number): string {
      let v = c * (1 - k);
      v = Math.round(v);
      v = Math.min(v, 255);
      v = Math.max(v, 0);
      return v.toString(16);
    }
  }

  viewChange(): void {
    const from = this.calendar.control.visibleStart();
    const to = this.calendar.control.visibleEnd();
    this.ds.getEvents(from, to).subscribe((result) => {
      this.events = result;
    });
  }
  navigatePrevious(event): void {
    event.preventDefault();
    this.config.startDate = this.config.startDate.addDays(-7);
  }

  navigateNext(event): void {
    event.preventDefault();
    this.config.startDate = this.config.startDate.addDays(7);
  }

  navigateToday(event): void {
    event.preventDefault();
    this.config.startDate = DayPilot.Date.today();
  }
  ngAfterViewInit(): void {
    this.changeLang();
    const demoElem = this.doc.querySelector(
      '.calendar_default_corner > div:nth-child(2)',
    );
    if (demoElem) {
      demoElem.style.visibility = 'hidden';
    }

    const from = this.calendar.control.visibleStart();
    const to = this.calendar.control.visibleEnd();
    this.ds.getEvents(from, to).subscribe((result) => {
      this.events = result;
    });
  }
}
