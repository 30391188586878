<div mat-dialog-content>
  <p>{{"LessonQuestionsDailog.note"|translate}}</p>
  <input
    placeholder="Enter Question here"
    [(ngModel)]="data.question"
    style="width: 100%; border: 1px solid #c8caca; border-radius: 5px"
  />
</div>
<div mat-dialog-actions style="margin-top: 10px">
  <button mat-button (click)="onNoClick()">
    {{"LessonQuestionsDailog.cancelBtn"|translate}}
  </button>
  <button mat-button [mat-dialog-close]="data.question" cdkFocusInitial>
    {{"LessonQuestionsDailog.addBtn"|translate}}
  </button>
</div>
