<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />
<div class="container">
  <h2 class="example-h2">{{ "RegistrationUpdate.title" | translate }}</h2>
  <br />
  <br />

  <p>
    <b>{{ "RegistrationUpdate.noteLabel" | translate }}</b>
    <br />
    {{ "RegistrationUpdate.noteDetails" | translate }}
  </p>
  <br />
  <form
    [formGroup]="updateInfoForm"
    (ngSubmit)="onSubmit(updateInfoForm.value)"
  >
    <div class="form-group">
      <b>
        <label for="message">{{
          "RegistrationUpdate.messageLabel" | translate
        }}</label></b
      >
      <textarea
        #input
        type="text"
        formControlName="message"
        class="form-control"
        required
        id="message"
        maxlength="256"
        minlength="3"
        [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
      ></textarea>
      <small
        >{{ "validations.messageLength" | translate
        }}{{ input.value?.length || 0 }} / 3-256</small
      >
      <br />
      <div
        class="alertError"
        *ngIf="
          !updateInfoForm.controls['message'].valid &&
          updateInfoForm.controls['message'].touched
        "
      >
        <div [hidden]="!updateInfoForm.controls['message'].errors.required">
          {{ "validations.messageReq" | translate }}
        </div>
      </div>
    </div>

    <button
      [disabled]="loading || buttonDisabled || updateInfoForm.invalid"
      class="btn button"
    >
      {{ "RegistrationUpdate.submitBtn" | translate }}
    </button>
    <img
      *ngIf="loading"
      class="pl-3"
      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
    />
  </form>
  <br />
  <br />
</div>
