import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {LocalLanguageService, TrainerService} from '@services/index';
import {NotificationsService} from 'angular2-notifications';
import {languages} from '../../../shared/language.translation';

@Component({
  selector: 'app-trainer-cv',
  templateUrl: './trainer-cv.component.html',
  styleUrls: ['./trainer-cv.component.sass'],
})
export class TrainerCVComponent implements OnInit {
  resumeForm: FormGroup;
  subscription = null;
  files = [];
  resume: any;
  file = {status: '', message: '', filePath: ''};
  langs = languages;
  loading = false;
  uploadResume = null;

  constructor(
    private formBuilder: FormBuilder,
    private trainerService: TrainerService,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService
  ) {
  }

  ngOnInit() {
    this.resumeForm = this.formBuilder.group({
      uploadFile: ['', Validators.required],
    });
    this.languageChangeHandler();
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  onSubmit() {
    this.uploadResume = this.resumeForm.get('uploadFile').value;
    const formData = new FormData();
    formData.append('file', this.uploadResume);
    this.loading = true;
    this.trainerService.uploadResume(formData).subscribe(
      (res) => {
        this.notificationService.success(
          '200',
          'Successfully uploaded!'
        );

        this.resume = res.data.signed_doc;
        this.loading = false;
      },
      (err) => {
        this.loading = false;

        this.notificationService.error(
          err.status,
          err.error.errors.file || err.error.message || 'Something Bad'
        );
      }
    );
  }

  private languageChangeHandler() {
    this.subscription = this.trainerService.getTrainerProfile();
    this.subscription.subscribe(
      (res) => {
        this.resumeForm.controls.uploadFile.setValue(res.data.resume);
        this.resume = res.data.resume;
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Something Bad'
        );
        //  / this.loading = false;
      }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.resumeForm.get('uploadFile').setValue(file);
    }
  }
}
