import { Component, OnInit } from '@angular/core';
import { EmailVerifyService } from '@services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { languages } from './../../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.sass'],
})
export class EmailVerificationComponent implements OnInit {
  verifyResponse = false;
  spinerLoading = true;
  langs = languages;
  constructor(
    private emailService: EmailVerifyService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    const verify = this.activatedRoute.snapshot.queryParams.verify;

    if (verify) {
      const url = atob(verify.replace(/_/g, '/').replace(/-/g, '+'));
      this.emailService.emailVerify(url).subscribe(
        (res) => {
          this.verifyResponse = true;
          this.notificationService.success(200, 'Successfully verified.');
          this.spinerLoading = false;
        },
        (error) => {
          this.verifyResponse = false;
          this.notificationService.error(error.status, error.error.message);
          this.spinerLoading = false;
        }
      );
    }
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  // language method
  public useLanguage(lang: string): void {
    this.translate.use(lang);
  }
}
