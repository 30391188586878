import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class CourseRegisterService {
  sharedComment: string;
  constructor(private http: HttpClient) {}

  updateInfo(registration: number, data) {
    return this.http.post(
      `${API.updateRegistration}/${registration}/decline`,
      data
    );
  }

  public acceptRegistration(registration: number, formData) {
    return this.http
      .post<any>(`${API.acceptRegistration}/${registration}/accept`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          // a switch to get progress and response from a file uploading section
          switch (event.type) {
            case HttpEventType.UploadProgress:
              // calculate the file uploading progress.
              const uploadProgress = Math.round(
                (100 * event.loaded) / event.total
              );
              return { status: 'progress', message: uploadProgress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  getRegistration(registration: number): Observable<[any]> {
    return this.http.get<[any]>(`${API.registrationDetails}/${registration}`);
  }

  getRegistrationStudents(
    registration: number,
    perPage: number,
    page: number
  ): Observable<[any]> {
    return this.http.get<[any]>(
      `${API.registrationStudents}/${registration}/student?with[]=timeSlot&with[]=primaryLanguage&per_page=${perPage}&page=${page}`
    );
  }
  getRegistrationFile(registration: number) {
    return this.http.get<[any]>(
      `${API.registrationFile}/${registration}/download-document`,
      {responseType: 'blob' as 'json'}
    );
  }
}
