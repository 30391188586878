import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  GroupInfoService,
  AuthV2Service,
  LocalLanguageService,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { RescheduleLessonComponent } from '../cancelLesson/reschedule-lesson/reschedule-lesson.component';
import { MatDialog } from '@angular/material/dialog';
import { PeopleAbsentComponent } from '../cancelLesson/people-absent/people-absent.component';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  selector: 'app-gernal-info',
  templateUrl: './gernal-info.component.html',
  styleUrls: ['./gernal-info.component.sass'],
  providers: [AuthV2Service],
})
export class GernalInfoComponent implements OnInit, OnChanges {
  @Input() lessonId: number;
  @Input() language: string;
  // @Input() language: string;
  lessons = [];
  dataRecieved = false;
  notes = '';
  lessonStartLoading = false;
  lessonEndLoading = false;
  lessonDetail = {};
  lessonDetailForm: FormGroup;
  subscription = null;
  loading = false;
  langs = languages;
  // language = 'en';
  backGround = 'primary';
  color = 'primary';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter note for lesson here',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dialogAbsentForm: MatDialog,
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private groupInfo: GroupInfoService,
    private localLanguageService: LocalLanguageService
  ) {
    this.lessonDetailForm = this.formBuilder.group({
      notes: '',
      attendance_file: '',
      attendance_list: '',
      cancellation_reason: '',
      cancellation_reason_detail: '',
      cancelled: false,
      end: '',
      group: {},
      group_id: null,
      id: null,
      lesson_end: null,
      lesson_start: null,
      remarks: '',
      start: '',
      trainer_id: null,
    });
  }

  ngOnInit(): void {
    this.loading = true;

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.dataRecieved = true;
  }

  navigateGroup(id) {
    this.router.navigate([`/group/${id}`]);
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('lessonId')) {
      if (changes.lessonId.currentValue != null) {
        this.lessonId = changes.lessonId.currentValue;
      }
    }
    if (this.lessonId != null) {
      this.loading = true;
      this.subscription = this.groupInfo.getLessonDetail(this.lessonId);
      this.subscription.subscribe((res) => {
        this.lessonDetail = res.data;
        this.lessonDetailForm.controls.notes.setValue(res.data.notes);
        this.lessonDetailForm.controls.start.setValue(res.data.start);
        this.lessonDetailForm.controls.end.setValue(res.data.end);
        this.lessonDetailForm.controls.attendance_file.setValue(
          res.data.attendance_file
        );
        this.lessonDetailForm.controls.attendance_list.setValue(
          res.data.attendance_list
        );
        this.lessonDetailForm.controls.cancellation_reason.setValue(
          res.data.cancellation_reason
        );
        this.lessonDetailForm.controls.cancellation_reason_detail.setValue(
          res.data.cancellation_reason_detail
        );
        this.lessonDetailForm.controls.cancelled.setValue(res.data.cancelled);
        this.lessonDetailForm.controls.lesson_start.setValue(
          res.data.lesson_start
        );
        this.lessonDetailForm.controls.lesson_end.setValue(res.data.lesson_end);
        this.lessonDetailForm.controls.id.setValue(res.data.id);
        this.lessonDetailForm.controls.remarks.setValue(res.data.remarks);
        this.lessonDetailForm.controls.trainer_id.setValue(res.data.trainer_id);
        this.lessonDetailForm.controls.group.setValue(res.data.group);
        this.lessonDetailForm.controls.group_id.setValue(res.data.group_id);
        this.loading = false;
      });
      this.dataRecieved = true;
    }
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.dataRecieved = true;
  }
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  addNote() {}

  startLesson() {
    this.lessonStartLoading = true;
    this.loading = true;
    const CurrentDate = moment().toISOString();
    const form = { lesson_start: CurrentDate };
    let count = 0;
    this.groupInfo
      .getGroupGoal(this.activeRoute.snapshot.params.groupId)
      .subscribe((res) => {
        if (res.data.length === 0) {
          this.groupInfo
            .getLessons(this.activeRoute.snapshot.params.groupId)
            .subscribe((response) => {
              response.data.forEach((element) => {
                if (element.lesson_start != null) {
                  count++;
                }
              });
              if (count < 3) {
                this.subscription = this.groupInfo
                  .updateLesson(+this.lessonId, form)
                  .subscribe(
                    (resData: any) => {
                      this.notificationService.success(
                        '200',
                        'Lesson Started Successfully'
                      );

                      this.lessonDetail['lesson_start'] = resData.data.lesson_start;
                      this.lessonDetailForm.controls.lesson_start.setValue(
                        resData.data.lesson_start
                      );
                      this.lessonStartLoading = false;
                      this.loading = false;
                    },
                    (error) => {
                      this.loading = false;
                      this.notificationService.error(
                        error.status,
                        error.error && error.error.message
                          ? error.error.message
                          : 'Failed'
                      );

                      // this.uploadloading = false;
                    }
                  );
              } else {
                this.lessonStartLoading = false;
                this.loading = false;
                this.notificationService.error('Please Add Goals in the Group');
              }
            });
        } else {
          this.subscription = this.groupInfo
            .updateLesson(+this.lessonId, form)
            .subscribe(
              (response: any) => {
                this.notificationService.success(
                  '200',
                  'Lesson Started Successfully'
                );

                this.lessonDetail['lesson_start'] = response.data.lesson_start;
                this.lessonDetailForm.controls.lesson_start.setValue(
                  response.data.lesson_start
                );
                this.lessonStartLoading = false;
                this.loading = false;
              },
              (error) => {
                this.loading = false;
                this.notificationService.error(
                  error.status,
                  error.error && error.error.message
                    ? error.error.message
                    : 'Failed'
                );

                // this.uploadloading = false;
              }
            );
        }
      });
  }

  checkAttendenceFile(): boolean {
    if (this.lessonDetail['lesson_end'] === null) {
      return true;
    }
    return false;
  }

  endLesson(): void {
    this.lessonEndLoading = true;
    this.loading = true;
    const CurrentDate = moment().toISOString();
    const form = { lesson_end: CurrentDate };
    this.subscription = this.groupInfo
      .updateLesson(+this.lessonId, form)
      .subscribe(
        (res: any) => {
          this.notificationService.success('200', 'Lesson Endded Successfully');
          this.lessonDetail['lesson_end'] = res.data.lesson_end;
          this.lessonDetailForm.controls.lesson_end.setValue(
            res.data.lesson_end
          );
          this.lessonEndLoading = false;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(
            error.status,
            error.error && error.error.message ? error.error.message : 'Failed'
          );
        }
      );
  }

  handleFileInput(file): void {
    if (!file.length) {
      return;
    }
    this.loading = true;
    const data = new FormData();
    data.append('file', file[0], file[0].name);
    this.subscription = this.groupInfo
      .uploadFile(this.lessonId, data)
      .subscribe(
        (res) => {
          this.notificationService.success('200', 'Update Successfully');
          this.loading = false;
          this.lessonDetail = res.data;
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(
            error.status,
            error.error && error.error.message ? error.error.message : 'Failed'
          );
        }
      );
  }

  getlocale() : string {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }

  duration() {
    const CurrentDate = moment();
    if (this.lessonDetail['lesson_end'] === null) {
      const duration = moment.duration(
        CurrentDate.diff(this.lessonDetail['lesson_start'])
      );
      const hours = duration.asHours();
      const min = duration.asMinutes();
      const a = 'a';
      return a;
    } else {
      const duration = moment.duration(
        this.lessonDetail['lesson_end'].diff(this.lessonDetail['lesson_start'])
      );
      const hours = duration.asHours();
      const min = duration.asMinutes();
      const a = 'b';
      return a;
    }
  }

  checkTime(val): boolean {
    if (this.lessonDetail[val] === null) {
      return false;
    } else {
      return true;
    }
  }

  updateLesson(val): void {
    const form = { notes: val.notes };
    this.loading = true;
    this.subscription = this.groupInfo
      .updateLesson(+this.lessonId, form)
      .subscribe(
        (res) => {
          this.notificationService.success('200', 'Note Added');
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(
            error.status,
            error.error && error.error.message ? error.error.message : 'Failed'
          );
        }
      );
  }

  openRescheduleForm() : void {
    this.dialog.open(RescheduleLessonComponent, {
      width: '50%',
      height: '70%',
      data: {
        lessonId: this.lessonId,
        language: this.language,
      },
    });
  }

  openAbsentCaseForm(): void {
    this.dialog.open(PeopleAbsentComponent, {
      width: '50%',
      height: '70%',
      data: {
        lessonId: this.lessonId,
        language: this.language,
      },
    });
  }
}
