import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {
  StudentGroupingProposalService,
  LocalLanguageService,
} from '@services/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accept-proposal',
  templateUrl: './accept-proposal.component.html',
  styleUrls: ['./accept-proposal.component.sass'],
})
export class AcceptProposalComponent implements OnInit {
  loading = false;
  langs = languages;
  acceptProposalForm: FormGroup;
  isAgreed = 0;
  error: string;
  checked = 0;
  routeId: number;
  status = '';
  constructor(
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    private proposalService: StudentGroupingProposalService,
    private activeRoute: ActivatedRoute,
    private localLanguageService: LocalLanguageService
  ) {}

  ngOnInit(): void {
    this.routeId = +this.activeRoute.snapshot.params.registration;

    this.acceptProposalForm = this.formBuilder.group({
      accepted: ['', [Validators.required]],
    });
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  goToLink(url: string) {
    window.open(`${environment.apiURL}` + url);
  }

  onSubmit(value) {
    this.loading = true;
    this.proposalService.acceptGroup(this.routeId, value).subscribe(
      (res) => {
        if (res) {
          this.loading = false;
          this.notificationService.success(200, 'Successfully accepted');
        }
        this.status = res.data.status;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad'
        );
      }
    );
  }
}
