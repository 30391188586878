<div style="z-index: 1; position: absolute; left: 45%; top: 40%">
  <mat-spinner *ngIf="spinnerLoading"></mat-spinner>
</div>

<span *ngIf="!spinnerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />
  <div class="container">
    <h2>{{ "TrainerEvaluatioin.title" | translate }}</h2>
    <br />
    <p>
      {{ "TrainerEvaluatioin.note" | translate }}
    </p>
    <form [formGroup]="evaluationFeedbackform">
      <div>
        <label>• {{ "TrainerEvaluatioin.teachingWay" | translate }}</label>
        <br />
        <ngx-star-rating
          formControlName="way_of_teaching"
          [id]="'way_of_teaching'"
          [disabled]="true"
        ></ngx-star-rating>
        <br />
      </div>
      <br />
      <br />
      <div>
        <label>• {{ "TrainerEvaluatioin.adjustmentLevel" | translate }}</label>
        <br />
        <ngx-star-rating
          formControlName="adjustment_level"
          [id]="'adjustment_level'"
          [disabled]="true"
        ></ngx-star-rating>
        <br />
      </div>
      <br />
      <br />
      <div>
        <label>• {{ "TrainerEvaluatioin.subjectMatter" | translate }}</label>
        <br />
        <ngx-star-rating
          formControlName="subject_knowledge"
          [id]="'subject_knowledge'"
          [disabled]="true"
        ></ngx-star-rating>
        <br />
      </div>
      <br />
      <br />
      <div>
        <label>• {{ "TrainerEvaluatioin.contactTrainer" | translate }}</label>
        <br />
        <ngx-star-rating
          formControlName="contact_language_trainer"
          [id]="'contact_language_trainer'"
          [disabled]="true"
        ></ngx-star-rating>
        <br />
      </div>
      <br />
      <br />
      <div>
        <label
          >• {{ "TrainerEvaluatioin.correctionMistakes" | translate }}</label
        >
        <br />
        <ngx-star-rating
          formControlName="correction_of_mistakes"
          [id]="'correction_of_mistakes'"
          [disabled]="true"
        ></ngx-star-rating>
        <br />
      </div>
      <br />
      <br />
      <div>
        <label>• {{ "TrainerEvaluatioin.teachingMaterial" | translate }}</label>
        <br />
        <ngx-star-rating
          formControlName="use_of_teaching_materials"
          [id]="'use_of_teaching_materials'"
          [disabled]="true"
        ></ngx-star-rating>
        <br />
      </div>
      <br />
      <br />
      <h6>
        <b>{{ "TrainerEvaluatioin.totalScore" | translate }}</b>
        {{ averageScore }}
      </h6>
      <ngx-star-rating
        formControlName="score_average"
        [id]="'score_average'"
        [disabled]="true"
      ></ngx-star-rating>
      <br />
    </form>
    <br />
  </div>
</span>
