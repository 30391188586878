import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimeslotDialogComponent } from '../timeslot-dialog/timeslot-dialog.component';
import {
  StudentRegistrationService,
  LocalLanguageService,
} from '@services/index';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import * as moment from 'moment';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-student-timeslot',
  templateUrl: './student-timeslot.component.html',
  styleUrls: ['./student-timeslot.component.sass'],
})
export class StudentTimeslotComponent implements OnInit {
  timeSlots = null;
  animal: string;
  name: string;
  loading = false;
  timeslotId: number | null;
  studentId = null;
  buttonDisable = false;
  langs = languages;
  timeDisplayForm: FormGroup;
  language = 'en';
  page = 0;
  size: number;
  length = 0;
  pageSize = 10;
  pageSizeOptions: any[] = [0, 5, 10, 20];
  spinerLoading = true;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  pageCall($event) {
    this.page = $event.pageIndex;
    this.size = $event.pageSize;
  }

  constructor(
    public dialog: MatDialog,
    private studentRegisterServce: StudentRegistrationService,
    private activeRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private route: Router,
    private localLanguageService: LocalLanguageService
  ) {
    this.studentId = this.activeRoute.snapshot.params.studentId;
  }

  ngOnInit(): void {
    this.getTimeSlots(this.page);
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
    this.getTimeSlots(this.page);
  }
  // MatPaginator Output
  pageEvent(event) {
    //    (<FormArray>this.form.get("student")).clear();
    this.pageSize = event.pageSize;
    this.timeSlots = [];
    this.getTimeSlots(event.pageIndex);
  }
  getTimeSlots(page: number) {
    page++;
    this.studentRegisterServce.getTimeSlots(this.pageSize, page).subscribe(
      (data) => {
        this.length =
          data.meta && data.meta.total ? data.meta.total : 0;
        this.timeSlots = data.data.length
          ? data.data.map((key) => {
              return {
                value: `${moment(key.start).format('h:mm A')} to ${moment(
                  key.end
                ).format('h:mm A MMM DD YYYY')}`,
                id: key.id,
              };
            })
          : null;

        this.spinerLoading = false;
      },

      (error) => {
        if (error.status === 403) {
          this.route.navigate(['/unauthorized']);
        }
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Unable to fetch timeslots!'
        );
        this.spinerLoading = false;
      }
    );
  }

  clickedList(list) {
    const value = list.selectedOptions.selected.map((item) => item.value);

    this.timeslotId = +value;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TimeslotDialogComponent, {
      width: '450px',
      height: '300px',
      data: { id: this.studentId, language: this.language },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.animal = result;
    });
  }

  onSubmit() {
    this.loading = true;

    this.studentRegisterServce.saveTimeSlot(this.timeslotId).subscribe(
      (data) => {
        this.notificationService.success(
          '200',
          'Successfully, Saved your TimeSlot!'
        );
        this.timeSlots = this.timeSlots.filter(
          (key) => key.id !== this.timeslotId
        );
        this.loading = false;
        this.buttonDisable = true;
      },
      (error) => {
        this.loading = false;
        this.notificationService.error(error.status, error.error.message);
      }
    );
  }
}
