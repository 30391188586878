import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdditionalInfoCRUDService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

export interface DialogAddtionalInfo {
  id: string;
  language: string;
  groupId: string;
  addtionalDetails: {
    id: number;
    title: string;
    content: string;
    file: string;
  };
}

@Component({
  selector: 'app-addtional-info-update-form',
  templateUrl: './addtional-info-update-form.component.html',
  styleUrls: ['./addtional-info-update-form.component.sass'],
})
export class AddtionalInfoUpdateFormComponent implements OnInit {
  addtionalInfoForm: FormGroup;
  loading = false;
  file = false;
  constructor(
    private formBuilder: FormBuilder,
    private additionalService: AdditionalInfoCRUDService,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AddtionalInfoUpdateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogAddtionalInfo
  ) {}

  ngOnInit(): void {
    if (this.data.addtionalDetails.file == null) {
      this.file = true;
    }
    this.addtionalInfoForm = this.formBuilder.group({
      content: ['', Validators.required],
      title: ['', Validators.required],
      file: [''],
    });
    if (this.data.addtionalDetails) {
      this.addtionalInfoForm.controls.title.setValue(
        this.data.addtionalDetails.title
      );
      this.addtionalInfoForm.controls.content.setValue(
        this.data.addtionalDetails.content
      );
      this.addtionalInfoForm.controls.file.setValue(
        this.data.addtionalDetails.file
      );
    }
  }
  get form() {
    return this.addtionalInfoForm.controls;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  change() {
    this.file = !this.file;
  }
  handleFileInput(file) {
    if (!file.length) {
      return;
    }
    const data = new FormData();
    data.append('file', file[0], file[0].name);
    if (this.data.addtionalDetails && this.data.addtionalDetails.id) {
      this.additionalService
        .updateAdditionalInfo(+this.data.addtionalDetails.id, data)
        .subscribe(
          (res) => {
            this.loading = false;
            this.notificationService.success(200, 'File Updated');
            this.closeDialog();
          },
          (error) => {
            this.loading = false;
            this.notificationService.error(error.status, error.error.message);
          }
        );
    } else {
      this.additionalService
        .createAdditionalInfo(+this.data.groupId, data)
        .subscribe(
          (res) => {
            this.loading = false;
            this.notificationService.success(200, 'New File Added');
          },
          (error) => {
            this.loading = false;
            this.notificationService.error(error.status, error.error.message);
          }
        );
    }
  }
  onSubmit(value) {
    if (this.addtionalInfoForm.invalid) {
      return 0;
    }
    if (this.file) {
      delete value.file;
    } else {
      delete value.content;
      delete value.title;
    }

    this.loading = true;
    if (this.data.addtionalDetails && this.data.addtionalDetails.id) {
      value.delete_file = true;
      this.additionalService
        .updateAdditionalInfo(+this.data.addtionalDetails.id, value)
        .subscribe(
          (res) => {
            this.loading = false;
            this.notificationService.success(200, 'Content Updated');
            this.closeDialog();
          },
          (error) => {
            this.loading = false;
            this.notificationService.error(error.status, error.error.message);
          }
        );
    } else {
      this.additionalService
        .createAdditionalInfo(+this.data.groupId, value)
        .subscribe(
          (res) => {
            this.loading = false;
            this.notificationService.success(200, 'New Content Added');
            this.closeDialog();
          },
          (error) => {
            this.loading = false;
            this.notificationService.error(error.status, error.error.message);
          }
        );
    }
  }
}
