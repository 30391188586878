import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { LocalLanguageService } from '@services/index';

@Component({
  selector: 'app-language-translate',
  templateUrl: './language-translate.component.html',
  styleUrls: ['./language-translate.component.sass'],
})
export class LanguageTranslateComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {}
  langs = languages;
  ngOnInit(): void {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
      // this.translate.currentLang = 'French';
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.setDefaultLang(lang);
  }
}
