<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>

<br />
<div style="z-index: 1; position: absolute; left: 45%; top: 40%">
  <mat-spinner *ngIf="downloadLoading || loading"></mat-spinner>
</div>
<div class="container">
  <h2>{{ "TrainerContractFile.title" | translate }}</h2>
  <p>
    {{ "TrainerContractFile.noteLabel" | translate }}
    <br />
    {{ "TrainerContractFile.noteDetails" | translate }}
  </p>
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="contractForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="inputTag">
            <input
              type="file"
              name="profile"
              (change)="onFileSelect($event)"
              accept=".doc,.docx,.pdf"
            />
          </div>
          <div class="buttonLayout">
            <button type="button" class="btn button" (click)="downloadFile()">
              {{ "TrainerContractFile.downloadBtn" | translate }}
            </button>

            <ng-container *ngIf="agreementFile">
              <a class="btn button" href="{{ agreementFile }}" target="_blank">
                {{ "TrainerContractFile.downloadExistingBtn" | translate }}
              </a>
            </ng-container>

            <button
              type="submit"
              class="btn button"
              [disabled]="contractForm.invalid"
            >
              {{ "TrainerContractFile.uploadBtn" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
