<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<br />

<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <div class="container">
    <mat-card>
      <span *ngIf="verifyResponse">
        <h3 class="okResponse">
          {{ "EmailVerifyStatus.okMessage" | translate }}
        </h3>
      </span>
      <span *ngIf="!verifyResponse">
        <h3 class="failureResponse">
          {{ "EmailVerifyStatus.failureMessage" | translate }}
        </h3>
      </span>
    </mat-card>
  </div>
</span>
