<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>
    {{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] === translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<mat-card>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        {{ "Stats.attendence" | translate }}
      </ng-template>
      <div style="position: absolute; top: 45%; left: 45%; z-index: 1">
        <mat-spinner *ngIf="!loading_attend"></mat-spinner>
      </div>
      <div *ngIf="loading_attend">
        <mat-tab-group mat-align-tabs="center">
          <mat-tab>
            <ng-template mat-tab-label>
              {{ "Stats.general" | translate }}
            </ng-template>
            <div class="col-lg-9" style="margin: 0 auto; max-width: 79%">
              <pie-chart
                [generalAttendence]="generalAttendence"
                totalLabel="{{ 'Stats.total' | translate }}"
                presentLabel="{{ 'Stats.present' | translate }}"
                absentLabel="{{ 'Stats.absent' | translate }}"
              ></pie-chart>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              {{ "Stats.groups" | translate }}
            </ng-template>
            <div
              style="
                display: flex;
                justify-content: space-around;
                border-bottom: 1px solid lightgray;
              "
            >
              <mat-form-field appearance="fill" style="margin-top: 10px">
                <mat-label>Select Group</mat-label>
                <mat-select
                  [(value)]="groupId"
                  (selectionChange)="showGroup($event)"
                >
                  <div *ngFor="let key of groups">
                    <mat-option [value]="key.id">{{ key.label }}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-tab-group mat-align-tabs="center">
              <mat-tab>
                <ng-template mat-tab-label>
                  {{ "Stats.group_attendence" | translate }}
                </ng-template>
                <div class="col-lg-8" style="margin: 0 auto">
                  <pie-chart
                    [generalAttendence]="groupAttendence"
                    totalLabel="{{ 'Stats.total' | translate }}"
                    presentLabel="{{ 'Stats.present' | translate }}"
                    absentLabel="{{ 'Stats.absent' | translate }}"
                  ></pie-chart>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  {{ "Stats.students" | translate }}
                </ng-template>
                <mat-drawer-container class="example-container">
                  <mat-drawer mode="side" opened>
                    <mat-list style="padding-top: 0px">
                      <div *ngFor="let key of students">
                        <mat-list-item
                          style="border: 1px solid lightgray; cursor: pointer"
                          (click)="showStudent(key.id)"
                          [ngStyle]="{
                            'background-color':
                              key.id === StudentId ? '#252e4b' : '#ffffff'
                          }"
                        >
                          {{ key.label }}
                        </mat-list-item>
                      </div>
                    </mat-list>
                  </mat-drawer>
                  <mat-drawer-content>
                    <div class="col-lg-7" style="margin: 0 auto">
                      <pie-chart
                        [generalAttendence]="studentAttendence"
                        totalLabel="{{ 'Stats.total' | translate }}"
                        presentLabel="{{ 'Stats.present' | translate }}"
                        absentLabel="{{ 'Stats.absent' | translate }}"
                      ></pie-chart>
                    </div>
                  </mat-drawer-content>
                </mat-drawer-container>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        {{ "Stats.evaluation" | translate }}
      </ng-template>
      <div style="position: absolute; top: 45%; left: 45%; z-index: 1">
        <mat-spinner *ngIf="!loading_eval"></mat-spinner>
      </div>
      <div *ngIf="loading_eval">
        <mat-tab-group mat-align-tabs="center">
          <mat-tab>
            <ng-template mat-tab-label>
              {{ "Stats.general" | translate }}
            </ng-template>
            <div style="overflow: hidden">
              <div class="row">
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['score_average']"
                      label="{{ 'Stats.score_average' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['content_of_training']"
                      label="{{ 'Stats.content_of_training' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['structure_of_training']"
                      label="{{ 'Stats.structure_of_training' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="
                        evaluations['activities_during_lesson']
                      "
                      label="{{ 'Stats.activities_during_lesson' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="
                        evaluations['variation_in_subject_matters']
                      "
                      label="{{
                        'Stats.variation_in_subject_matters' | translate
                      }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['pace_of_lessons']"
                      label="{{ 'Stats.pace_of_lessons' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['documents_received']"
                      label="{{ 'Stats.documents_received' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['specified_home_tasks']"
                      label="{{ 'Stats.specified_home_tasks' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="
                        evaluations['often_spoke_during_training']
                      "
                      label="{{
                        'Stats.often_spoke_during_training' | translate
                      }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                      total="4"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="
                        evaluations['organization_of_training']
                      "
                      label="{{ 'Stats.organization_of_training' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['meet_expectations']"
                      label="{{ 'Stats.meet_expectations' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                      totol="3"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
                <div class="col-lg-4">
                  <mat-card class="mb-4">
                    <app-eval-pie-chart
                      [generalAttendence]="evaluations['satisfied_with_result']"
                      label="{{ 'Stats.satisfied_with_result' | translate }}"
                      totalLabel="{{ 'Stats.total' | translate }}"
                      totla="3"
                    ></app-eval-pie-chart>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" style="margin: 0 auto">
                  <mat-card class="mb-4">
                    <p>{{ "Stats.attendence" | translate }}</p>
                    <pie-chart
                      [generalAttendence]="generalAttendence"
                      totalLabel="{{ 'Stats.total' | translate }}"
                      presentLabel="{{ 'Stats.present' | translate }}"
                      absentLabel="{{ 'Stats.absent' | translate }}"
                    ></pie-chart>
                  </mat-card>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              {{ "Stats.groups" | translate }}
            </ng-template>
            <div
              style="
                display: flex;
                justify-content: space-around;
                border-bottom: 1px solid lightgray;
              "
            >
              <mat-form-field appearance="fill" style="margin-top: 10px">
                <mat-label>Select Group</mat-label>
                <mat-select
                  [(value)]="evalGroupId"
                  (selectionChange)="showEvalGroup($event)"
                >
                  <div *ngFor="let key of evalGroup">
                    <mat-option [value]="key.id">{{ key.label }}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-tab-group mat-align-tabs="center">
              <mat-tab>
                <ng-template mat-tab-label>
                  {{ "Stats.group_attendence" | translate }}
                </ng-template>
                <div style="overflow: hidden">
                  <div class="row">
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="evalGroupData['score_average']"
                          label="{{ 'Stats.score_average' | translate }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['content_of_training']
                          "
                          label="{{ 'Stats.content_of_training' | translate }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['structure_of_training']
                          "
                          label="{{
                            'Stats.structure_of_training' | translate
                          }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['activities_during_lesson']
                          "
                          label="{{
                            'Stats.activities_during_lesson' | translate
                          }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['variation_in_subject_matters']
                          "
                          label="{{
                            'Stats.variation_in_subject_matters' | translate
                          }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="evalGroupData['pace_of_lessons']"
                          label="{{ 'Stats.pace_of_lessons' | translate }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['documents_received']
                          "
                          label="{{ 'Stats.documents_received' | translate }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['specified_home_tasks']
                          "
                          label="{{ 'Stats.specified_home_tasks' | translate }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['often_spoke_during_training']
                          "
                          label="{{
                            'Stats.often_spoke_during_training' | translate
                          }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                          total="4"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['organization_of_training']
                          "
                          label="{{
                            'Stats.organization_of_training' | translate
                          }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['meet_expectations']
                          "
                          label="{{ 'Stats.meet_expectations' | translate }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                          total="3"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                    <div class="col-lg-4">
                      <mat-card class="mb-4">
                        <app-eval-pie-chart
                          [generalAttendence]="
                            evalGroupData['satisfied_with_result']
                          "
                          label="{{
                            'Stats.satisfied_with_result' | translate
                          }}"
                          totalLabel="{{ 'Stats.total' | translate }}"
                          total="3"
                        ></app-eval-pie-chart>
                      </mat-card>
                    </div>
                  </div>
                  <div class="col-lg-4" style="margin: 0 auto">
                    <mat-card class="mb-4">
                      <p>{{ "Stats.attendence" | translate }}</p>
                      <pie-chart
                        [generalAttendence]="groupEvalAttendence"
                        totalLabel="{{ 'Stats.total' | translate }}"
                        presentLabel="{{ 'Stats.present' | translate }}"
                        absentLabel="{{ 'Stats.absent' | translate }}"
                      ></pie-chart>
                    </mat-card>
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  {{ "Stats.trainer" | translate }}
                </ng-template>
                <mat-tab-group mat-align-tabs="center">
                  <mat-tab label="General">
                    <div style="overflow: hidden">
                      <div class="row">
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerData['way_of_teaching']
                              "
                              label="{{ 'Stats.way_of_teaching' | translate }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerData['adjustment_level']
                              "
                              label="{{ 'Stats.adjustment_level' | translate }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerData['subject_knowledge']
                              "
                              label="{{
                                'Stats.subject_knowledge' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerData['contact_language_trainer']
                              "
                              label="{{
                                'Stats.contact_language_trainer' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerData['correction_of_mistakes']
                              "
                              label="{{
                                'Stats.correction_of_mistakes' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerData['use_of_teaching_materials']
                              "
                              label="{{
                                'Stats.use_of_teaching_materials' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-form-field
                        appearance="fill"
                        style="margin-top: 10px"
                      >
                        <mat-label>Select Trainer</mat-label>
                        <mat-select
                          [(value)]="evalTrainerDetailId"
                          (selectionChange)="showTrainer($event)"
                        >
                          <div *ngFor="let key of evalTrainerDetail">
                            <mat-option [value]="key.id">
                              {{ key.label }}
                            </mat-option>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </ng-template>
                    <div style="overflow: hidden">
                      <div class="row">
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerDetailData['way_of_teaching']
                              "
                              label="{{
                                'Stats.activities_during_lesson' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerDetailData['adjustment_level']
                              "
                              label="{{
                                'Stats.activities_during_lesson' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerDetailData['subject_knowledge']
                              "
                              label="{{
                                'Stats.activities_during_lesson' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerDetailData[
                                  'contact_language_trainer'
                                ]
                              "
                              label="{{
                                'Stats.activities_during_lesson' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerDetailData['correction_of_mistakes']
                              "
                              label="{{
                                'Stats.activities_during_lesson' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                        <div class="col-lg-4">
                          <mat-card class="mb-4">
                            <app-eval-pie-chart
                              [generalAttendence]="
                                evalTrainerDetailData[
                                  'use_of_teaching_materials'
                                ]
                              "
                              label="{{
                                'Stats.activities_during_lesson' | translate
                              }}"
                              totalLabel="{{ 'Stats.total' | translate }}"
                            ></app-eval-pie-chart>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
