<div class="container">
    <h3>{{ "LessonRescheduleForm.title" | translate }}</h3>
    <br />
    <form
      class="example-form"
      [formGroup]="rescheduleForm"
      (ngSubmit)="onSubmit(rescheduleForm.value)"
    >
      <mat-form-field class="example-full-width">
        <input
          matInput
          [ngxMatDatetimePicker]="picker1"
          placeholder="{{ 'LessonRescheduleForm.start' | translate }}"
          formControlName="start"
          [min]="minDate"
          [max]="maxDate"
          [disabled]="disabled"
        />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker1
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
          [enableMeridian]="enableMeridian"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <br />
      <span
        class="text-danger"
        *ngIf="
          rescheduleForm.get('start').touched &&
          rescheduleForm.get('start').hasError('required')
        "
      >
        {{ "LessonRescheduleForm.validations.start" | translate }}
      </span>
  
      <br />
      <mat-form-field class="example-full-width">
        <input
          matInput
          [ngxMatDatetimePicker]="picker2"
          placeholder="{{ 'LessonRescheduleForm.end' | translate }}"
          formControlName="end"
          [min]="minDate"
          [max]="maxDate"
          [disabled]="disabled"
        />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker2
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
          [enableMeridian]="enableMeridian"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
  
      <br />
      <span
        class="text-danger"
        *ngIf="
          rescheduleForm.get('end').touched &&
          rescheduleForm.get('end').hasError('required')
        "
      >
        {{ "LessonRescheduleForm.validations.end" | translate }}
      </span>
      <br />
      <br />
      <div mat-dialog-actions class="space">
        <button
          mat-raised-button
          type="submit"
          [disabled]="loading || rescheduleForm.invalid"
          class="button"
        >
          {{ "LessonRescheduleForm.saveBtn" | translate }}
        </button>
        <button mat-button mat-dialog-close color="warn">
          {{ "LessonRescheduleForm.cancelBtn" | translate }}
        </button>
      </div>
    </form>
  </div>
  