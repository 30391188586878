import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './interfaces';
import { languages } from './shared/language.translation';
import { TranslateService } from '@ngx-translate/core';
import { LocalLanguageService, AuthV2Service } from '@services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser: User;
  title: 'myapp';
  name = '';
  langs = languages;

  public options = {
    position: ['top', 'right'],
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: true,
    animate: 'scale',
    clickToClose: true,
    maxLength: 200,
    clickIconToClose: true,
  };

  constructor(
    private router: Router,
    private authenticationService: AuthV2Service,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
    private authService: AuthV2Service,
  ) {}
  ngOnInit() {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  routing(url): void {
    this.router.navigate([url]);
  }

  logout(): void {
    this.authenticationService.logout().subscribe(
      (res) => {
        this.router.navigate(['/auth/login']);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  public isAuth() {
    this.name = this.authenticationService.getAccessToken();
    if (this.name) {
      return this.name;
    }
    return false;
  }

  ngOnDestroy() {}
}
