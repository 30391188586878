<div style="z-index: 1; position: absolute; left: 45%; top: 40%">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />

  <div class="container">
    <br />
    <h2 class="pageTitle">{{ "TrainerInformation.title" | translate }}</h2>
    <br />
    <br />
    <form
      [formGroup]="trainerProfileForm"
      (ngSubmit)="updateProfileData(trainerProfileForm.value)"
    >
      <div class="form-group">
        <b>
          <label for="skill">{{
            "TrainerInformation.skillLabel" | translate
          }} <span class="star">*</span></label></b
        >

        <ng-multiselect-dropdown
          [placeholder]="'Select Skills'"
          [settings]="dropdownSettings"
          [data]="skills"
          class="selector"
          formControlName="skills"
        >
        </ng-multiselect-dropdown>

        <div
          class="alertError"
          *ngIf="
            !trainerProfileForm.controls['skills'].valid &&
            trainerProfileForm.controls['skills'].touched
          "
        >
          <div
            [hidden]="!trainerProfileForm.controls['skills'].errors.required"
          >
            Skills are required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <b
          ><label for="language">{{
            "TrainerInformation.languageLabel" | translate
          }} <span class="star">*</span></label></b
        >

        <ng-multiselect-dropdown
          [placeholder]="'Select Languages'"
          [settings]="dropdownSettingsForLanguages"
          [data]="languages"
          class="selector"
          formControlName="languages"
        >
        </ng-multiselect-dropdown>
        <div
          class="alertError"
          *ngIf="
            !trainerProfileForm.controls['languages'].valid &&
            trainerProfileForm.controls['languages'].touched
          "
        >
          <div
            [hidden]="!trainerProfileForm.controls['languages'].errors.required"
          >
            Languages are required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <b>
          <label for="city">{{
            "TrainerInformation.cityLabel" | translate
          }} <span class="star">*</span></label></b
        >

        <ng-multiselect-dropdown
          [placeholder]="'Select Cities'"
          [settings]="dropdownSettings"
          [data]="cities"
          class="selector"
          formControlName="cities"
        >
        </ng-multiselect-dropdown>
        <div
          class="alertError"
          *ngIf="
            !trainerProfileForm.controls['cities'].valid &&
            trainerProfileForm.controls['cities'].touched
          "
        >
          <div
            [hidden]="!trainerProfileForm.controls['cities'].errors.required"
          >
            Cities are required.
          </div>
        </div>
      </div>

      <div class="form-group">
        <b>
          <label for="transport">{{
            "TrainerInformation.transportLabel" | translate
          }} <span class="star">*</span></label></b
        >

        <ng-multiselect-dropdown
          [placeholder]="'Select Transport'"
          [settings]="dropdownSettings"
          [data]="transports"
          class="selector"
          formControlName="transports"
        >
        </ng-multiselect-dropdown>

        <div
          class="alertError"
          *ngIf="
            !trainerProfileForm.controls['transports'].valid &&
            trainerProfileForm.controls['transports'].touched
          "
        >
          <div
            [hidden]="
              !trainerProfileForm.controls['transports'].errors.required
            "
          >
            Transports are required.
          </div>
        </div>
      </div>

      <div class="form-group">
        <button
          type="submit"
          class="btn button"
          [disabled]="loading || !trainerProfileForm.valid"
        >
          {{ "TrainerInformation.submitBtn" | translate }}
        </button>

        <img
          *ngIf="loading"
          class="pl-3"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      </div>
    </form>
  </div>
</span>
