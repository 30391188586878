import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DayPilot } from 'daypilot-pro-angular';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { TrainerService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DataService {
  subscription = null;

  constructor(
    private http: HttpClient,
    private trainerService: TrainerService,
    public translate: TranslateService
  ) {}

  getEvents(from: DayPilot.Date, to: DayPilot.Date): Observable<any[]> {
    const f = moment(`${from}`, 'YYYY-MM-DD HH:mm ZZ').toISOString();
    const t = moment(`${to}`, 'YYYY-MM-DD HH:mm ZZ').toISOString();
    const check = [];
    let test = {
      id: null,
      lessonId: null,
      groupId: null,
      end: null,
      start: null,
      text: null,
      sId: null,
      additional: { color: '#f9cb9c' },
    };
    this.subscription = this.trainerService.getTrainerTimeSlot(
      `?from=${f}&&till=${t}&&per_page=0`
    );
    this.subscription.pipe(first()).subscribe((res) => {
      res.data.map((temp) => {
        test = {
          id: null,
          lessonId: null,
          groupId: null,
          end: null,
          start: null,
          text: null,
          sId: null,
          additional: { color: '#f9cb9c' },
        };
        test.id = temp.event_id;
        (test.lessonId = temp.event_type === 'lesson' ? temp.event.id : null),
          (test.groupId =
            temp.event_type === 'lesson' ? temp.event.group_id : null),
          (test.text =
            temp.event_type === 'lesson'
              ? this.translate.instant('Lesson')
              : this.translate.instant('TimeSlot')),
          (test.start = new DayPilot.Date(temp.event.start));
        test.end = new DayPilot.Date(temp.event.end);
        test.additional.color =
          temp.event_type === 'lesson' ? '#f9cb9c' : '#b6d7a8';
        test.sId = temp.event_type === 'timeslot' ? temp.event.student_id : null;
        check.push(test);
      });
    });

    // simulating an HTTP request
    return new Observable((observer) => {
      // setTimeout(() => {
      observer.next(check);
      // }, );
    });
  }
}
