import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class LessonCancelService {
  constructor(private http: HttpClient) {}

  peopleAbsent(lesson: number, data): Observable<any> {
    return this.http.post<any>(
      `${API.lessonCancelAbsent}/${lesson}/cancel`,
      data
    );
  }

  rescheduleLesson(lesson: number, data): Observable<any> {
    return this.http.post<any>(
      `${API.lessonDetail}/${lesson}/re-schedule`,
      data
    );
  }
}
