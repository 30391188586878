import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TokenModel, LoginModel } from '@models/index';
import { API } from './api.urls';

@Injectable({
  providedIn: 'root',
})
export class AuthV2Service {
  private readonly accessToken = 'ACCESS_TOKEN';
  private readonly refreshAuthToken = 'REFRESH_TOKEN';
  private readonly expireToken = 'EXPIRE_IN_TOKEN';
  private readonly tokenType = 'TYPE_TOKEN';
  private loggedUser: string;

  constructor(private http: HttpClient) { }

  login(user: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(`${API.login}`, user).pipe(
      tap((tokens) => this.doLoggedInUser(user.email, tokens)),
      map((data) => {
        return data;
      })
    );
  }

  logout() {
    return this.http.post<TokenModel>(`${API.logout}`, {}).pipe(
      map((data) => {
        this.doLogoutUser();
      }),
      catchError((error) => {
        return throwError('Capital not found!');
      })
    );
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  refreshToken(): Observable<TokenModel> {
    return this.http.post<any>(`${API.refresh}`, {}).pipe(
      tap((tokens: TokenModel) => {
        this.storeTokenValidates(tokens.access_token, tokens.expires_in);
      })
    );
  }

  getAccessToken() {
    return localStorage.getItem(this.accessToken);
  }

  private doLoggedInUser(email: string, tokens: TokenModel) {
    this.loggedUser = email;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.refreshAuthToken);
  }

  private storeTokenValidates(acctoken: string, expire: number) {
    localStorage.setItem(this.accessToken, acctoken);
    localStorage.setItem(this.expireToken, expire.toString());
  }

  private storeTokens(tokens: TokenModel) {
    localStorage.setItem(this.accessToken, tokens.access_token);
    localStorage.setItem(
      this.refreshAuthToken,
      tokens.refresh_expires_in.toString()
    );
    const expireTime = Date.now() + tokens.expires_in;
    localStorage.setItem(this.expireToken, expireTime + '');
    localStorage.setItem(this.tokenType, tokens.token_type);
  }

  public removeTokens() {
    localStorage.removeItem(this.accessToken);
    localStorage.removeItem(this.refreshAuthToken);
    localStorage.removeItem(this.tokenType);
    localStorage.removeItem(this.expireToken);
  }

  getTokenExpiry() {
    const timeToken = +localStorage.getItem(this.expireToken);
    const date = new Date().getTime() / 1000;
    if (timeToken > date) {
      return true;
    } else {
      // this.logout();
      this.removeTokens();
      return false;
    }
  }
}
