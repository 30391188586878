import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfirmedValidator } from '../../../shared/confirmPassword.validator';
import { PasswordResetService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { ChangePasswordModel } from '@models/index';
import { NotificationsService } from 'angular2-notifications';
import { Location } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass'],
})
export class ChangePasswordComponent implements OnInit {
  passwordChangeForm: FormGroup;
  loading = false;
  submitted = false;
  langs = languages;
  constructor(
    private formBuilder: FormBuilder,
    private passwordResetService: PasswordResetService,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.passwordChangeForm = this.formBuilder.group(
      {
        current_password: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirmation: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'password_confirmation'),
      },
    );

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }
  // language method
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.passwordChangeForm.controls;
  }

  goBack() {
    this.location.back();
  }

  onSubmit(values: ChangePasswordModel) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordChangeForm.invalid) {
      return;
    }

    this.loading = true;

    this.passwordResetService
      .resetPassword(values)
      .pipe(first())
      .subscribe(
        (data) => {
          this.notificationService.success(
            '200',
            'Successfully update your password!',
          );
          // this.router.navigate(['/auth/login']);
          this.loading = false;
        },
        (error) => {
          this.notificationService.error(
            error.status,
            error.status ? error.error.message : 'Something looks bad!',
          );
          this.loading = false;
        },
      );
  }
}
