import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  LocalLanguageService,
  StudentGroupingProposalService,
} from '@services/index';

export interface DialogTimeSlotData {
  id: string;
  language: string;
}

@Component({
  selector: 'app-decline-proposal',
  templateUrl: './decline-proposal.component.html',
  styleUrls: ['./decline-proposal.component.sass'],
})
export class DeclineProposalComponent implements OnInit {
  declineProposalForm: FormGroup;
  loading = false;
  submitted = false;
  langs = languages;
  status = '';

  constructor(
    public dialogRef: MatDialogRef<DeclineProposalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTimeSlotData,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private localLanguageService: LocalLanguageService,
    private proposalService: StudentGroupingProposalService
  ) {}

  ngOnInit(): void {
    this.declineProposalForm = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    this.useLanguage(this.data.language);
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.declineProposalForm.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(value) {
    this.submitted = true;
    this.loading = true;
    this.proposalService.declineGroup(+this.data.id, value).subscribe(
      (res: any) => {
        if (res) {
          this.loading = false;
          this.notificationService.success(
            200,
            'Successfully decline your proposal'
          );
        }
        this.loading = false;
        this.status = res.data.status;
        this.onNoClick();
      },
      (err) => {
        this.loading = false;
        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad'
        );
        this.onNoClick();
      }
    );
  }
}
