import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import {
  GroupInfoService,
  AuthV2Service,
  LocalLanguageService,
} from '@services/index';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-display-proposal',
  templateUrl: './display-proposal.component.html',
  styleUrls: ['./display-proposal.component.sass'],
  providers: [AuthV2Service],
  // encapsulation: ViewEncapsulation.None
})
export class DisplayProposalComponent implements OnInit {
  routeId: number = null;
  langs = languages;
  language = 'en';
  backGround = '#252E4B';
  color = '#ffffff';
  groups = [];
  lessons = [];
  lesson_count = 0;
  groupId = null;
  subscription = null;
  groupsLength = -1;
  @ViewChild('tabs') tabs: MatTabGroup;
  constructor(
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private groupInfo: GroupInfoService,
    private localLanguageService: LocalLanguageService,
  ) {}

  ngOnInit(): void {
    this.routeId = +this.activeRoute.snapshot.params.groupId;
    this.languageChangeHandler();

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.subscription = this.groupInfo.getAllGroups();
    this.subscription.subscribe((res) => {
      this.groups = res.data;
      if (Number.isNaN(this.routeId)) {
        this.groupId = res.data[0]?.id;
      } else {
        this.groupId = this.routeId;
      }
      if (this.groupId) {
        this.subscription = this.groupInfo
          .getLessons(this.groupId)
          .subscribe((response) => {
            this.lessons = response.data;
          });
        const reg = res.data.find((item) => item.id === this.groupId);
        this.subscription = this.groupInfo
          .getRegistration(reg.registration_id)
          .subscribe((response) => {
            this.lesson_count = response.data.lesson_count;
          });
      }
    });
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
    // this.languageChangeHandler();
  }

  private languageChangeHandler(): void {
    this.subscription = this.groupInfo.getAllGroups();
    this.subscription.subscribe((res) => {
      this.groups = res.data;
      this.groupsLength = res.data.length;
      if (Number.isNaN(this.routeId)) {
        this.groupId = res.data[0]?.id;
      } else {
        this.groupId = this.routeId;
      }
      if (this.groupId) {
        this.subscription = this.groupInfo
          .getLessons(this.groupId)
          .subscribe((response) => {
            this.lessons = response.data;
          });
      }
    });
  }

  checkLessons(): boolean {
    if (this.lesson_count <= 0) {
      return true;
    }
    const activeLessonsCount = this.lessons.filter(
      (x) => x.lesson_end != null,
    ).length;

    let completionPercentage = Math.round(
      (activeLessonsCount / this.lesson_count) * 100,
    );
    return completionPercentage < 80;
  }

  showInfo(val): void {
    this.groupId = val;
    const reg = this.groups.find((item) => item.id === val);
    this.subscription = this.groupInfo
      .getRegistration(reg.registration_id)
      .subscribe((response) => {
        this.lesson_count = response.data.lesson_count;
      });
    this.subscription = this.groupInfo
      .getLessons(this.groupId)
      .subscribe((res) => {
        this.lessons = res.data;
      });
    this.checkLessons();
    this.tabs.selectedIndex = 0;
  }
}
