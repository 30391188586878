import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalLanguageService {
  public localLanguage = 'en';
  constructor() {}

  public getLocalLanguage() {
    return this.localLanguage;
  }

  public setLocalLanguage(lang: string) {
    this.localLanguage = lang;
  }
}
