import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainerService, LocalLanguageService } from '@services/index';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-evaluations-feedback',
  templateUrl: './evaluations-feedback.component.html',
  styleUrls: ['./evaluations-feedback.component.sass'],
})
export class EvaluationsFeedbackComponent implements OnInit {
  evaluationFeedbackform: FormGroup;
  gId = null;
  subscription = null;
  grId = 0;
  langs = languages;
  language: string;
  averageScore = 0;
  noFeedbacks = false;
  spinnerLoading = true;
  messageEvaluations = '';

  constructor(
    private activeRoute: ActivatedRoute,
    private trainerService: TrainerService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private localLanguageService: LocalLanguageService,
    private notificationService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.languageChangeHandler();

    this.evaluationFeedbackform = this.formBuilder.group({
      way_of_teaching: new FormControl({ value: 0, disabled: true }),
      adjustment_level: new FormControl({ value: 0, disabled: true }),
      subject_knowledge: new FormControl({ value: 0, disabled: true }),
      contact_language_trainer: new FormControl({ value: 0, disabled: true }),
      correction_of_mistakes: new FormControl({ value: 0, disabled: true }),
      use_of_teaching_materials: new FormControl({ value: 0, disabled: true }),
      score_average: new FormControl({ value: 0, disabled: true }),
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.subscription = this.trainerService.trainerFetchEvaluations();
    this.subscription
      .pipe(finalize(() => (this.spinnerLoading = false)))
      .subscribe(
        (res) => {
          if (res.score_average === 0) {
            switch (this.language) {
              case 'en':
                this.messageEvaluations = 'Currently there is not any record.';
                break;
              case 'nl':
                this.messageEvaluations = 'Momenteel is er geen record.';
                break;
              case 'fr':
                this.messageEvaluations = `Actuellement, il n'y a aucun enregistrement.`;
                break;
              case 'de':
                this.messageEvaluations =
                  'Derzeit gibt es keine Aufzeichnungen.';
                break;

              default:
                this.messageEvaluations = 'Currently there is not any record.';
                break;
            }
            this.notificationService.info(this.messageEvaluations);
          }

          this.noFeedbacks = false;
          this.evaluationFeedbackform.controls.way_of_teaching.setValue(
            Math.round(+res.way_of_teaching),
          );
          this.evaluationFeedbackform.controls.adjustment_level.setValue(
            Math.round(+res.adjustment_level),
          );
          this.evaluationFeedbackform.controls.subject_knowledge.setValue(
            Math.round(+res.subject_knowledge),
          );
          this.evaluationFeedbackform.controls.contact_language_trainer.setValue(
            Math.round(+res.contact_language_trainer),
          );
          this.evaluationFeedbackform.controls.correction_of_mistakes.setValue(
            Math.round(+res.correction_of_mistakes),
          );
          this.evaluationFeedbackform.controls.use_of_teaching_materials.setValue(
            Math.round(+res.use_of_teaching_materials),
          );
          this.evaluationFeedbackform.controls.score_average.setValue(
            Math.round(res.score_average),
          );
          this.averageScore = res.score_average;
        },
        (error) => {
          this.notificationService.error(
            error.status,
            error.error && error.error.message
              ? error.error.message
              : 'No Data found',
          );
        },
      );
  }

  getlocale() {
    return this.translate.currentLang || this.translate.defaultLang || 'en';
  }
}
