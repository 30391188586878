<div class="container">
  <br />
  <h1 mat-dialog-title class="title">Are you sure to delete this</h1>
  <div mat-dialog-content class="icon">
    <mat-icon color="warn">delete</mat-icon>
  </div>
  <div mat-dialog-actions class="space">
    <button mat-button mat-dialog-close class="button" (click)="onSubmit()">Yes</button>
    <button mat-button mat-dialog-close (click)="(closeDialog)">Close</button>
  </div>
</div>
