import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API } from './api.urls';

@Injectable({
  providedIn: 'root',
})
export class StudentEvaluationService {
  constructor(private http: HttpClient) {}

  getEvaluation(): Observable<[any]> {
    return this.http.get<[any]>(`${API.getEvaluation}`);
  }

  // UpdateEvaluation

  updateEvaluation(evaluationData) {
    return this.http.put<any>(`${API.updateEvaluation}`, evaluationData);
  }

  // Create Evaluation

  createEvaluation(evaluationData) {
    return this.http.post(`${API.createEvaluation}`, evaluationData);
  }
}
