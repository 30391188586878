<div class="wrapper">
  <div class="left">
    <daypilot-navigator
      [config]="navigatorConfig"
      [(date)]="config.startDate"
      #navigator
    ></daypilot-navigator>
  </div>
  <div class="main">
    <div
      class="buttons"
      style="display: flex; flex-wrap: wrap; flex-direction: row"
    >
      <div style="flex: 0 0 auto">
        <a href="#" (click)="navigatePrevious($event)">Previous</a>
        <a href="#" (click)="navigateToday($event)">Today</a>
        <a href="#" (click)="navigateNext($event)">Next</a>
      </div>
      <div style="flex: 0 0 auto; margin-left: auto; display: inline-block">
        <label>
          <mat-icon class="translateIcon">g_translate</mat-icon
          >{{ "" | translate }}
          <select
            #langSelect
            (change)="useLanguage(langSelect.value)"
            class="translateSelector"
          >
            <option
              *ngFor="let lang of langs"
              [value]="lang['code']"
              [selected]="lang['code'] == translate.currentLang"
            >
              {{ lang["name"] }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <daypilot-calendar
      [config]="config"
      [events]="events"
      #calendar
      (viewChange)="viewChange()"
    ></daypilot-calendar>
  </div>
</div>
