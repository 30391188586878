<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />

  <div class="container">
    <br />
    <mat-card>
      <br />
      <h3>{{ "DisplayContract.title" | translate }}</h3>
      <br />
      <br />
      <mat-accordion>
        <form>
          <mat-expansion-panel class="layoutBack">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>{{ "DisplayContract.contractTitle" | translate }}</b>
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <br />
            <br />

            <div class="form-group alignContent">
              <b>{{ "DisplayContract.codeModuleLabel" | translate }}</b> &nbsp;
              <p class="comment">{{ codeModule }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{ "DisplayContract.statusLabel" | translate }}</b> &nbsp;
              <p class="comment">{{ status }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{ "DisplayContract.lessonFeePerHourLabel" | translate }}</b>
              &nbsp;
              <p class="comment">{{ lessonFeePerHour }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{ "DisplayContract.addtionalFeesLabel" | translate }}</b>
              &nbsp;
              <p class="comment">{{ addtionalFees }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{ "DisplayContract.travelExpenseLabel" | translate }}</b>
              &nbsp;
              <p class="comment">{{ travelExpense }}</p>
            </div>
            <div class="form-group alignContent" *ngIf="signedDoc">
              <a class="btn button" href="{{ signedDoc }}" target="_blank">{{
                "DisplayContract.signedDocLabel" | translate
              }}</a>
            </div>

            <div class="form-group">
              <b>{{ "DisplayContract.commentsLabel" | translate }}</b> &nbsp;
              <br/>
              <p class="comment">{{ comments }}</p>
            </div>
          </mat-expansion-panel>
          <br />
          <mat-expansion-panel class="layoutBack">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>{{ "DisplayContract.courseTitle" | translate }}</b>
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <br />
            <br />

            <div class="form-group alignContent">
              <b>{{ "DisplayContract.studentsLabel" | translate }}</b> &nbsp;
              <p class="comment">{{ students }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{ "DisplayContract.teachingHoursLabel" | translate }}</b>
              &nbsp;
              <p class="comment">{{ teachingHours }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{
                "DisplayContract.lessonsDaysAndTimesLabel" | translate
              }}</b>
              &nbsp;
              <p class="comment">{{ lessonsDaysAndTimes }}</p>
            </div>
            <div class="form-group alignContent">
              <b>{{ "DisplayContract.startDateLabel" | translate }}</b> &nbsp;
              <p class="comment"> {{ startDate }}</p>
            </div>
            <div class="form-group">
              <b>{{ "DisplayContract.progressReportLabel" | translate }}</b>
              <br/>
              <p class="comment">{{ progressReport }}</p>
            </div>
            <div class="form-group">
              <b>{{
                "DisplayContract.postLanguageTrainingLabel" | translate
              }}</b>
             <br/>
             <p class="comment">{{ postLanguageTraining }}</p>
            </div>
            <br />
            <br />

            <br />
            <br />
          </mat-expansion-panel>
        </form>
        <br />
        <br />
        <div class="buttonLayout">
          <button
            mat-raised-button
            [disabled]="statusDisable"
            class="button"
            (click)="routeNavAccept()"
          >
            {{ "DisplayContract.acceptBtn" | translate }}
          </button>
          <button
            mat-raised-button
            [disabled]="statusDisable"
            color="warn"
            (click)="openDialog()"
          >
            {{ "DisplayContract.declineBtn" | translate }}
          </button>
        </div>
      </mat-accordion>
    </mat-card>
  </div>
</span>
