<div class="container">
  <h4>{{ "AddtionalUpdateForm.title" | translate }}</h4>
  <div style="display: flex">
    <mat-checkbox
      style="margin: 0 auto"
      [checked]="!file"
      (change)="change()"
      >{{ "AddtionalUpdateForm.addFileLabel" | translate }}</mat-checkbox
    >
    <mat-checkbox style="margin: 0 auto" [checked]="file" (change)="change()">{{
      "AddtionalUpdateForm.addContentLabel" | translate
    }}</mat-checkbox>
  </div>
  <form
    class="example-form"
    [formGroup]="addtionalInfoForm"
    (ngSubmit)="onSubmit(addtionalInfoForm.value)"
  >
    <br />
    <div class="example-full-width" *ngIf="file">
      <b>
        <label>{{ "AddtionalUpdateForm.titleLabel" | translate }}<span class="star"> *</span></label></b
      >
      <br />
      <input
        type="text"
        class="example-full-width input"
        matInput
        placeholder="Enter title......"
        formControlName="title"
      />
      <div
      class="alert"
      *ngIf="
        !addtionalInfoForm.controls['title'].valid &&
        addtionalInfoForm.controls['title'].touched
      "
    >
      <div [hidden]="!addtionalInfoForm.controls['title'].errors.required">
        {{ "validations.titleReq" | translate }}
      </div>
    </div>
    </div>
    <br />
    <div class="example-full-width" *ngIf="file">
      <b
        ><label>{{ "AddtionalUpdateForm.commentLabel" | translate }}<span class="star"> *</span></label></b
      >
      <br />

      <textarea
        type="text"
        class="textareaTag"
        matInput
        placeholder="Enter content....."
        formControlName="content"
      ></textarea>
      <div
        class="alert"
        *ngIf="
          !addtionalInfoForm.controls['content'].valid &&
          addtionalInfoForm.controls['content'].touched
        "
      >
        <div [hidden]="!addtionalInfoForm.controls['content'].errors.required">
          {{ "validations.contentReq" | translate }}
        </div>
      </div>
    </div>
    <br />
    <div class="example-full-width" *ngIf="!file">
      <b>
        <label>{{ "AddtionalUpdateForm.fileLabel" | translate }}<span class="star"> *</span></label></b
      >
      <br />
      <input
        class="example-full-width"
        type="file"
        placeholder="Choose a file"
        (change)="handleFileInput($event.target.files)"
      />
    </div>
    <div mat-dialog-actions class="space">
      <button
        *ngIf="file"
        mat-raised-button
        type="submit"
        class="button"
        [disabled]="loading || addtionalInfoForm.invalid"
      >
        {{ "AddtionalUpdateForm.saveBtn" | translate }}
      </button>
      <button *ngIf="file" mat-button mat-dialog-close (click)="(closeDialog)">
        {{ "AddtionalUpdateForm.cancelBtn" | translate }}
      </button>
    </div>
  </form>
</div>
