import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { TrainerService, LocalLanguageService } from '@services/index';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-trainer-information',
  templateUrl: './trainer-information.component.html',
  styleUrls: ['./trainer-information.component.sass'],
})
export class TrainerInformationComponent implements OnInit {
  trainerProfileForm: FormGroup;
  loading = false;
  dropdownList = [];
  lastSkills = [];
  lastlanguages = [];
  lastTransports = [];
  lastCities = [];
  submitted = false;
  public skills = [];
  public transports = [];
  public languages = [];
  public cities = [];
  profile: any;
  // data: any;
  // id: any;
  subscription = null;
  selectedItems = [];
  dropdownSettings = {};
  dropdownLanguageSettings = {};
  dropdownSettingsForLanguages = {};
  langs = languages;
  spinerLoading = true;
  constructor(
    private formBuilder: FormBuilder,
    private trainerService: TrainerService,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private localLanguageService: LocalLanguageService,
  ) {
    this.trainerProfileForm = this.formBuilder.group({
      skills: ['', Validators.required],
      transports: ['', Validators.required],
      languages: ['', Validators.required],
      cities: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.languageChangeHandler();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      maxHeight: 100,
      minWidth: 400,
    };

    this.dropdownSettingsForLanguages = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      maxHeight: 100,
      minWidth: 400,
    };

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.spinerLoading = true;
    this.fetchSkills();
    this.fetchCities();
    this.fetchLanguages();
    this.fetchTransports();

    this.subscription = this.trainerService.getTrainerDetails();
    this.subscription.subscribe(
      (res) => {
        this.profile = res;
        this.trainerProfileForm.controls.skills.setValue(res.data.skills);
        this.trainerProfileForm.controls.transports.setValue(
          res.data.transports,
        );
        this.trainerProfileForm.controls.languages.setValue(res.data.languages);
        this.trainerProfileForm.controls.cities.setValue(res.data.cities);
        this.spinerLoading = false;
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Something Bad',
        );
        //  / this.loading = false;
      },
    );
  }

  updateProfileData(values) {
    this.loading = true;
    this.submitted = true;

    const skills = values.skills.map((data) => data.id);

    const cities = values.cities.map((data) => data.id);

    const languagesData = values.languages.map((data) => data.id);

    const transports = values.transports.map((data) => data.id);

    values.skills = skills;
    values.transports = transports;
    values.cities = cities;
    values.languages = languagesData;

    this.trainerService.updateTrainer(values).subscribe(
      (result) => {
        this.notificationService.success(200, 'Data Save SuccessFull!');
        this.loading = false;
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Something Bad',
        );

        this.loading = false;
      },
    );
  }

  fetchSkills() {
    this.trainerService.getskills().subscribe((data) => {
      this.skills = data['data'].map((e) => ({
        id: e.id,
        label: e.label,
      }));
    });
  }

  fetchTransports() {
    this.trainerService.getTransports().subscribe((data) => {
      this.transports = data['data'].map((e) => ({
        id: e.id,
        label: e.label,
      }));
    });
  }

  fetchCities() {
    this.trainerService.getCities().subscribe((data) => {
      // this.cities = Object.keys(data).map((key) => [Number(key), data[key]]);
      this.cities = data['data'].map((e) => ({
        id: e.id,
        label: e.label,
      }));
    });
  }

  fetchLanguages() {
    this.trainerService.getlanguages().subscribe((data) => {
      // this.cities = Object.keys(data).map((key) => [Number(key), data[key]]);
      this.languages = data['data'].map((e) => ({
        id: e.id,
        name: e.name,
      }));
    });
  }

  get f() {
    return this.trainerProfileForm.controls;
  }

  getSkills(data): void {
    const tmp = [];
    for (let i = 0; i < data.length; i++) {
      tmp.push({ item_id: i, item_text: data[i].name });
    }
    this.lastSkills = tmp;
  }

  getLanguages(data): void {
    const tmp = [];
    for (let i = 0; i < data.length; i++) {
      tmp.push({ item_id: i, item_text: data[i].name });
    }
    this.lastlanguages = tmp;
  }

  getTransports(data): void {
    const tmp = [];
    for (let i = 0; i < data.length; i++) {
      tmp.push({ item_id: i, item_text: data[i].name });
    }
    this.lastTransports = tmp;
  }

  getCities(data): void {
    const tmp = [];
    for (let i = 0; i < data.length; i++) {
      tmp.push({ item_id: i, item_text: data[i].name });
    }
    this.lastCities = tmp;
  }
}
