import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  CourseRegisterService,
  LocalLanguageService,
  HelperService,
} from '@services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.sass'],
})
export class AcceptComponent implements OnInit {
  document = '';
  registerId = 0;
  registrationAcceptForm: FormGroup;
  fileUrl = '';
  loading = true;
  file = null;
  buttonDisable = false;
  showDownload = false;
  uploadFileResponse = { status: '', message: '', filePath: '' };
  subscription: any;
  langs = languages;

  constructor(
    private router: Router,
    private courseRegistration: CourseRegisterService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
    private helperService: HelperService,
  ) {}

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.registrationAcceptForm.get('file').setValue(file);
    }
  }

  ngOnInit(): void {
    this.registerId = +this.activeRoute.snapshot.params.id;

    this.fileUrl = `${environment.apiURL}/api/customer/registration/${this.registerId}/download-document`;

    this.registrationAcceptForm = this.formBuilder.group({
      file: [''],
    });
    this.languageChangeHandler();

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }
  downloadFIle() {
    this.loading = true;
    this.courseRegistration.getRegistrationFile(this.registerId).subscribe(
      (res) => {
        this.helperService.makeAndDownLoadFile(res, 'Registration.pdf');
        this.loading = false;
        this.notificationService.success('200', 'File recieved!');
      },
      (err) => {
        this.loading = false;
        this.notificationService.error(err.status, 'File Not Found!');
      },
    );
  }
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.subscription = this.courseRegistration.getRegistration(
      this.registerId,
    );
    this.subscription.subscribe(
      (res) => {
        if (res?.data?.status === 'accepted') {
          this.buttonDisable = true;
        }
        this.loading = false;
      },
      (error) => {
        if (error.status === 403) {
          this.router.navigate(['/unauthorized']);
        }
      },
    );
  }

  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', this.registrationAcceptForm.get('file').value);

    this.courseRegistration
      .acceptRegistration(this.registerId, formData)
      .subscribe(
        (events) => {
          this.showDownload = true;
          this.uploadFileResponse = events;

          if (events.data) {
            this.notificationService.success(
              '200',
              'Successfully, Uploaded your document!',
            );
            this.buttonDisable = true;
            this.loading = false;
          }
        },
        (err) => {
          this.notificationService.error(err.status, err.error.message);
          this.loading = false;
        },
      );
  }
}
