<div class="container">
  <h2 class="example-h2">{{ "DeclineGroupProposal.title" | translate }}</h2>

  <form
    [formGroup]="declineProposalForm"
    (ngSubmit)="onSubmit(declineProposalForm.value)"
  >
    <div class="form-group">
      <label for="message">{{
        "DeclineGroupProposal.label" | translate
      }}</label>
      <textarea
        #input
        type="text"
        formControlName="message"
        class="form-control"
        required
        maxlength="256"
        minlength="3"
        [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
      >
      </textarea>
      <small
        >{{ "validations.messageLength" | translate
        }}{{ input.value?.length || 0 }} / 256</small
      >
      <br />
      <div
        class="invalid-feedback"
        *ngIf="
          !declineProposalForm.controls['message'].valid &&
          declineProposalForm.controls['message'].touched
        "
      >
        <div
          [hidden]="!declineProposalForm.controls['message'].errors.required"
        >
          {{ "validations.messageReq" | translate }}
        </div>
        <div
          [hidden]="!declineProposalForm.controls['message'].errors.maxLength"
        >
          Message should be under the 256 digits
        </div>
      </div>
    </div>

    <button
      type="submit"
      [disabled]="loading || declineProposalForm.invalid || status"
      class="btn button"
    >
      {{ "DeclineGroupProposal.submitBtn" | translate }}
    </button>

    <img
      *ngIf="loading"
      class="pl-3"
      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
    />
  </form>
  <button mat-button (click)="onNoClick()" color="warn">
    {{ "DeclineContract.cancelBtn" | translate }}
  </button>
  <br />
</div>
