import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from '../common/delete-confirmation/delete-confirmation.component';
import { AddtionalInfoUpdateFormComponent } from '../common/addtional-info-update-form/addtional-info-update-form.component';
import {
  GroupInfoService,
  AuthV2Service,
  LocalLanguageService,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';

@Component({
  selector: 'app-addtional-info',
  templateUrl: './addtional-info.component.html',
  styleUrls: ['./addtional-info.component.sass'],
  providers: [AuthV2Service],
})
export class AddtionalInfoComponent implements OnInit, OnChanges {
  @Input() groupId: number;
  @Input() language: string;
  langs = languages;
  files = [];
  loading = true;
  subscription = null;
  // language: string;
  addtionalData = {};
  gId: number = null;

  constructor(
    private router: Router,
    private groupInfo: GroupInfoService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {}
  ngOnInit(): void {}

  openDeleteConfirmator(key) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        additionalInfoId: key.id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.request();
    });
  }

  request() {
    if (this.gId != null) {
      this.loading = true;
      this.subscription = this.groupInfo.getGroupAdditionalInfo(this.gId);
      this.subscription.subscribe((res) => {
        this.files = res.data;
        this.loading = false;
      });
    }
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('groupId')) {
      if (changes.hasOwnProperty('groupId')) {
        if (changes.groupId.currentValue != null) {
          this.gId = changes.groupId.currentValue;
        }
      }
      if (this.gId != null) {
        this.loading = true;
        this.subscription = this.groupInfo.getGroupAdditionalInfo(this.gId);
        this.subscription.subscribe((res) => {
          this.files = res.data;
          this.loading = false;
        });
      }
    }
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
  }

  checkIfFile(val) {}

  createHandler() {
    this.addtionalData = null;
    this.openAddtionalInfoForm();
  }

  editHandler(value) {
    this.addtionalData = value;
    this.openAddtionalInfoForm();
  }

  openAddtionalInfoForm() {
    let dataForm = {};
    if (this.addtionalData == null) {
      dataForm = {};
    } else {
      dataForm = this.addtionalData;
    }
    const dialogRef = this.dialog.open(AddtionalInfoUpdateFormComponent, {
      width: '50%',
      data: {
        id: 1,
        language: this.language,
        groupId: this.gId,
        addtionalDetails: dataForm,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.request();
    });
  }
}
