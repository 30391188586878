import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { CancelPlanningComponent } from '../cancel-planning/cancel-planning.component';
import { MakingPlanningService, LocalLanguageService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';
export interface Contract {
  status: string;
  postLanguageTraining: string;
  comments: string;
  codeModule: string;
  students: number;
  teachingHours: number;
  lessonsDaysAndTimes: number;
  startDate: string;
  lessonFeePerHour: number;
  addtionalFees: number;
  progressReport: string;
  travelExpense: number;
  signedDoc: string;
}
@Component({
  selector: 'app-display-planning',
  templateUrl: './display-planning.component.html',
  styleUrls: ['./display-planning.component.sass'],
})
export class DisplayPlanningComponent implements OnInit {
  groupId = 0;
  contractId = 0;
  langs = languages;
  language = 'en';
  // : Contract;
  statusDisable = false;
  status: string;
  postLanguageTraining: string;
  comments: string;
  codeModule: string;
  students: number;
  teachingHours: number;
  lessonsDaysAndTimes: number;
  startDate: string;
  lessonFeePerHour: number;
  addtionalFees: number;
  progressReport: string;
  travelExpense: number;
  signedDoc: string;
  spinerLoading = true;
  token: string;

  constructor(
    private route: Router,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private notificationService: NotificationsService,
    private planningService: MakingPlanningService,
    private localLanguageService: LocalLanguageService
  ) {
    this.groupId = +this.activeRoute.snapshot.params?.groupId;
    this.contractId = +this.activeRoute.snapshot.params?.contractId;
  }

  ngOnInit(): void {
    this.languageChangeHander();
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.language = lang;
    this.translate.use(lang);
    this.languageChangeHander();
  }
  languageChangeHander() {
    this.planningService.getPlanningContract(this.groupId, this.contractId).subscribe(
      (res) => {
        this.codeModule = res.data.code_module;
        this.status = res.data.status;
        this.lessonFeePerHour = res.data.lesson_fee_per_hour;
        this.addtionalFees = res.data.additional_fees;
        this.travelExpense = res.data.travel_expenses;
        this.comments = res.data.comments;
        this.signedDoc = res.data.signed_doc;
        this.startDate = res.data.start_date;
        this.students = res.data.students;
        this.teachingHours = res.data.teaching_hours;
        this.lessonsDaysAndTimes = res.data.lesson_days_and_times;
        this.progressReport = res.data.progress_report;
        this.postLanguageTraining = res.data.post_language_training;
        this.spinerLoading = false;
        if (
          res.data.status === 'Accepted' ||
          res.data.status === 'Declined' ||
          res.data.status === 'Prepare'
        ) {
          this.statusDisable = true;
        }
      },
      (error) => {
        if(error.status === 403) {
          this.route.navigate(['/unauthorized']);
        }
        this.notificationService.error(
          error.status,
          error && error.error && error.error.message
            ? error.error.message
            : 'SomeThing Bad'
        );
        this.spinerLoading = false;
      }
    );
  }
  routeNavAccept() {
    this.route.navigate([`/trainer/group/${this.groupId}/contract/${this.contractId}/accept`]);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CancelPlanningComponent, {
      width: '50%',
      height: '50%',
      data: { groupId: this.groupId, contractId: this.contractId, language: this.language },
    });
    dialogRef.afterClosed().subscribe((result) => this.statusDisable = result);
  }
}
