import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class EmailVerifyService {
  constructor(private http: HttpClient) {}

  headers = new HttpHeaders({
    Authorization: `Bearer ${this.getToken()}`,
  });
  options = { headers: this.headers };

  getToken() {
    return localStorage.getItem('ACCESS_TOKEN');
  }

  emailVerify(url: string): Observable<any> {
    return this.http.post<any>(url, {}, this.options);
  }

  trainerVerifyEmail() {
    return this.http.post<any>(`${API.resendVerifyEmail}`, {});
  }
}
