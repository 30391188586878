<br />
<div class="container">
  <form [formGroup]="timeslotForm" (ngSubmit)="onSubmit(timeslotForm.value)">
    <h4>{{ "DeclineContract.title" | translate }}</h4>
    <div class="form-group">

      <label for="message">{{
        "DeclineContract.messageLabel" | translate
      }}</label>

      <textarea
        #input
        type="text"
        formControlName="message"
        class="form-control"
        required
        maxlength="256"
        minlength="3"
        [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
      ></textarea>
      <small
        >{{ "validations.messageLength" | translate
        }}{{ input.value?.length || 0 }} / 3-256</small
      >
      <span
        class="text-danger"
        *ngIf="
          timeslotForm.get('message').touched &&
          timeslotForm.get('message').hasError('required')
        "
      >
        {{ "DeclineContract.validations" | translate }}
      </span>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn button"
        [disabled]="loading || buttonDisable"
      >
        {{ "DeclineContract.submitBtn" | translate }}
      </button>
      <button mat-button (click)="onNoClick(false)">
        {{ "DeclineContract.cancelBtn" | translate }}
      </button>
    </div>
  </form>
  <br/>
  <br/>
</div>
