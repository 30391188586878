import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CourseRegisterService, LocalLanguageService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import { currency, vat } from '../../../shared/constant';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.sass'],
})

export class DisplayComponent implements OnInit {
  displayForm: FormGroup;
  studentData: FormGroup;
  termsConditions = 'Loading....';
  subscription: any;
  registerId = 0;
  multi = true;
  data = null;
  priceData: any;
  payment = [];
  paymentMethod = '';
  offerId = '';
  fileCosts = '';
  langs = languages;
  courseMaterialCosts = '';
  totalAmount = '';
  frequencey = null;
  individual = false;
  orgainization = false;
  parentCase = false;
  termTitle: string;
  isCompany = false;
  checkRegistraion = '';
  token: string;
  status = '';
  spinerLoading = true;
  registrationComments = '';
  remote = false;
  totalTravelCost = null;
  trainingPlace = '';
  lessonCount = null;
  currenySign = currency.euro;
  vatValue = +vat;
  includeVatTotal = null;
  travelCost = null;
  withDossierFollowup = null;
  withScreening = null;
  withTerminology = null;
  withoutIntake = null;

  page = 0;
  size: number;
  studentsLength = 0;
  pageSize = 10;


  constructor(
    private route: Router,
    private courseRegistration: CourseRegisterService,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
    private notificationService: NotificationsService
  ) {
    this.displayForm = this.formBuilder.group({
      language: [''],
      content: [''],
      frequency: [''],
      rate: [''],
      date: [''],
      persons: [''],
      availabilty: [''],
      startDate: [''],
      endDate: [''],
      place_traning: [''],
      // Subscriber Information
      subscriber_first_name: [''],
      subscriber_last_name: [''],

      phone: [''],
      // gsm: [''],
      email: [''],
      // Customer Information
      customer_first_name: [''],
      customer_last_name: [''],

      customer_phone: [''],
      customer_email: [''],
      company_first_name: [''],
      company_last_name: [''],

      address: [''],
      vat: [''],
      coc_number: [''],
    });
  }

  ngOnInit(): void {
    this.registerId = +this.activeRoute.snapshot.params.id;
    this.activeRoute.queryParams.subscribe((params) => {
      this.token = params.token;
    });

    this.languageChangeHandler();
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }

    this.getStudents();
  }

  getStudents(page = 1) {
    const pageSize = 10;
    this.page = page ? page : 1;
    this.subscription = this.courseRegistration.getRegistrationStudents(
      this.registerId,
      pageSize,
      this.page
    );
    this.subscription.subscribe((res) => {
      // Assign Students details which are displaying in edit case.
      this.data = res.data;
      this.studentsLength = res.meta.total;
      this.page = res.meta.current_page;
    });
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.subscription = this.courseRegistration.getRegistration(
      this.registerId
    );
    this.subscription.subscribe(
      (res) => {
        if (
          res.data.status === 'accepted' ||
          res.data.status === 'declined' ||
          res.data.status === 'prepare'
        ) {
          this.status = res.data.status;
        }

        this.termTitle = res.data.term.title;
        this.termsConditions = res.data.term.content;
        this.displayForm.controls.language.setValue(
          res.data.type.language.name
        );
        this.checkRegistraion = res.data.lesson_price;
        this.displayForm.controls.rate.setValue(res.data.lesson_price); // this.displayForm.controls['laptop'].setValue(;
        this.displayForm.controls.persons.setValue(
          res.data.student_count
        );
        this.registrationComments = res.data.remarks || '';
        this.paymentMethod = res.data.payment_method;
        this.offerId = res.data.quotation_item_id;
        this.isCompany = res.data.customer.is_company;
        this.displayForm.controls.frequency.setValue(
          res.data.frequency
        );
        this.displayForm.controls.startDate.setValue(res.data.start_date);
        this.displayForm.controls.endDate.setValue(res.data.end_date);

        if (res.data.remote) {
          this.trainingPlace = 'At a distance';
        }

        if (res.data.at_client) {
          this.trainingPlace = 'Face-to-face with the customer';
        }

        if (!res.data.at_client && !res.data.remote) {
          this.trainingPlace = 'Face-to-face at Agataal';
        }

        this.displayForm.controls.place_traning.setValue(this.trainingPlace);
        // if (!this.offerId) {

        this.fileCosts = res.data.total_dossier_cost;
        this.totalAmount = res.data.price_total;
        this.courseMaterialCosts = res.data.total_course_material_cost;
        this.remote = res.data.remote;
        this.totalTravelCost = res.data.total_travel_cost;
        this.travelCost = res.data.travel_cost_price;
        this.lessonCount = res.data.lesson_count;
        this.withDossierFollowup = res.data.with_dossier_followup;
        this.withScreening = res.data.with_screening;
        this.withTerminology = res.data.with_terminology;
        this.withoutIntake = res.data.without_intake;
        const vatCalculate = (+this.totalAmount * this.vatValue) / 100;
        this.includeVatTotal = +this.totalAmount + vatCalculate;
        this.displayForm.controls.subscriber_first_name.setValue(
          res.data.contact
            ? res.data.contact
              ? res.data.contact.first_name
              : ''
            : 0
        );

        this.displayForm.controls.subscriber_last_name.setValue(
          res.data.contact
            ? res.data.contact
              ? res.data.contact.last_name
              : ''
            : 0
        );

        this.displayForm.controls.phone.setValue(
          res.data.contact
            ? res.data.contact
              ? res.data.contact.phone
              : ''
            : ''
        );

        this.displayForm.controls.email.setValue(
          res.data.contact
            ? res.data.contact
              ? res.data.contact.email
              : ''
            : ''
        ); // this.displayForm.controls['laptop'].setValue(;

        this.displayForm.controls.company_first_name.setValue(
          res.data.customer.first_name
        );
        this.displayForm.controls.company_last_name.setValue(
          res.data.customer.last_name
        );
        this.displayForm.controls.customer_first_name.setValue(
          res.data.customer.first_name
        );
        this.displayForm.controls.customer_last_name.setValue(
          res.data.customer.last_name
        );
        this.displayForm.controls.customer_phone.setValue(
          res.data.customer.phone
        );
        this.displayForm.controls.coc_number.setValue(
          res.data.customer.coc_number
        );
        this.displayForm.controls.customer_email.setValue(
          res.data.customer.email
        );
        this.displayForm.controls.address.setValue(
          [res.data.customer.address_line_1, res.data.customer.address_line_2, res.data.customer.city, res.data.customer.country_name]
            .map(item => `${item || ''}`.trim())
            .filter(item => item.length > 0)
            .join(', ')
        );
        this.displayForm.controls.vat.setValue(
          res.data.customer.vat_number
        );
        this.spinerLoading = false;
      },

      (error) => {
        if (error.status === 403) {
          this.route.navigate(['/unauthorized']);
        }
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Unable to fetch timeslots!'
        );
        this.spinerLoading = false;
        //  / this.loading = false;
      }

    );
  }

  routeNavAccept() {
    this.route.navigate([`/course/registration/${this.registerId}/accept`], {
      queryParams: { token: this.token },
    });
  }
  routeNavUpdate() {
    this.route.navigate([`/course/registration/${this.registerId}/update`], {
      queryParams: { token: this.token },
    });
  }

  pageEvent(event) {
    event.previousPageIndex ? this.page-- : this.page++;
    this.getStudents(this.page);
  }
}
