import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthV2Service, LocalLanguageService } from '@services/index';
import { languages } from './../../../shared/language.translation';
import { LoginModel } from '@models/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  langs = languages;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthV2Service,

    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    const emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailregex)]],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(value: LoginModel) {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(value).subscribe(
      (data) => {
        if (this.returnUrl === '/'){
          this.returnUrl = '/trainer/dashboard';
        }
        this.router.navigateByUrl(this.returnUrl);
        this.loading = false;
      },
      (err) => {
        this.notificationService.error(
          err.status,
          err.error.message ? err.error.message : 'Something Bad'
        );

        this.loading = false;
      }
    );
  }
}
