import { Component, OnInit } from '@angular/core';
import { AuthV2Service } from '@services/index';
import { ActivatedRoute } from '@angular/router';
import { TrainerService, LocalLanguageService } from '@services/index';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { languages } from '../../shared/language.translation';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './statistics-presence.component.html',
  styleUrls: ['./statistics-presence.component.sass'],
  providers: [AuthV2Service],
})
export class StatisticsPresenceComponent implements OnInit {
  generalAttendence: {};
  backGround = 'accent';
  groupAttendence: {};
  groupEvalAttendence: {};

  langs = languages;
  studentAttendence: {};
  groupId: null;
  StudentId: null;
  groups = [];
  students = [];
  data: {};
  evaluations: {};
  evalTrainer: [];
  evalTrainerData: {};
  evalTrainerId: null;
  evalGroup: [];
  evalGroupData: {};
  evalGroupId: null;
  loading_eval = false;
  loading_attend = false;
  evalTrainerDetail: [];
  evalTrainerDetailData: {};
  evalTrainerDetailId: null;
  subscription = null;
  registerId: number = null;

  constructor(
    private trainerService: TrainerService,
    private activeRoute: ActivatedRoute,
    private localLanguageService: LocalLanguageService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    let browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.loading_eval = false;
    this.loading_attend = false;
    this.registerId = +this.activeRoute.snapshot.params['registration'];
    this.subscription = this.trainerService
      .getEvaluation(this.registerId)
      .subscribe((res) => {
        this.evaluations = res;
        this.evalGroupId = this.evaluations['groups'][0]['id'];
        this.evalGroup = this.evaluations['groups'];
        this.evalGroupData = this.evaluations['groups'][0];
        this.evalTrainerData = this.evalGroupData['trainers'];
        this.evalTrainerDetail = this.evalTrainerData['trainers'];
        this.evalTrainerDetailData = this.evalTrainerData['trainers'][0];
        this.evalTrainerDetailId = this.evalTrainerData['trainers'][0]['id'];
        this.loading_eval = true;
      });
    this.subscription = this.trainerService
      .getPresence(this.registerId)
      .subscribe((res) => {
        this.data = res;
        this.groups = this.data['groups'];

        this.groupId = this.groups[0]['id'];
        this.generalAttendence = {
          label: this.data['label'],
          presents: this.data['presents'],
          absents: this.data['absents'],
          total: this.data['total'],
        };
        this.groupAttendence = {
          label: this.data['groups'][0]['label'],
          presents: this.data['groups'][0]['presents'],
          absents: this.data['groups'][0]['absents'],
          total: this.data['groups'][0]['total'],
        };
        this.groupEvalAttendence = {
          label: this.data['groups'][0]['label'],
          presents: this.data['groups'][0]['presents'],
          absents: this.data['groups'][0]['absents'],
          total: this.data['groups'][0]['total'],
        };

        this.StudentId = this.groups[0]['students'][0]['id'];
        this.students = this.data['groups'][0]['students'];
        this.studentAttendence = {
          label: this.data['groups'][0]['students'][0]['label'],
          presents: this.data['groups'][0]['students'][0]['presents'],
          absents: this.data['groups'][0]['students'][0]['absents'],
          total: this.data['groups'][0]['students'][0]['total'],
        };
        this.loading_attend = true;
      });
  }
  showGroup(event) {
    this.groupId = event.value;
    let date = this.data['groups'].filter((x) => x.id == event.value);
    this.students = date[0]['students'];
    this.groupAttendence = {
      label: date[0]['label'],
      presents: date[0]['presents'],
      absents: date[0]['absents'],
      total: date[0]['total'],
    };
  }
  showStudent(id) {
    this.StudentId = id;
    let a = this.data['groups'].filter((x) => x.id == this.groupId);
    let date = a[0]['students'].filter((x) => x.id == id);
    this.studentAttendence = {
      label: date[0]['label'],
      presents: date[0]['presents'],
      absents: date[0]['absents'],
      total: date[0]['total'],
    };
  }
  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }

  showEvalGroup(event) {
    this.evalGroupId = event.value;
    let datu = this.evaluations['groups'].filter((x) => x.id == event.value);
    this.evalGroupData = datu[0];
    this.evalTrainerData = this.evalGroupData['trainers'];
    let date = this.data['groups'].filter((x) => x.id == event.value);
    this.students = date[0]['students'];
    this.groupEvalAttendence = {
      label: date[0]['label'],
      presents: date[0]['presents'],
      absents: date[0]['absents'],
      total: date[0]['total'],
    };
  }
  showTrainer(event) {
    this.evalTrainerDetailId = event.value;
    let a = this.evalTrainerData['trainers'].filter((x) => x.id == event.value);
    this.evalTrainerDetailData = a[0];
  }
}
