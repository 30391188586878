<div style="display: flex; justify-content: center; align-content: center">
  <mat-spinner *ngIf="spinerLoading"></mat-spinner>
</div>
<span *ngIf="!spinerLoading">
  <span class="translateSelectorAlignment">
    <label>
      <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
      <select
        #langSelect
        (change)="useLanguage(langSelect.value)"
        class="translateSelector"
      >
        <option
          *ngFor="let lang of langs"
          [value]="lang['code']"
          [selected]="lang['code'] == translate.currentLang"
        >
          {{ lang["name"] }}
        </option>
      </select>
    </label>
  </span>

  <br />
  <br />
  <div class="container">
    <iframe
      appIframeResizer
      class="quoteFrame"
      id="quotationPDFIframe"
      title="Quotation PDF"
    >
    </iframe>
    <br />
    <br /><br />

    <div class="button">
      <button
        mat-raised-button
        color="warn"
        (click)="declineRoute()"
        [disabled]="status"
      >
        {{ "OfferDisplay.declineBtn" | translate }}
      </button>

      <button
      mat-raised-button
      class="buttonStyle"
      (click)="acceptRoute()"
      [disabled]="status"
      >
        {{ "OfferDisplay.acceptBtn" | translate }}
      </button>
    </div>
  </div>
</span>
