import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../shared/language.translation';
import { NotificationsService } from 'angular2-notifications';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import {
  StudentEvaluationService,
  StudentRegistrationService,
  LocalLanguageService,
} from '@services/index';

@Component({
  selector: 'app-intermediate-evaluation',
  templateUrl: './intermediate-evaluation.component.html',
  styleUrls: ['./intermediate-evaluation.component.sass'],
})
export class IntermediateEvaluationComponent implements OnInit {
  // Const Data usage in the real time<<<<<<<<<<<<<<<<<<
  formDummyData = null;
  evaluation = [
    { code: 1, name: 'Weak' },
    { code: 2, name: 'Sufficient' },
    { code: 3, name: 'Good' },
    { code: 4, name: 'Very good' },
    { code: 5, name: 'Excellent' },
  ];
  trainerSpoke = [
    { code: 1, name: 'very often' },
    { code: 2, name: 'often' },
    { code: 3, name: 'sometimes' },
    { code: 4, name: 'rarely' },
  ];
  expectationsStatus = [
    { code: 1, name: 'Yes' },
    { code: 2, name: 'not quite' },
    { code: 3, name: 'no' },
  ];
  trainers = [{ id: 1 }, { id: 3 }];
  // >>>>>>>>>>>>
  langs = languages;
  intermediateEvaluationForm: FormGroup;
  currentDate = new Date();

  stdFirstName = '';
  stdLastName = '';
  trainerName: object = null;
  organizationName = '';
  targertedLanguage = '';
  spinerLoading = true;
  intermediateForm = false;
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private evaluationService: StudentEvaluationService,
    private studentService: StudentRegistrationService,
    private localLanguageService: LocalLanguageService
  ) { }

  ngOnInit(): void {
    this.languageChangeHandler();

    this.intermediateEvaluationForm = this.formBuilder.group({
      content_of_training: new FormControl(0, Validators.required),
      subject_interests: new FormControl(''),
      future_subjects: new FormControl(''),
      structure_of_training: new FormControl(0, Validators.required),
      activities_during_lesson: new FormControl(0, Validators.required),
      variation_in_subject_matters: new FormControl(0, Validators.required),
      pace_of_lessons: new FormControl(0, Validators.required),
      documents_received: new FormControl(0, Validators.required),
      specified_home_tasks: new FormControl(0, Validators.required),
      often_spoke_during_training: new FormControl(0, Validators.required),
      training_comments: new FormControl('', Validators.required),
      trainer_evaluations: this.formBuilder.array([]),
      organization_of_training: new FormControl(0, Validators.required),
      organization_comments: new FormControl('', Validators.required),
      meet_expectations: new FormControl(0, Validators.required),
      meet_expectations_explanation: new FormControl('', Validators.required),
      suggestions: new FormControl('', Validators.required),
    });

    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  formDrawer() {
    for (let a = 0; a < this.formDummyData.length; a++) {
      const fg = this.formBuilder.group({
        trainer_id: new FormControl(this.trainerName[a].id),
        way_of_teaching: new FormControl(0, Validators.required),
        adjustment_level: new FormControl(0, Validators.required),
        subject_knowledge: new FormControl(0, Validators.required),
        contact_language_trainer: new FormControl(0, Validators.required),
        correction_of_mistakes: new FormControl(0, Validators.required),
        use_of_teaching_materials: new FormControl(0, Validators.required),
        remarks: new FormControl('', Validators.required),
      });

      ((
        this.intermediateEvaluationForm.get('trainer_evaluations')
      ) as FormArray).push(fg);
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.studentService.studentDetailsAPI().subscribe(
      (res) => {
        this.stdFirstName = res.data.first_name;
        this.stdLastName = res.data.last_name;
        this.trainerName =
          res.data.group && res.data.group.trainers
            ? res.data.group.trainers
            : [];
        this.organizationName = res.data.registration.customer.name;
        this.targertedLanguage =
          res.data.registration.type.language.name;
        this.spinerLoading = false;
        this.formDummyData =
          res.data.group && res.data.group.trainers
            ? res.data.group.trainers
            : [];
        this.formDrawer();
      },
      (error) => {
        this.notificationService.error(error.status, error.error.message);
        this.spinerLoading = false;
      }
    );

    this.evaluationService.getEvaluation().subscribe(
      (res) => {
        if (res) {
          this.intermediateForm = false;
          this.spinerLoading = false;
        } else {
          this.spinerLoading = false;
          this.intermediateForm = true;
        }
      },
      (error) => {
        this.notificationService.error('Failure!', error.error.messsage);
        this.spinerLoading = false;
      }
    );
  }

  onSubmit(value) {
    if (this.intermediateEvaluationForm.invalid) {
      return 0;
    }

    value.final = false;
    this.evaluationService.createEvaluation(value).subscribe(
      (res) => {
        this.notificationService.success(
          '200',
          'Sccessfully Submited the form.'
        );
      },
      (error) => {
        this.notificationService.error(
          error.status,
          error.error && error.error.message
            ? error.error.message
            : 'Something wrong'
        );
      }
    );
  }
}
