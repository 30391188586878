import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  GroupInfoService,
  LocalLanguageService,
  AuthV2Service,
} from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../../shared/language.translation';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

const levels = {
  A01: 1,
  A02: 2,
  A1: 3,
  A2: 4,
  B1: 5,
  B2: 6,
};

export interface Transaction {
  item: string;
  cost: number;
}

@Component({
  selector: 'app-progress-report',
  templateUrl: './progress-report.component.html',
  styleUrls: ['./progress-report.component.sass'],
  providers: [AuthV2Service],
})
export class ProgressReportComponent implements OnInit, OnChanges {
  @Input() groupId: number;
  @Input() language;

  datarecieved = false;
  subscription = null;
  studentId = null;
  loadingStudents = false;
  loadingProgress = false;

  students = [];
  studentDetail: FormGroup;
  loading = true;
  displayedColumns: string[] = ['Category', 'Score'];
  langs = languages;
  gId = null;
  submitted:boolean;
  /** Gets the total cost of all transactions. */
  constructor(
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private groupInfo: GroupInfoService,
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {
    this.studentDetail = this.formBuilder.group({
      trainer_id: '',
      course_content: ['',Validators.required],
      comments: ['',Validators.required],
      follow_up: ['',Validators.required],
      assessment_progress_vocabulary_general: '',
      assessment_progress_vocabulary_business: '',
      assessment_progress_grammar_general: '',
      assessment_progress_grammar_business: '',
      assessment_progress_statement_general: '',
      assessment_progress_statement_business: '',
      assessment_end_speak_general: '',
      assessment_end_speak_business: '',
      assessment_end_listen_general: '',
      assessment_end_listen_business: '',
      assessment_end_read_general: '',
      assessment_end_read_business: '',
      assessment_end_write_general: '',
      assessment_end_write_business: '',
    });
    this.submitted = false;
  }
  ngOnChanges(changes) {
    // if(changes!=null){
    if (changes.hasOwnProperty('groupId')) {
      if (changes.hasOwnProperty('groupId')) {
        if (changes.groupId.currentValue != null) {
          this.gId = changes.groupId.currentValue;
        }
      }
    }
    if (this.gId != null) {
      this.loadingStudents = true;
      this.loadingProgress = true;

      this.datarecieved = false;
      this.loading = true;
      this.subscription = this.groupInfo.getGroupStudent(this.gId);
      this.subscription.subscribe((res) => {
        this.students = res.data;
        if (res.data.length > 0) {
          this.studentId = res.data[0].id;
          this.loadingStudents = false;
        }

        this.studentDetail = this.formBuilder.group({
          trainer_id: '',
          course_content: '',
          comments: '',
          follow_up: '',
          assessment_progress_vocabulary_general: '',
          assessment_progress_vocabulary_business: '',
          assessment_progress_grammar_general: '',
          assessment_progress_grammar_business: '',
          assessment_progress_statement_general: '',
          assessment_progress_statement_business: '',
          assessment_end_speak_general: '',
          assessment_end_speak_business: '',
          assessment_end_listen_general: '',
          assessment_end_listen_business: '',
          assessment_end_read_general: '',
          assessment_end_read_business: '',
          assessment_end_write_general: '',
          assessment_end_write_business: '',
        });
        this.groupInfo.getGroupStudentDetail(this.studentId).subscribe(
          (response) => {
            if (response.data) {
              this.saveDate(response);
              this.datarecieved = true;
              this.loadingProgress = false;
            }
          },
          (err) => {
            this.loadingProgress = false;
            this.datarecieved = false;
          }
        );
      });

      this.loading = false;
    }
    // }
  }
  saveDate(res) {
    this.studentDetail.controls.trainer_id.setValue(
      res.data.course_content ? res.data.trainer_id : ''
    );
    this.studentDetail.controls.course_content.setValue(
      res.data.course_content ? res.data.course_content : ''
    );
    this.studentDetail.controls.comments.setValue(
      res.data.comments ? res.data.comments : ''
    );
    this.studentDetail.controls.follow_up.setValue(
      res.data.follow_up ? res.data.follow_up : ''
    );
    this.studentDetail.controls.assessment_progress_statement_general.setValue(
      res.data.assessment_progress_statement_general
        ? levels[res.data.assessment_progress_statement_general]
        : ''
    );
    this.studentDetail.controls.assessment_progress_grammar_business.setValue(
      res.data.assessment_progress_grammar_business
        ? levels[res.data.assessment_progress_grammar_business]
        : ''
    );
    this.studentDetail.controls.assessment_progress_grammar_general.setValue(
      res.data.assessment_progress_grammar_general
        ? levels[res.data.assessment_progress_grammar_general]
        : ''
    );
    this.studentDetail.controls.assessment_progress_vocabulary_business.setValue(
      res.data.assessment_progress_vocabulary_business
        ? levels[res.data.assessment_progress_vocabulary_business]
        : ''
    );
    this.studentDetail.controls.assessment_progress_vocabulary_general.setValue(
      res.data.assessment_progress_vocabulary_general
        ? levels[res.data.assessment_progress_vocabulary_general]
        : ''
    );
    this.studentDetail.controls.assessment_progress_statement_business.setValue(
      res.data.assessment_progress_statement_business
        ? levels[res.data.assessment_progress_statement_business]
        : ''
    );
    this.studentDetail.controls.assessment_end_listen_general.setValue(
      res.data.assessment_end_listen_general
        ? levels[res.data.assessment_end_listen_general]
        : ''
    );
    this.studentDetail.controls.assessment_end_listen_business.setValue(
      res.data.assessment_end_listen_business
        ? levels[res.data.assessment_end_listen_business]
        : ''
    );
    this.studentDetail.controls.assessment_end_speak_general.setValue(
      res.data.assessment_end_speak_general
        ? levels[res.data.assessment_end_speak_general]
        : ''
    );
    this.studentDetail.controls.assessment_end_speak_business.setValue(
      res.data.assessment_end_speak_business
        ? levels[res.data.assessment_end_speak_business]
        : ''
    );
    this.studentDetail.controls.assessment_end_read_general.setValue(
      res.data.assessment_end_read_general
        ? levels[res.data.assessment_end_read_general]
        : ''
    );
    this.studentDetail.controls.assessment_end_read_business.setValue(
      res.data.assessment_end_read_business
        ? levels[res.data.assessment_end_read_business]
        : ''
    );
    this.studentDetail.controls.assessment_end_write_general.setValue(
      res.data.assessment_end_write_general
        ? levels[res.data.assessment_end_write_general]
        : ''
    );
    this.studentDetail.controls.assessment_end_write_business.setValue(
      res.data.assessment_end_write_business
        ? levels[res.data.assessment_end_write_business]
        : ''
    );
  }
  ngOnInit(): void {
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }
  showInfo(val) {
    if (this.studentId != val) {
      this.loadingProgress = true;
      this.datarecieved = false;
      this.studentDetail = this.formBuilder.group({
        trainer_id: '',
        course_content: '',
        comments: '',
        follow_up: '',
        assessment_progress_vocabulary_general: '',
        assessment_progress_vocabulary_business: '',
        assessment_progress_grammar_general: '',
        assessment_progress_grammar_business: '',
        assessment_progress_statement_general: '',
        assessment_progress_statement_business: '',
        assessment_end_speak_general: '',
        assessment_end_speak_business: '',
        assessment_end_listen_general: '',
        assessment_end_listen_business: '',
        assessment_end_read_general: '',
        assessment_end_read_business: '',
        assessment_end_write_general: '',
        assessment_end_write_business: '',
      });
      this.studentId = val;
      this.subscription = this.groupInfo.getGroupStudentDetail(this.studentId);
      this.subscription.subscribe(
        (res) => {
          if (res.data) {
            this.datarecieved = true;
            this.saveDate(res);
            this.loadingProgress = false;
          }
        },
        (error) => {
          this.loadingProgress = false;
        }
      );
    }
  }

  getData(val) {
    return this.studentDetail[val] ? this.studentDetail[val] : '';
  }

  editScore(val, key) {
    this.studentDetail[key] = val;
  }

  addProgressReport() {
    this.submitted = true;
    if(this.studentDetail.invalid){
      this.notificationService.error('Form has invalid data!');
      return;
    }
    this.loadingProgress = true;
    if (this.datarecieved) {
      this.subscription = this.groupInfo
        .updatePorgressReport(this.studentId, this.studentDetail.value)
        .subscribe(
          (res) => {
            this.notificationService.success('200', 'Progres Report Updated');
            this.saveDate(res);
            this.loadingProgress = false;
            this.submitted = false;

          },
          (error) => {
            this.loadingProgress = false;
            this.notificationService.error(
              error.status,
              error.error.errors.trainer_id || error.error.message
            );
            this.submitted = false;
              
            // this.uploadloading = false;
          }
        );
    } else {
      this.groupInfo.getTrainerInfo().subscribe((res) => {
        this.studentDetail.controls.trainer_id.setValue(
          res.data ? res.data.id : null
        );
        this.groupInfo
          .addPorgressReport(this.studentId, this.studentDetail.value)
          .subscribe(
            (response) => {
              this.notificationService.success('200', 'Progres Report Added');
              this.saveDate(response);
              this.loadingProgress = false;
              this.datarecieved = true;
             this.submitted = false;

            },
            (error) => {
              this.loadingProgress = false;
              this.notificationService.error(
                error.status,
                error.error.errors.trainer_id || error.error.message
              );
              this.submitted = false;

              // this.uploadloading = false;
            }
          );
      });
    }
  }
}
