import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from './api.urls';

@Injectable({ providedIn: 'root' })
export class GoalsInfoCRUDService {
  constructor(private http: HttpClient) {}

  // Get offer/Quotation details

  createGoal(group: number, data): Observable<any> {
    return this.http.post<any>(`${API.creatGoal}/${group}/goal`, data);
  }

  updateGoal(goal: number, data): Observable<any> {
    return this.http.put<any>(`${API.updateGoal}/${goal}`, data);
  }
}
