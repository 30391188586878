import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  HelperService,
  LocalLanguageService,
  TrainerService,
} from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import { languages } from '../../../shared/language.translation';

@Component({
  selector: 'app-my-download',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.sass'],
})
export class DownloadFileComponent implements OnInit {
  langs = languages;
  contractForm: FormGroup;
  subscription = null;
  files = [];
  id: any;

  data: any;
  agreementFile = '';
  file = null;
  loading = false;
  downloadLoading = false;

  constructor(
    public translate: TranslateService,
    private localLanguageService: LocalLanguageService,
    private formBuilder: FormBuilder,
    private trainerService: TrainerService,
    private helperService: HelperService,
    private notificationService: NotificationsService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.contractForm = this.formBuilder.group({
      profile: ['', Validators.required],
    });
    //this.agreementFile = `${environment.apiURL}/api/trainer/download-agreement`;
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.languageChangeHandler();
  }

  downloadFile() {
    this.downloadLoading = true;
    this.trainerService.getAgreementDoc().subscribe(
      (res) => {
        this.helperService.makeAndDownLoadFile(res, 'TrainerAgreement.pdf');
        this.downloadLoading = false;
        this.notificationService.success('200', 'File recieved!');
      },
      (err) => {
        this.downloadLoading = false;
        this.notificationService.error(err.status, 'File Not Found!');
      },
    );
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.contractForm.get('profile').value);
    this.downloadLoading = true;
    this.trainerService.uploadSignedDoc(formData).subscribe(
      (res) => {
        this.notificationService.success('200', 'Successfully uploaded!');

        this.agreementFile = res.data.signed_doc;
        this.downloadLoading = false;
      },
      (err) => {
        this.downloadLoading = false;
        this.notificationService.error(
          err.status,
          err.error && err.error.message ? err.error.message : 'Something Bad',
        );
      },
    );
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
    this.languageChangeHandler();
  }

  private languageChangeHandler() {
    this.subscription = this.trainerService.getTrainerProfile();
    this.subscription.subscribe(
      (res) => {
        this.contractForm.controls.profile.setValue(res.data.signed_doc);
        this.agreementFile = res.data.signed_doc;
      },
      (err) => {
        this.notificationService.error(
          err.status,
          err.error && err.error.message ? err.error.message : 'Something Bad',
        );
        //  / this.loading = false;
      },
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.contractForm.get('profile').setValue(file);
    }
  }
}
