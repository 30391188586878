import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {
  SingleDataSet,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.sass'],
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() generalAttendence: any;
  @Input() totalLabel: any;
  @Input() presentLabel: any;
  @Input() absentLabel: any;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };

  public pieChartLabels: Label[] = ['Present', 'Absent', 'Total'];
  public pieChartData: SingleDataSet = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];

  constructor(public translate: TranslateService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {}
  ngOnChanges(change) {
    if (change.generalAttendence) {
      if (change.generalAttendence.currentValue !== undefined) {
        this.pieChartData = [
          change.generalAttendence.currentValue.presents,
          change.generalAttendence.currentValue.absents,
          change.generalAttendence.currentValue.total,
        ];
      }
    }
    if (change.presentLabel) {
      this.pieChartLabels[0] = change.presentLabel.currentValue;
    }
    if (change.absentLabel) {
      this.pieChartLabels[1] = change.absentLabel.currentValue;
    }
    if (change.totalLabel) {
      this.pieChartLabels[2] = change.totalLabel.currentValue;
    }
  }
}
