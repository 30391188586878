import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { LessonCancelService, LocalLanguageService } from '@services/index';
import { NotificationsService } from 'angular2-notifications';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
export interface DialogReScheduleLesson {
  lessonId: string;
  language: string;
}

@Component({
  selector: 'app-reschedule-lesson',
  templateUrl: './reschedule-lesson.component.html',
  styleUrls: ['./reschedule-lesson.component.sass'],
})
export class RescheduleLessonComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RescheduleLessonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogReScheduleLesson,
    private formBuilder: FormBuilder,
    private lessonCancelService: LessonCancelService,
    private notificationService: NotificationsService,
    private http: HttpClient,
    private zone: NgZone,
    private translate: TranslateService,
    private localLanguageService: LocalLanguageService
  ) {}

  get form() {
    return this.rescheduleForm.controls;
  }
  rescheduleForm: FormGroup;
  loading = false;
  public date;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate;
  public maxDate;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required]),
  });
  public dateControl = new FormControl(new Date(2021, 9, 4, 5, 6, 7));
  public dateControlMinMax = new FormControl(new Date());

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  @ViewChild('picker') picker1: any;
  @ViewChild('picker') picker2: any;

  ngOnInit(): void {
    this.rescheduleForm = this.formBuilder.group({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
    });
    const browserlang = this.translate.getBrowserLang();
    if (browserlang) {
      this.translate.setDefaultLang(browserlang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.date = new Date(2021, 9, 4, 5, 6, 7);
    this.useLanguage(this.data.language);
  }

  public useLanguage(lang: string): void {
    this.localLanguageService.setLocalLanguage(lang);
    this.translate.use(lang);
  }
  closeDialog() {
    this.dialogRef.close();
  }
  onSubmit(value) {
    if (this.rescheduleForm.invalid) {
      return 0;
    }
    this.loading = true;

    this.lessonCancelService
      .rescheduleLesson(+this.data.lessonId, value)
      .subscribe(
        (res) => {
          this.loading = false;
          this.notificationService.success(
            '200',
            'Successfully re-scheduled for lesson'
          );
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.status, error.error.message);
        }
      );
  }

  toggleMinDate(evt: any) {
    if (evt.checked) {
      this._setMinDate();
    } else {
      this.minDate = null;
    }
  }

  toggleMaxDate(evt: any) {
    if (evt.checked) {
      this._setMaxDate();
    } else {
      this.maxDate = null;
    }
  }

  closePicker() {
    this.picker1.cancel();
  }

  private _setMinDate() {
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate() - 1);
  }

  private _setMaxDate() {
    const now = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(now.getDate() + 1);
  }
}
