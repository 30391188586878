<span class="translateSelectorAlignment">
  <label>
    <mat-icon class="translateIcon">g_translate</mat-icon>{{ "" | translate }}
    <select
      #langSelect
      (change)="useLanguage(langSelect.value)"
      class="translateSelector"
    >
      <option
        *ngFor="let lang of langs"
        [value]="lang['code']"
        [selected]="lang['code'] == translate.currentLang"
      >
        {{ lang["name"] }}
      </option>
    </select>
  </label>
</span>
<br />
<div style="position: absolute; top: 45%; left: 45%; z-index: 1">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div class="container">
  <h1>{{ "RegistrationAccept.title" | translate }}</h1>
  <br />
  <br />
  <ng-container *ngIf="!loading && !buttonDisable">
    <p>
      <b>{{ "RegistrationAccept.noteLabel" | translate }}</b>
      <br />
      {{ "RegistrationAccept.noteDetails" | translate }}
    </p>
    <br />
    <div class="row">
      <div class="col-md-6">
        <form [formGroup]="registrationAcceptForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="inputTag">
              <input type="file" name="file" (change)="onFileSelect($event)" />
            </div>
          </div>
          <br />
          <div class="form-group">
            <div class="buttonStyle">
              <a
                class="download"
                (click)="downloadFIle()"
                class="anchorColor btn button"
                target="_blank"
                style="color: #ffffff"
              >
                {{ "RegistrationAccept.downloadBtn" | translate }}
              </a>

              <button
                type="submit"
                class="btn button"
                [disabled]="loading || buttonDisable"
              >
                {{ "RegistrationAccept.uploadBtn" | translate }}
              </button>
            </div>
          </div>
        </form>

        <!--
  File Uploading progress bar feature.
   -->

        <br />
        <div *ngIf="uploadFileResponse.status === 'error'">
          {{ uploadFileResponse.message }}
        </div>
        <div *ngIf="uploadFileResponse.status === 'progress'">
          <div
            role="progressbar"
            [style.width.%]="uploadFileResponse.message"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ uploadFileResponse.message }}%
            <mat-progress-bar
              mode="determinate"
              value=" uploadFileResponse.message"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <p class="accepted" *ngIf="!loading && buttonDisable">
    {{ "RegistrationAccept.Accepted" | translate }}
  </p>
</div>
