import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API } from './api.urls';

@Injectable({
  providedIn: 'root',
})
export class StudentGroupingProposalService {
  constructor(private http: HttpClient) {}

  getProposal(group: number): Observable<[any]> {
    return this.http.get<[any]>(`${API.proposalAPI}/${group}`);
  }

  getGroup(registration: number): Observable<[any]> {
    return this.http.get<[any]>(
      `${API.customerProposal}/${registration}/groups?with[]=students`
    );
  }

  // Accept the Quotation

  acceptProposal(group: number, proposalData) {
    return this.http.post<any>(
      `${API.proposalAPI}/${group}/accept`,
      proposalData
    );
  }

  acceptGroup(registration: number, proposalData) {
    return this.http.post<any>(
      `${API.customerProposal}/${registration}/groups/accept`,
      proposalData
    );
  }

  // Decline the Quotation

  declineProsoal(group: number, proposalData) {
    return this.http.post(`${API.proposalAPI}/${group}/decline`, proposalData);
  }

  declineGroup(registration: number, proposalData) {
    return this.http.post(
      `${API.customerProposal}/${registration}/groups/decline`,
      proposalData
    );
  }
}
